import { Grid } from '@mui/material'
import { useState } from 'react'
import CustomModal from '../../common/popup/CustomModal'
import LoadingBackdrop from '../../common/LoadingBackdrop'
import { urlConfig } from '../../../config/apiConfig'
import axiosInstance from '../../../config/axiosConfig'
import DeviceTypeForm from './DeviceTypeForm'
import { AlertPopup } from '../../StyleComponent'

const initialState = {
    name: '',
    key_type_id: '',
    deviceTypeParameter: [
        {
            parameter: 1,
            name: '',
            key: '',
            unit: '',
            status: false,
        },
    ],
    type_alert: 'Remain state'
}

export default function DeviceHeader({
    onFetch,
    searchText,
    setSearchText,
    onSearch,
    openAdd,
    setOpenAdd,
}) {
    // const [openAdd, setOpenAdd] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState(initialState)
    const [imageUpload, setImageUpload] = useState(null)
    const [err, setErr] = useState(false)

    const canClickNext =
        input.name?.trim() !== '' &&
        input.key_type_id?.trim() !== '' &&
        input.deviceTypeParameter?.every(
            (param) => param.name?.trim() !== '' && param.key?.trim() !== '',
        ) &&
        err === false

    const handleSave = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.post(
                `${urlConfig.deviceType}`,
                input,
            )
            if (res.status !== 201) {
                throw new Error('Failed to create device type')
            }

            const formData = new FormData()
            formData.append('image', imageUpload)
            const uploadImage = await axiosInstance.post(
                `${urlConfig.deviceType}/upload/${input.key_type_id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            )
            if (uploadImage.status !== 200) {
                throw new Error('Failed to upload image')
            }

            onFetch()
            setIsSuccess(true)
            setOpenAdd(false)
            setAnchorEl(true)
        } catch (error) {
            console.log('Error:', error)
            setIsSuccess(false)
            setAnchorEl(true)
            // setOpenAdd(false)
        } finally {
            setLoading(false)
            // setInput(initialState)
        }
    }

    const handleCloseUploadImage = () => {
        setInput(initialState)
    }

    return (
        <Grid container justifyContent={'space-between'}>
            <LoadingBackdrop loading={loading} />
            <CustomModal
                open={openAdd}
                setOpen={setOpenAdd}
                handleClose={handleCloseUploadImage}
                loading={loading}
            >
                <DeviceTypeForm
                    input={input}
                    setInput={setInput}
                    setImageUpload={setImageUpload}
                    canSave={canClickNext}
                    handleSave={handleSave}
                    setOpen={setOpenAdd}
                    onCancel={handleCloseUploadImage}
                    setErr={setErr}
                />
            </CustomModal>
            <AlertPopup
                open={anchorEl}
                setOpen={setAnchorEl}
                message={{
                    status: isSuccess ? 'success' : 'error',
                    name: 'Add new device type',
                }}
            />
        </Grid>
    )
}
