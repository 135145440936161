import GroupsIcon from '@mui/icons-material/Groups'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded'
import StickyNote2RoundedIcon from '@mui/icons-material/StickyNote2Rounded'
import WifiTetheringRoundedIcon from '@mui/icons-material/WifiTetheringRounded'
import TuneRoundedIcon from '@mui/icons-material/TuneRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import CreditCardIcon from '@mui/icons-material/CreditCard'

export const permissionDefine = {
    ADMIN: 'Admin',
    USER: 'User',
}

const menus = (role, auth) => {
    const checkRead = auth?.Permissions.filter(
        (item) => item.action === 'read' || item.name === 'superAdmin',
    )

    const AllowRead = (name) => {
        const allow = checkRead.filter((item) => item.name === name)
        return !!allow[0]
    }

    const menu = [
        {
            name: 'Overview',
            icon: <GroupsIcon />,
            route: '/',
            permission: [permissionDefine.USER],
        },
    ]

    const adminMenu = [
        {
            name: 'Organizations',
            icon: (
                <svg
                    viewBox='0 0 24 24'
                    width={20}
                    height={20}
                    fill='white'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path d='M11.62 9.21c.23.18.37.47.37.78v12c0 .55-.45 1-1 1h-9c-.56 0-1-.45-1-1v-10c0-.47.32-.88.78-.98l9-2c.29-.07.6 0 .84.19Zm-8.63 3.58v8.19h7v-9.76l-7 1.55Z' />
                    <path d='M22.64 1.23c.22.18.35.47.35.76v20c0 .55-.45 1-1 1h-11c-.56 0-1-.45-1-1v-18c0-.49.34-.9.82-.99l11-2c.29-.06.59.02.81.21Zm-10.65 3.6v16.16h9V3.18l-9 1.63Z' />
                    <path d='M13.5 7.5V6h2v1.5h-2Z' />
                    <path d='M13.5 12v-1.5h2V12h-2Z' />
                    <path d='M13.5 16.5V15h2v1.5h-2Z' />
                    <path d='M4 15.5V14h2v1.5H4Z' />
                    <path d='M4 19.5V18h2v1.5H4Z' />
                    <path d='M17.5 7.5V6h2v1.5h-2Z' />
                    <path d='M17.5 12v-1.5h2V12h-2Z' />
                    <path d='M17.5 16.5V15h2v1.5h-2Z' />
                    <path d='M7 15.5V14h2v1.5H7Z' />
                    <path d='M7 19.5V18h2v1.5H7Z' />
                </svg>
            ),
            route: '/organizations',
            ref2: '/',
            permission: [permissionDefine.ADMIN],
            allow: AllowRead('organization'),
        },
        {
            name: 'Devices',
            icon: <TuneRoundedIcon sx={{ width: '20px', height: '20px' }} />,
            route: '/devices',
            permission: [permissionDefine.ADMIN],
            allow: AllowRead('device'),
        },
        {
            name: 'User Management',
            icon: (
                <AccountCircleRoundedIcon
                    sx={{ width: '20px', height: '20px' }}
                />
            ),
            route: '/user-management',
            permission: [permissionDefine.ADMIN],
            allow: AllowRead('user management'),
        },

        {
            name: 'Role & Permission',
            icon: <CreditCardIcon sx={{ width: '20px', height: '20px' }} />,
            route: '/role-permission',
            permission: [permissionDefine.ADMIN],
            allow: AllowRead('role permission'),
        },
        {
            name: 'MQTT Broker',
            icon: (
                <WifiTetheringRoundedIcon
                    sx={{ width: '20px', height: '20px' }}
                />
            ),
            route: '/mqtt-broker',
            permission: [permissionDefine.ADMIN],
            allow: AllowRead('mqtt broker'),
        },
    ]

    const userMenu = [
        {
            name: 'Overview',
            icon: (
                <GridViewRoundedIcon sx={{ width: '20px', height: '20px' }} />
            ),
            route: '/',
            permission: [permissionDefine.USER],
            allow: AllowRead('dashboard'),
        },
        {
            name: 'Device IoT',
            icon: (
                <DisplaySettingsIcon sx={{ width: '20px', height: '20px' }} />
            ),
            route: '/device-iot',
            permission: [permissionDefine.USER],
            allow: AllowRead('device'),
        },
        {
            name: 'Graph Trend',
            icon: (
                <InsertChartRoundedIcon
                    sx={{ width: '20px', height: '20px' }}
                />
            ),
            route: '/graph-trend',

            permission: [permissionDefine.USER],
            allow: AllowRead('graph'),
        },
        {
            name: 'Report',
            icon: (
                <StickyNote2RoundedIcon
                    sx={{ width: '20px', height: '20px' }}
                />
            ),
            route: '/report',
            permission: [permissionDefine.USER],
            allow: AllowRead('report'),
        },
    ]

    if (role === 1) {
        return adminMenu
    } else if (role === 2) {
        return userMenu
    } else {
        return menu
    }
}

export default menus
