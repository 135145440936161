import { useContext, useEffect, useState } from 'react'
import {
    Grid,
    Select,
    MenuItem,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Switch,
    FormControlLabel,
    FormControl,
    InputLabel,
    Button,
    TableContainer,
    IconButton,
    Menu,
    Box,
} from '@mui/material'
import { urlConfig } from '../../config/apiConfig'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ModalRequest from '../../components/ModalRequest'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import {
    AlertPopup,
    Container,
    Input,
    Loadding,
    handdleOpenAlert,
} from '../../components/StyleComponent'
import ModalDelete from '../../components/ModalDelete'
import axiosInstance from '../../config/axiosConfig'
import { AlertContext } from '../../context/AlertContext'
import { useAuth } from '../../hook/useAuth'

const cellStyles = {
    border: '1px solid #e3e3e3',
    padding: '5px 10px',
}

const headerCellStyles = {
    border: '1px solid #e3e3e3',
    padding: '10px 10px',
    fontWeight: 'bold',
}

export default function Permission({ create, update, remove }) {
    const [open, setOpen] = useState(false)
    const [onload, setOnload] = useState(false)
    const [level, setLevel] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [edit, setEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [message, setMessage] = useState('')
    const [loadding, setLoadding] = useState(false)
    const [selectedRole, setSelectedRole] = useState('')
    const [rolePermissions, setRolePermissions] = useState('')
    const [roleName, setRoleName] = useState('')
    const [permissions, setPermissions] = useState([])
    const [roles, setRoles] = useState([])
    const [group, setGroup] = useState('User')
    const [anchorEl, setAnchorEl] = useState(null)
    const { auth } = useAuth()
    const handleRoleChange = (event) => {
        const selectedRoleId = event.target.value
        setSelectedRole(selectedRoleId)
        const selectedRoleObject = roles.find(
            (r) => r.role_id === selectedRoleId,
        )

        setRolePermissions(selectedRoleObject ? selectedRoleObject : [])
    }

    const togglePermission = (permissionId) => {
        let updatedPermissions = rolePermissions.Permissions && [
            ...rolePermissions.Permissions,
        ]
        const index = updatedPermissions.findIndex(
            (permission) => permission.permission_id === permissionId,
        )

        if (index !== -1) {
            updatedPermissions.splice(index, 1)
        } else {
            updatedPermissions.push(
                permissions.find((p) => p.permission_id === permissionId),
            )
        }

        setRolePermissions({
            ...rolePermissions,
            Permissions: updatedPermissions,
        })
    }

    const isPermissionEnabled = (permissionId) => {
        if (permissionId) {
            return rolePermissions.Permissions.some(
                (permission) => permission.permission_id === permissionId,
            )
        } else {
            return false
        }
    }
    const groupRole = roles?.filter((item) => item.role_id === selectedRole)[0]
        ?.group

    const useThisName = permissions?.filter((item) => item.group === groupRole)

    const uniqueNames = Array.from(new Set(useThisName.map((p) => p.name)))

    const { handleOpen } = useContext(AlertContext)
    const getRole = async (data) => {
        setOnload(true)
        try {
            const response = await axiosInstance.get(urlConfig.role)
            setRoles(
                response.data.message[0] &&
                    response.data.message.filter(
                        (item) => item.level <= auth?.role_level,
                    ),
            )
            if (data && data !== 'delete') {
                setSelectedRole(data.role_id)
                setRolePermissions(
                    response.data.message.filter(
                        (item) => item.role_id === data.role_id,
                    )[0],
                )
            } else if (data === 'delete') {
                return handleOpen({ open: false })
            } else {
                if (response.data.message.length > 0) {
                    setSelectedRole(response.data.message[0].role_id)
                    setRolePermissions(response.data.message[0])
                }
            }
            handleOpen({ open: false })
        } catch (error) {
            console.error('Error fetching roles:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        } finally {
            setOnload(false)
        }
    }

    useEffect(() => {
        axiosInstance
            .get(urlConfig.permission)
            .then((response) => {
                setPermissions(response.data.message)
            })
            .catch((error) => {
                console.error('Error fetching permissions:', error)
            })

        axiosInstance
            .get(urlConfig.role)
            .then((response) => {
                const filteLevel =
                    response.data.message[0] &&
                    response.data.message.filter(
                        (item) => item.level <= auth?.role_level,
                    )
                setRoles(filteLevel)
                if (response.data.message.length > 0) {
                    setSelectedRole(filteLevel[0].role_id)
                    setRolePermissions(filteLevel[0])
                }
            })
            .catch((error) => {
                console.error('Error fetching roles:', error)
            })
    }, [])

    const arePermissionIdsEqual = (role1, role2) => {
        if (!role1 || !role2) {
            return false
        }

        const permissionIds1 =
            role1.Permissions &&
            new Set(
                role1.Permissions?.map(
                    (permission) => permission.permission_id,
                ),
            )
        const permissionIds2 =
            role2.Permissions &&
            new Set(
                role2.Permissions?.map(
                    (permission) => permission.permission_id,
                ),
            )

        if (permissionIds1.size !== permissionIds2.size) {
            return false
        }
        for (const permissionId of permissionIds1) {
            if (!permissionIds2.has(permissionId)) {
                return false
            }
        }
        return true
    }

    const result =
        roles &&
        selectedRole &&
        rolePermissions &&
        arePermissionIdsEqual(
            ...roles.filter((item) => item.role_id === selectedRole),
            rolePermissions || [],
        )

    const updateData = rolePermissions.Permissions
        ? {
              permission_id: rolePermissions.Permissions.map(
                  (item) => item.permission_id,
              ),
          }
        : []

    const CreateRole = async () => {
        setLoadding(true)
        const body = {
            name: roleName,
            level: level,
            group: group,
        }

        try {
            const res = await axiosInstance.post(`${urlConfig.role}`, body)
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                'Role added successfully.',
                'success',
            )
            setOpen(false)
            getRole(res.data.message)
        } catch (error) {
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                'Failed to create the role',
                'error',
            )
        } finally {
            setLoadding(false)
        }
    }

    const upDateRole = async (mode) => {
        setLoadding(true)
        const body =
            mode === 'name'
                ? { name: rolePermissions.name, level: level, group: group }
                : updateData

        try {
            await axiosInstance.put(`${urlConfig.role}/${selectedRole}`, body)
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                ' Role Update successfully.',
                'success',
            )
            setEdit(false)
            getRole(rolePermissions)
        } catch (error) {
            console.error(error)
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                'Failed to updating the role',
                'error',
            )
        } finally {
            setLoadding(false)
        }
    }

    const deleteRole = async () => {
        setLoadding(true)
        try {
            await axiosInstance.delete(
                `${urlConfig.role}/${rolePermissions.role_id}`,
            )
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                'Role deleted successfully.',
                'success',
            )
            setOpenDelete(false)
            getRole('delete')
        } catch (error) {
            console.error(error)
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                'Failed to delete the role',
                'error',
            )
        } finally {
            setLoadding(false)
            if (roles.length > 0) {
                setSelectedRole(roles[0].role_id)
                setRolePermissions(roles[0])
            }
        }
    }

    const editOnChange = (name, value) => {
        setRolePermissions({ ...rolePermissions, [name]: value })
    }

    const checkLevel =
        roles?.filter((item) => item.role_id === selectedRole)[0]?.level ===
        auth?.role_level

    const tableHd =
        groupRole === 'Admin'
            ? ['read', 'create', 'update', 'delete']
            : ['read']
    return (
        <Container
            header={header}

            // button={{ name: 'new Role', action: () =>  }}
        >
            {roles.length > 0 && permissions?.length > 0 ? (
                <Grid container spacing={2} sx={{ padding: '30px 20px' }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box>
                            <FormControl size='small' sx={{ width: '200px' }}>
                                <InputLabel id='role-select-label'>
                                    Select a Role:
                                </InputLabel>
                                <Select
                                    label='Select a Role:'
                                    labelId='role-select-label'
                                    value={selectedRole}
                                    onChange={handleRoleChange}
                                    sx={{ borderRadius: '30px' }}
                                    fullWidth
                                >
                                    {roles &&
                                        roles.map((item) => (
                                            <MenuItem
                                                key={item.role_id}
                                                value={item.role_id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <IconButton
                                disabled={!update || checkLevel}
                                onClick={(event) =>
                                    setAnchorEl(event.currentTarget)
                                }
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Box>
                        <Button
                            disabled={!create}
                            color='button'
                            variant='contained'
                            size='small'
                            onClick={() => {
                                setOpen(true)
                                setLevel('')
                            }}
                            sx={{
                                whiteSpace: 'nowrap',
                                marginRight: '5px',
                                borderRadius: '30px',
                                // textTransform: 'none',
                                padding: '5px 20px',
                                fontWeight: '600',
                                textWrap: 'nowrap',
                                height: '30px',
                            }}
                            startIcon={
                                <AddCircleOutlineIcon
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                ></AddCircleOutlineIcon>
                            }
                        >
                            ADD Role
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem
                                onClick={() => {
                                    setAnchorEl(null)
                                    setEdit(true)
                                    setLevel(rolePermissions.level || '')
                                }}
                                sx={{
                                    fontSize: '14px',
                                    padding: '10px 15px',
                                    fontWeight: '200',
                                }}
                            >
                                <ModeEditOutlinedIcon
                                    fontSize='small'
                                    sx={{ marginRight: '10px' }}
                                    color='warning'
                                />
                                Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setAnchorEl(null)
                                    setOpenDelete(true)
                                }}
                                sx={{
                                    fontSize: '14px',
                                    padding: '10px 15px',
                                    fontWeight: '200',
                                }}
                            >
                                <DeleteOutlineOutlinedIcon
                                    fontSize='small'
                                    sx={{ marginRight: '10px' }}
                                    color='error'
                                />
                                Delete
                            </MenuItem>
                        </Menu>
                    </Grid>
                    <Grid item xs={12} ml={2} sx={{ display: 'flex' }}>
                        <Box sx={{ fontWeight: 'bold' }}>Group : </Box>
                        &#160;{`   ${groupRole}` || ''}
                    </Grid>
                    {selectedRole && (
                        <Grid item xs={12}>
                            <TableContainer sx={{ padding: '0 5px' }}>
                                <Table
                                    sx={{
                                        borderRadius: '5px',
                                        overflow: 'auto',
                                    }}
                                >
                                    <TableHead>
                                        <TableRow sx={{ bgcolor: '#f7f7f7' }}>
                                            <TableCell
                                                sx={{
                                                    ...headerCellStyles,
                                                    width: '20%',
                                                    fontWeight: 'bold',
                                                    color: '#242424',
                                                    padding: '10px 30px',
                                                    textTransform: 'uppercase',
                                                }}
                                                align='center'
                                            >
                                                Name
                                            </TableCell>

                                            {tableHd?.map((item, index) => (
                                                <TableCell
                                                    key={index}
                                                    sx={{
                                                        ...headerCellStyles,
                                                        width: '17.5%',
                                                        color: '#242424',
                                                        padding: '10px 30px',
                                                        textTransform:
                                                            'uppercase',
                                                    }}
                                                    align='center'
                                                >
                                                    {item}
                                                </TableCell>
                                            ))}

                                            {/* <TableCell
                                                sx={{
                                                    ...headerCellStyles,
                                                    width: '17.5%',
                                                    color: '#242424',
                                                    padding: '10px 30px',
                                                    textTransform: 'uppercase',
                                                }}
                                                align='center'
                                            >
                                                Read
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...headerCellStyles,
                                                    width: '17.5%',
                                                    color: '#242424',
                                                    padding: '10px 30px',
                                                    textTransform: 'uppercase',
                                                }}
                                                align='center'
                                            >
                                                Create
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...headerCellStyles,
                                                    width: '17.5%',
                                                    color: '#242424',
                                                    padding: '10px 30px',
                                                    textTransform: 'uppercase',
                                                }}
                                                align='center'
                                            >
                                                Update
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    ...headerCellStyles,
                                                    width: '17.5%',
                                                    color: '#242424',
                                                    padding: '10px 30px',
                                                    textTransform: 'uppercase',
                                                }}
                                                align='center'
                                            >
                                                Delete
                                            </TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {uniqueNames &&
                                            uniqueNames.map((name, index) => {
                                                const permissionsForName =
                                                    permissions?.filter(
                                                        (p) => p.name === name,
                                                    )
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            sx={{
                                                                ...cellStyles,
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '16px',
                                                                padding:
                                                                    '5px 15px',
                                                            }}
                                                        >
                                                            {name}
                                                        </TableCell>
                                                        {tableHd?.map(
                                                            (action, index) => {
                                                                const permission =
                                                                    permissionsForName.find(
                                                                        (p) =>
                                                                            p.action.toLowerCase() ===
                                                                            action,
                                                                    )
                                                                return (
                                                                    <TableCell
                                                                        key={
                                                                            index
                                                                        }
                                                                        sx={{
                                                                            ...cellStyles,
                                                                        }}
                                                                        align='center'
                                                                    >
                                                                        <FormControlLabel
                                                                            control={
                                                                                permission ? (
                                                                                    <Switch
                                                                                        disabled={
                                                                                            checkLevel
                                                                                        }
                                                                                        color='success'
                                                                                        size='small'
                                                                                        checked={
                                                                                            permission
                                                                                                ? isPermissionEnabled(
                                                                                                      permission.permission_id,
                                                                                                  )
                                                                                                : false
                                                                                        }
                                                                                        onChange={() =>
                                                                                            togglePermission(
                                                                                                permission.permission_id,
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <>

                                                                                    </>
                                                                                )
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                )
                                                            },
                                                        )}
                                                    </TableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* {(!permissions[0] || !permissions[0]) && <Loadding />} */}
                        </Grid>
                    )}
                    {selectedRole && (
                        <Grid item xs={12} align='right'>
                            <Button
                                // size='small'
                                // sx={{ borderRadius: '30px' }
                                startIcon={<SaveAltIcon></SaveAltIcon>}
                                color='success'
                                variant='contained'
                                sx={{
                                    borderRadius: '15px',
                                    fontSize: '12px',
                                }}
                                size='small'
                                onClick={upDateRole}
                                disabled={
                                    result || loadding || !update || checkLevel
                                }
                            >
                                Save Change
                            </Button>
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Box>
                    <Loadding></Loadding>
                </Box>
            )}

            <ModalRequest
                disabled={
                    edit
                        ? loadding ||
                          (roles.filter(
                              (item) => item.role_id === selectedRole,
                          )[0].name === rolePermissions.name &&
                              rolePermissions.level === level)
                        : loadding
                }
                open={open}
                setOpen={setOpen}
                edit={edit}
                setEdit={setEdit}
                title={edit ? 'Edit Role' : 'Add new Role'}
                onSubmit={edit ? () => upDateRole('name') : CreateRole}
            >
                <Grid item xs={12}>
                    <Input
                        label='Role Name'
                        required={true}
                        onChange={(event) =>
                            edit
                                ? editOnChange('name', event.target.value)
                                : setRoleName(event.target.value)
                        }
                        defaultValue={edit ? rolePermissions.name : roleName}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth size='small'>
                        <InputLabel>Group *</InputLabel>
                        <Select
                            label='Group'
                            required
                            value={edit ? rolePermissions.group : group}
                            onChange={(event) =>
                                edit
                                    ? editOnChange('group', event.target.value)
                                    : setGroup(event.target.value)
                            }
                        >
                            <MenuItem value='User'>User</MenuItem>
                            <MenuItem value='Admin'>Admin</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl size='small' fullWidth>
                        <InputLabel>Level *</InputLabel>
                        <Select
                            required
                            label='Level'
                            value={level || ''}
                            onChange={(v) => setLevel(v.target.value)}
                        >
                            <MenuItem value={''}>-</MenuItem>
                            {Array.from({ length: auth?.role_level - 1 }).map(
                                (_, index) => (
                                    <MenuItem
                                        value={index + 1}
                                        key={index + 500}
                                    >
                                        {index + 1}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </ModalRequest>
            {openDelete && (
                <ModalDelete
                    disabled={loadding}
                    onSubmit={deleteRole}
                    open={openDelete}
                    setOpen={setOpenDelete}
                    message={`${
                        roles.filter((item) => item.role_id === selectedRole)[0]
                            .name
                    }`}
                />
            )}
            <AlertPopup
                open={openAlert}
                setOpen={setOpenAlert}
                message={message}
            />
        </Container>
    )
}
const header = {
    icon: <CreditCardIcon sx={{ color: 'white' }}></CreditCardIcon>,
    color: 'grey',
    title: 'ROLE & PERMISSION',
}
