import React, { useContext, useEffect, useState } from 'react'
import ModalRequest from '../../components/ModalRequest'
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
    Box,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { urlConfig } from '../../config/apiConfig'
import axiosInstance from '../../config/axiosConfig'
import { AlertContext } from '../../context/AlertContext'
import dayjs from 'dayjs'
export default function ModalReport({
    open,
    setOpen,
    handleAlert,
    keyName,
    title,
    req,
    optionExport,
    selectDevice,
    startDate,
    endDate,
    nameParam,
}) {
    const { handleSubmit, setValue, control, watch } = useForm()
    const [loading, setLoading] = useState(false)
    const { handleOpen } = useContext(AlertContext)
    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const encodedOptions = encodeURIComponent(
                watch('selectedOptions')
                    .map((item) => item.trim())
                    .join(','),
            )
            const encodedStartDate = encodeURIComponent(`${startDate} 00:00:00`)
            const encodedEndDate = encodeURIComponent(`${endDate} 23:59:59`)

            const formattedUrl = `${
                urlConfig.export
            }/history/${selectDevice}?format=${
                title === 'pdf' ? 'pdf' : 'csv'
            }&key=${encodedOptions}&date_from=${dayjs(startDate).format(
                'YYYY-MM-DD',
            )} 00:00:00&date_to=${dayjs(endDate).format('YYYY-MM-DD')} 23:59:59`
            console.log(formattedUrl)
            const res = await axiosInstance.get(formattedUrl, {
                responseType: 'arraybuffer',
            })
            console.log('res', res)
            const blob = new Blob([res.data], {
                type:
                    title === 'pdf'
                        ? 'application/pdf'
                        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const blobURL = window.URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = blobURL
            // link.download = `history%20%log%20${startDate}%2000_00_00&date_to=${endDate}%2023:59:59.${
            //     title === 'pdf' ? 'pdf' : 'xlsx'
            // }`
            link.download = `history_log_report.${
                title === 'pdf' ? 'pdf' : 'xlsx'
            }`
            link.click()
            setOpen(false)
            handleOpen({ open: false })
        } catch (err) {
            console.log(err)
            handleOpen({
                open: true,
                message: `${err.response?.data.message}`,
                isSuccess: false,
            })
        } finally {
            setLoading(false)
        }
    }
    const handleChange = (name, value) => {
        setValue(name, value)
    }

    useEffect(() => {
        if (optionExport.length > 0) {
            const allOptionIds = optionExport.map((option) => option.id)
            setValue('selectedOptions', allOptionIds)
        }
    }, [optionExport, setValue])

    return (
        <ModalRequest
            ex={true}
            disabled={loading || !optionExport.length > 0}
            open={open}
            setOpen={setOpen}
            title={`Export ${title}`}
            onSubmit={handleSubmit(onSubmit)}
            width='600px'
        >
            {optionExport.length > 0 ? (
                <FormControl fullWidth size='small' sx={{ marginTop: '20px' }}>
                    <InputLabel id='multi-select-label'>
                        Select Columns
                    </InputLabel>
                    <Controller
                        name='selectedOptions'
                        control={control}
                        render={({ field }) => (
                            <Select
                                sx={{ borderRadius: '30px' }}
                                {...field}
                                label='Select Columns'
                                multiple
                                value={field.value || []}
                                onChange={(event) =>
                                    handleChange(
                                        'selectedOptions',
                                        event.target.value,
                                    )
                                }
                                renderValue={(selected) => (
                                    <div
                                        style={{ display: 'flex', gap: '5px' }}
                                    >
                                        {selected.map((value, index) => (
                                            <Chip
                                                key={index}
                                                label={
                                                    optionExport.find(
                                                        (option) =>
                                                            option.id === value,
                                                    ).label
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                {optionExport.map((option, index) => (
                                    <MenuItem key={index} value={option.id}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
            ) : (
                <Box
                    sx={{
                        width: '100%',
                    }}
                    align='center'
                >
                    Not Found
                </Box>
            )}
        </ModalRequest>
    )
}
