import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, Grid, Typography } from '@mui/material'
import DeviceDetailCard from './DeviceDetailCard'
import axiosInstance from '../../../../../config/axiosConfig'
import { urlConfig } from '../../../../../config/apiConfig'
import { AlertContext } from '../../../../../context/AlertContext'

export default function DetailHeader({
    data,
    handleAlert,
    user,
    typeDevice,
    connectedWB,
}) {
    const { handleOpen } = useContext(AlertContext)
    const [deviceDetail, setDeviceDetail] = useState(null)
    const [dataTpye, setDataType] = useState(false)
    const [color, setColor] = useState('gray')

    const handleDeviceDetail = useCallback(async () => {
        console.log('req using status')
        try {
            const resp = await axiosInstance.get(
                `${urlConfig.deviceIot}/id/${data.device_id}`,
            )
            if (resp.data.status === 200) {
                setDeviceDetail(resp.data.message)
            }
            handleOpen({ open: false })
        } catch (error) {
            console.error('Error:', error)
            setDeviceDetail([])
            handleOpen({
                open: true,
                message:
                    error.response?.data.message ||
                    'Failed to fetch device details',
                isSuccess: false,
            })
        }
    }, [data.device_id, handleOpen])

    const handleWebSocketData = useCallback(() => {
        if (typeDevice.device_id !== data.device_id) return
        setDeviceDetail(typeDevice.data)
        setDataType(true)
    }, [typeDevice, typeDevice, data])

    useEffect(() => {
        let interval
        let timeout
        if (deviceDetail?.status === 'Online') {
            if (deviceDetail?.type_alert === 'Trigger state') {
                if (deviceDetail.alarm_type) {
                    const alarmTimeInterval =
                        deviceDetail?.alarm_time_interval * 1000

                    interval = setInterval(() => {
                        setColor((prevColor) =>
                            prevColor === '#FFC700' ? '#584400' : '#FFC700',
                        )
                    }, 500)

                    timeout = setTimeout(() => {
                        clearInterval(interval)
                        setColor('#4CAF50')
                    }, alarmTimeInterval)
                } else {
                    setColor('#4CAF50')
                }
            } else if (deviceDetail?.type_alert === 'Remain state') {
                if (deviceDetail.alarm_type) {
                    interval = setInterval(() => {
                        setColor((prevColor) =>
                            prevColor === '#FF001F' ? '#67000C' : '#FF001F',
                        )
                    }, 500)
                } else {
                    setColor('#4CAF50')
                }
            }
        } else {
            setColor('gray')
        }

        return () => {
            clearInterval(interval)
            clearTimeout(timeout)
        }
    }, [deviceDetail])

    // useEffect(() => {
    //     handleDeviceDetail()
    //     const intervalId1 = setInterval(handleDeviceDetail, 3000)
    //     return () => clearInterval(intervalId1)
    // }, [])

    useEffect(() => {
        if (
            connectedWB &&
            data &&
            typeDevice &&
            typeDevice.device_id === data.device_id
        ) {
            console.log('web using status')
            handleWebSocketData()
        }
    }, [handleWebSocketData, typeDevice])

    console.log('device_id web', typeDevice.device_id)
    console.log('device_id client', data.device_id)

    useEffect(() => {
        if (!connectedWB || !dataTpye) {
            handleDeviceDetail()
            const intervalId = setInterval(handleDeviceDetail, 2000)
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [connectedWB])

    return (
        <Grid
            container
            gap={2}
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={{ xs: 'column', md: 'row' }}
        >
            <Grid
                item
                xs={12}
                md={6.6}
                sx={{ border: '2px solid #B1B2B2', borderRadius: 3, p: 1 }}
            >
                <DeviceDetailCard
                    user={user}
                    data={data}
                    handleAlert={handleAlert}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={5}
                sx={{
                    border: '2px solid #B1B2B2',
                    borderRadius: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                flexDirection={'column'}
                p={1}
                gap={2}
            >
                <Typography variant='h6'>Real-Time Status</Typography>
                <Box
                    sx={{
                        borderRadius: 50,
                        border: '2px solid #B1B2B2',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircleIcon
                        sx={{
                            fontSize: 150,
                            color:
                                deviceDetail?.status === 'Offline'
                                    ? 'gray'
                                    : deviceDetail?.status === 'Online' &&
                                      !deviceDetail.type_alert
                                    ? '#4CAF50'
                                    : color,
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
