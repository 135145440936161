import { styled, useTheme } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useLocation, useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import menus from '../../config/menu-drawer.jsx'
import { Box, Typography } from '@mui/material'
import { useAuth } from '../../hook/useAuth.jsx'
import React from 'react'

const drawerWidth = 250

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}))

export default function MenuDrawer({ open, handleDrawerClose }) {
    const { auth } = useAuth()
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const admin = auth?.Permissions.filter((item) => item.name === 'superAdmin')
    const isAdmin = auth?.group === 'Admin'
    const menuList = menus(isAdmin ? 1 : 2, auth)

    const handleRedirect = (route) => {
        navigate(route)
    }
    const isOrganizationsPath = location.pathname.startsWith('/organizations/')
    const Graph = location.pathname.startsWith('/graph-trend/')

    return (
        <Drawer
            variant='permanent'
            open={open}
            sx={{
                top: '64px',
                '& .MuiDrawer-paper': {
                    bgcolor: theme.palette.primary.main,
                    px: open ? 1 : 0.5,
                },
            }}
        >
            <CssBaseline />
            <DrawerHeader sx={{ p: 0, gap: 0.5 }}>
                <IconButton onClick={handleDrawerClose} sx={{ p: 0 }}>
                    <ChevronLeftIcon
                        sx={{ color: theme.palette.success.light }}
                    />
                </IconButton>
            </DrawerHeader>
            {!admin[0] && !isAdmin && (
                <List sx={{ p: 0, mt: 1 }}>
                    {[auth.keyName].map((text, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{ display: 'block' }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: theme.palette.success.light,
                                    }}
                                >
                                    <svg
                                        viewBox='0 0 24 24'
                                        width={25}
                                        height={25}
                                        fill='white'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path d='M11.62 9.21c.23.18.37.47.37.78v12c0 .55-.45 1-1 1h-9c-.56 0-1-.45-1-1v-10c0-.47.32-.88.78-.98l9-2c.29-.07.6 0 .84.19Zm-8.63 3.58v8.19h7v-9.76l-7 1.55Z' />
                                        <path d='M22.64 1.23c.22.18.35.47.35.76v20c0 .55-.45 1-1 1h-11c-.56 0-1-.45-1-1v-18c0-.49.34-.9.82-.99l11-2c.29-.06.59.02.81.21Zm-10.65 3.6v16.16h9V3.18l-9 1.63Z' />
                                        <path d='M13.5 7.5V6h2v1.5h-2Z' />
                                        <path d='M13.5 12v-1.5h2V12h-2Z' />
                                        <path d='M13.5 16.5V15h2v1.5h-2Z' />
                                        <path d='M4 15.5V14h2v1.5H4Z' />
                                        <path d='M4 19.5V18h2v1.5H4Z' />
                                        <path d='M17.5 7.5V6h2v1.5h-2Z' />
                                        <path d='M17.5 12v-1.5h2V12h-2Z' />
                                        <path d='M17.5 16.5V15h2v1.5h-2Z' />
                                        <path d='M7 15.5V14h2v1.5H7Z' />
                                        <path d='M7 19.5V18h2v1.5H7Z' />
                                    </svg>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <>
                                            <div style={{ fontSize: '14px' }}>
                                                {auth.site_name}
                                            </div>{' '}
                                            <div style={{ fontSize: '12px' }}>
                                                {text}
                                            </div>
                                        </>
                                    }
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        color: theme.palette.success.light,
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}
            {!isAdmin && (
                <Divider
                    sx={{
                        my: 1,
                        mx: 'auto',
                        width: '90%',
                        bgcolor: 'white',
                    }}
                />
            )}
            <List>
                {menuList.map((menu, index) => (
                    <React.Fragment key={index}>
                        {menu.allow && (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    display: 'block',
                                    bgcolor:
                                        location.pathname === menu.route ||
                                        location.pathname === menu.ref2 ||
                                        (menu.route === '/organizations' &&
                                            isOrganizationsPath) ||
                                        (menu.route === '/graph-trend' && Graph)
                                            ? theme.palette.error.dark
                                            : 'transparent',
                                }}
                                onClick={() => {
                                    // handleDrawerClose()
                                    handleRedirect(menu.route)
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open
                                            ? 'initial'
                                            : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            color: theme.palette.success.light,
                                        }}
                                    >
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography
                                                variant='body1'
                                                sx={{ fontWeight: 'normal' }}
                                            >
                                                {menu.name}
                                            </Typography>
                                        }
                                        sx={{
                                            opacity: open ? 1 : 0,
                                            color: theme.palette.success.light,
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </React.Fragment>
                ))}
            </List>
            <Box
                sx={{
                    marginTop: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '10px',
                    color: 'white',
                    fontWeight: '200',
                    fontSize: '12px',
                }}
            >
                {open && ` ${process.env.REACT_APP_VERSION}`}
            </Box>
        </Drawer>
    )
}
