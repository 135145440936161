import ModalRequest from '../../components/ModalRequest'
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/StyleComponent'
import { useCallback, useEffect, useState } from 'react'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import { useAuth } from '../../hook/useAuth'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export default function EditUser({
    open,
    setOpen,
    keyName,
    handleAlert,
    getUserByKey,
    row,
}) {
    const [role, setRole] = useState([])
    const [loading, setLoading] = useState(false)
    const { handleSubmit, setValue, watch } = useForm()
    const { auth } = useAuth()

    const onSubmit = async (data) => {
        if (data.password !== data.confirmPassword) {
            alert('Passwords do not match!')
            return
        }
        const body = {
            ...data,
            keyName: keyName,
            // verified_account: !!data.verified_account,
            status: !!data.status ? 'Active' : 'Inactive',
            expire_date: dayjs(data.expire_date, 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
            ),
        }
        try {
            setLoading(true)
            await axiosInstance.put(`${urlConfig.user}/${row.user_id}`, body)
            handleAlert('User updated successfully.', 'success')
            getUserByKey()
            setOpen(false)
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (row) {
            Object.keys(row).forEach((key) => {
                if (key === 'expire_date') {
                    setValue(key, dayjs(row[key]))?.format('DD/MM/YYYY')
                } else {
                    setValue(key, row[key])
                }
            })
        }
    }, [row, setValue])

    const handleChange = (name, value) => {
        setValue(name, value)
    }

    const getRole = useCallback(async () => {
        try {
            const res = await axiosInstance.get(urlConfig.role)
            setRole(res.data.message)
        } catch {
            // Handle error
        }
    }, [])

    // console.log(role && role.filter((item) => item.level < auth))
    useEffect(() => {
        getRole()
    }, [getRole])

    return (
        <ModalRequest
            open={open}
            disabled={loading}
            setOpen={setOpen}
            title={`Edit User [${keyName}]`}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        size='small'
                        sx={{
                            height: '35px',
                            // borderRadius: '10px',
                        }}
                    >
                        <InputLabel id='demo-simple-select-label'>
                            Group
                        </InputLabel>
                        <Select
                            label='Group'
                            id='Role'
                            required
                            onChange={(e) =>
                                handleChange('group', e.target.value)
                            }
                            defaultValue={''}
                            value={watch('group') || ''}
                        >
                            <MenuItem value={'User'}>User</MenuItem>
                            <MenuItem value={'Admin'}>Admin</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {watch('group') && (
                    <Grid item xs={12} md={6}>
                        <FormControl size='small' fullWidth>
                            <InputLabel>Role * </InputLabel>
                            <Select
                                label='Role'
                                required
                                onChange={(e) =>
                                    handleChange('role_id', e.target.value)
                                }
                                value={watch('role_id') || ''}
                                sx={{
                                    // height: '35px',
                                    borderRadius: '5px',
                                }}
                            >
                                <MenuItem value=''>-</MenuItem>
                                {role
                                    .filter(
                                        (item) => item.group === watch('group'),
                                    )
                                    .map((item) => (
                                        <MenuItem
                                            key={item.role_id}
                                            value={item.role_id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    {/* <Input
                        label='Expire'
                        type='date'
                        defaultValue={watch('expire_date') || ''}
                        required
                        name='expire_date'
                        onChange={(e) =>
                            handleChange('expire_date', e.target.value)
                        }
                    /> */}
                    <DatePicker
                        sx={{ width: '100%' }}
                        name='expire_date'
                        label='Expire Date'
                        InputLabelProps={{ shrink: true }}
                        slotProps={{ textField: { size: 'small' } }}
                        value={
                            watch('expire_date')
                                ? dayjs(watch('expire_date'), 'DD/MM/YYYY')
                                : null
                        }
                        format='DD/MM/YYYY'
                        onChange={(date) => {
                            setValue(
                                'expire_date',
                                date ? date.format('DD/MM/YYYY') : '',
                            )
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Input
                        label='First name'
                        defaultValue={watch('first_name') || ''}
                        required
                        name='first_name'
                        onChange={(e) =>
                            handleChange('first_name', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Last name'
                        defaultValue={watch('last_name') || ''}
                        required
                        name='last_name'
                        onChange={(e) =>
                            handleChange('last_name', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Email'
                        defaultValue={watch('email') || ''}
                        type='email'
                        required
                        name='email'
                        onChange={(e) => handleChange('email', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Input
                        label='Phone number'
                        defaultValue={watch('phone_number') || ''}
                        name='phone_number'
                        onChange={(e) =>
                            handleChange('phone_number', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12} align='right'>
                    <Box>
                        <label>Active</label>
                        <Switch
                            checked={
                                (typeof watch('status') === 'string' &&
                                watch('status') === 'Active'
                                    ? true
                                    : false) ||
                                (typeof watch('status') === 'boolean' &&
                                    watch('status')) ||
                                false
                            }
                            color='success'
                            onChange={(e, nv) => handleChange('status', nv)}
                        />
                    </Box>
                    {/* <Box>
                        <label>Verified</label>
                        <Switch
                            color='success'
                            checked={watch('verified_account') || false}
                            onChange={(e, nv) =>
                                handleChange('verified_account', nv)
                            }
                        />
                    </Box> */}
                </Grid>
            </Grid>
        </ModalRequest>
    )
}
