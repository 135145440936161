import { createContext, useState } from 'react'
import Alert from '../components/common/Alert'

const initModal = {
    open: false,
    title: '',
    message: '',
    isSuccess: false,
    isWarning: false,
}

export const AlertContext = createContext({
    modal: initModal,
    handleClose: () => {},
    handleOpen: () => {},
})

export const AlertProvider = ({ children }) => {
    const [modal, setModal] = useState({ ...initModal })

    const handleOpen = (obj) => {
        setModal(obj)
    }

    const handleClose = () => {
        setModal((prev) => ({ ...prev, open: false }))
    }

    return (
        <AlertContext.Provider value={{ modal, handleClose, handleOpen }}>
            {children}
            <Alert />
        </AlertContext.Provider>
    )
}
