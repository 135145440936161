import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Tooltip,
} from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Input } from '../../components/StyleComponent'
import { useForm, Controller } from 'react-hook-form'
import { urlConfig } from '../../config/apiConfig'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import axiosInstance from '../../config/axiosConfig'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

import { useNavigate } from 'react-router-dom'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ModalDeleteOrg from './DeleteOrg'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AlertContext } from '../../context/AlertContext'

export default function Setting({ keyName, handleAlert, remove, update }) {
    const [data, setData] = useState('')
    const { handleSubmit, control, setValue, watch } = useForm()
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [exStatus, setExStatus] = useState(false)
    const [active, setAcitve] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const labelStyle = 'original'
    const timezones = {
        ...allTimezones,
        'Europe/Berlin': 'Frankfurt',
    }
    const { options } = useTimezoneSelect({
        labelStyle,
        timezones,
    })

    const { handleOpen } = useContext(AlertContext)

    const getOrg = useCallback(async () => {
        try {
            const res = await axiosInstance(
                `${urlConfig.organization}/${keyName}`,
            )
            setData(res.data.message)
            setAcitve(res.data.message?.status === 'Active' || false)
            setExStatus(!!res.data.message?.expire_date)
            Object.keys(res.data.message).forEach((key) => {
                if (key === 'expire_date') {
                    const expireDate = res.data.message[key]
                    if (expireDate) {
                        setValue(key, extractDate(expireDate))
                    } else {
                        setValue(key, null)
                    }
                } else {
                    setValue(key, res.data.message[key])
                }
            })
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [keyName, setValue])

    const onSubmit = async (data) => {
        const body = {
            ...data,
            // expire_date: exStatus ? data.expire_date : null,
            expire_date: exStatus
                ? dayjs(data.expire_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : null,
            status: active ? 'Active' : 'Inactive',
        }
        setLoading(true)
        try {
            await axiosInstance.put(
                `${urlConfig.organization}/${keyName}`,
                body,
            )
            handleAlert('Organization update successfully.', 'success')
            getOrg()
        } catch (err) {
            handleAlert('Failed to update the Organization', 'error')
            console.error(err)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getOrg()
    }, [getOrg])

    const deleteRole = async () => {
        setLoading(true)
        try {
            await axiosInstance.delete(`${urlConfig.organization}/${keyName}`)
            setOpen(false)
            handleAlert('Organization delete successfully.', 'success')
            navigate('/organizations')
        } catch {
            handleAlert('Failed to delete the organization', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (data) {
            Object.keys(data).forEach((key) => {
                if (key === 'expire_date') {
                    setValue(key, dayjs(data[key]))?.format('DD/MM/YYYY')
                }
            })
        }
    }, [data, setValue])
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        padding: '15px 50px',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                            GENERAL&#160;
                            <span
                                style={{
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: 'green',
                                }}
                            >
                                {edit && '[ EDIT MODE ]'}
                            </span>
                        </Box>

                        <Box sx={{ whiteSpace: 'nowrap' }}>
                            {!edit && (
                                <Tooltip title='Delete organization'>
                                    <Button
                                        sx={{
                                            borderRadius: '35px',
                                            textTransform: 'none',
                                            height: '30px',
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                        }}
                                        // size='small'
                                        color='error'
                                        variant='contained'
                                        size='small'
                                        startIcon={
                                            <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                                        }
                                        onClick={() => setOpen(true)}
                                        disabled={!remove}
                                    >
                                        {/* <DeleteOutlineOutlinedIcon color='error' /> */}
                                        DELETE
                                    </Button>
                                </Tooltip>
                            )}
                            {edit && (
                                <Button
                                    color='error'
                                    variant='contained'
                                    // size='small'
                                    startIcon={
                                        <CloseRoundedIcon></CloseRoundedIcon>
                                    }
                                    sx={{
                                        borderRadius: '35px',
                                        textTransform: 'none',
                                        height: '30px',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                    }}
                                    onClick={() => {
                                        setEdit(false)
                                        getOrg()
                                    }}
                                >
                                    CANCEL
                                </Button>
                            )}
                            {edit && (
                                <Button
                                    sx={{
                                        borderRadius: '35px',
                                        textTransform: 'none',
                                        height: '30px',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        mx: 1,
                                    }}
                                    color={'success'}
                                    variant='contained'
                                    // size='small'
                                    type={'submit'}
                                    disabled={loading}
                                    startIcon={
                                        <SaveAltOutlinedIcon></SaveAltOutlinedIcon>
                                    }
                                >
                                    Save
                                </Button>
                            )}
                            {!edit && (
                                <Tooltip title='Edit organization'>
                                    <Button
                                        disabled={!update}
                                        sx={{
                                            borderRadius: '35px',
                                            textTransform: 'none',
                                            height: '30px',
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                            mx: 1,
                                        }}
                                        // size='small'
                                        color='warning'
                                        variant='contained'
                                        size='small'
                                        startIcon={
                                            <EditOutlinedIcon></EditOutlinedIcon>
                                        }
                                        onClick={() => setEdit(true)}
                                    >
                                        {/* <EditOutlinedIcon /> */}
                                        EDIT
                                    </Button>
                                </Tooltip>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        {((data && data.status === 'Inactive') ||
                            data.status === 'Expired') && (
                            <Box sx={{ color: 'red' }}>
                                the organization's usage has expired
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name='site_name'
                            control={control}
                            defaultValue={data?.site_name || ''}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    label='Customer Name (Organization name)'
                                    required
                                    value={value || ''}
                                    onChange={onChange}
                                    readOnly={!edit}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name='keyName'
                            control={control}
                            defaultValue={data?.keyName || ''}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    label='KeyName'
                                    required
                                    value={value || ''}
                                    onChange={onChange}
                                    readOnly={true}
                                    // readOnly={!edit}
                                />
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <DatePicker
                            sx={{ width: '100%' }}
                            name='expire_date'
                            label='Expire Date'
                            InputLabelProps={{ shrink: true }}
                            slotProps={{ textField: { size: 'small' } }}
                            value={
                                watch('expire_date')
                                    ? dayjs(watch('expire_date'), 'DD/MM/YYYY')
                                    : null
                            }
                            format='DD/MM/YYYY'
                            disabled={!edit || !exStatus}
                            onChange={(date) => {
                                setValue(
                                    'expire_date',
                                    date ? date.format('DD/MM/YYYY') : '',
                                )
                            }}
                        />
                        {/* <Controller
                            // defaultValue={data?.expire_date || ''}
                            name='expire_date'
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <TextField
                                    size='small'
                                    fullWidth
                                    label={<>Expire</>}
                                    required
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    value={value || ''}
                                    onChange={onChange}
                                    disabled={!edit || !exStatus}
                                />
                            )}
                        /> */}
                        <Checkbox
                            checked={exStatus}
                            disabled={!edit}
                            onChange={(_, nv) => setExStatus(nv)}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name='latitude'
                            control={control}
                            defaultValue={data?.latitude || ''}
                            render={({ field }) => (
                                <Input
                                    label='Lattitude'
                                    required
                                    type='number'
                                    value={field.value || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        if (value === '-' || value === '') {
                                            field.onChange(value)
                                            return
                                        }
                                        const regex = /^-?\d*(\.\d{0,5})?$/
                                        if (regex.test(value)) {
                                            let parsedValue = parseFloat(value)
                                            if (!isNaN(parsedValue)) {
                                                if (parsedValue > 90) {
                                                    parsedValue = 90
                                                } else if (parsedValue < -90) {
                                                    parsedValue = -90
                                                }
                                            }

                                            field.onChange(
                                                parsedValue ||
                                                    (value === '.' && value),
                                            )
                                        }
                                    }}
                                    readOnly={!edit}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Controller
                            name='longitude'
                            control={control}
                            defaultValue={data?.longitude || ''}
                            render={({ field }) => (
                                <Input
                                    label='Longitude'
                                    required
                                    type='number'
                                    value={field.value || ''}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        if (value === '-' || value === '') {
                                            field.onChange(value)
                                            return
                                        }
                                        const regex = /^-?\d*(\.\d{0,5})?$/
                                        if (regex.test(value)) {
                                            let parsedValue = parseFloat(value)
                                            if (!isNaN(parsedValue)) {
                                                if (parsedValue > 180) {
                                                    parsedValue = 180
                                                } else if (parsedValue < -180) {
                                                    parsedValue = -180
                                                }
                                            }

                                            field.onChange(
                                                parsedValue ||
                                                    (value === '.' && value),
                                            )
                                        }
                                    }}
                                    readOnly={!edit}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='timezone-label'>
                                TimeZone
                            </InputLabel>
                            <Controller
                                name='timezone'
                                control={control}
                                defaultValue=''
                                render={({ field: { value, onChange } }) => (
                                    <Select
                                        disabled={!edit}
                                        labelId='timezone-label'
                                        label='TimeZone'
                                        value={value || ''}
                                        onChange={onChange}
                                    >
                                        {options.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Controller
                            name='address'
                            control={control}
                            defaultValue={data?.address || ''}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    label='Address'
                                    required
                                    value={value || ''}
                                    onChange={onChange}
                                    readOnly={!edit}
                                    rows={4}
                                    multiline
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name='phone_number'
                            control={control}
                            defaultValue={data?.phone_number || ''}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    label='Phone Number'
                                    required
                                    value={value}
                                    onChange={onChange}
                                    readOnly={!edit}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name='email'
                            control={control}
                            defaultValue={data?.email || ''}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    label='Email'
                                    // required
                                    value={value}
                                    onChange={onChange}
                                    readOnly={!edit}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Controller
                            name='line_notify_token'
                            control={control}
                            defaultValue={data?.line_notify_token || ''}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    label='Line Notify Token'
                                    // required
                                    value={value}
                                    onChange={onChange}
                                    readOnly={!edit}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} align='right'>
                        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                            ACTIVE
                        </span>{' '}
                        <Switch
                            color='success'
                            checked={active}
                            disabled={!edit}
                            onChange={(_, nv) => setAcitve(nv)}
                        />
                    </Grid>
                </Grid>
            </form>

            <ModalDeleteOrg
                disabled={loading}
                onSubmit={deleteRole}
                open={open}
                setOpen={setOpen}
                message={`${keyName}`}
                data={data || ''}
            />
        </React.Fragment>
    )
}
function extractDate(datetime) {
    return datetime.split('T')[0]
}
