import { useCallback, useEffect, useState } from 'react'
import columnConfig from '../../config/columnsConfig'
import { Grid } from '@mui/material'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import { AlertPopup, handdleOpenAlert } from '../../components/StyleComponent'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModalDelete from '../../components/ModalDelete'
import Edit from './Edit'
import ADD_USER from './ADD_USER'
import TableComponents from '../../components/TableComponents'

export default function UserList({ create, update, remove }) {
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [row, setRow] = useState('')
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('')
    const columns = columnConfig('User Management')

    const handleAlert = (message, status) => {
        handdleOpenAlert(setOpenAlert, setMessage, message, status)
        if (status === 'success') {
            getUserByKey()
        }
    }

    const ListMenu = []

    if (update) {
        ListMenu.push({
            title: 'Edit',
            icon: <EditOutlinedIcon color='warning' />,
            action: () => setOpen('edit'),
        })
    }

    if (remove) {
        ListMenu.push({
            title: 'Delete',
            icon: <DeleteOutlineOutlinedIcon color='error' />,
            action: () => setOpen('delete'),
        })
    }

    const getUserByKey = useCallback(async () => {
        setLoading(true) // ตั้งค่า loading เป็น true เมื่อดึงข้อมูล
        setData('')
        try {
            setError(false)
            const res = await axiosInstance.get(`${urlConfig.user}`)
            setData(
                res.data.message?.map((item, index) => {
                    // แปลง last_active เป็นเวลาในโซนเวลา Asia/Bangkok
                    const lastActive = new Date(item.last_assess)
                    const enUSTimeOptions = {
                        timeZone: 'Asia/Bangkok',
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                    }
                    const lastActiveInThailand = new Intl.DateTimeFormat(
                        'en-US',
                        enUSTimeOptions,
                    ).format(lastActive)

                    return {
                        ...item,
                        fullName: `${item.first_name} ${item.last_name}`,
                        index: index + 1,
                        lastActiveInThailand: lastActiveInThailand,
                        date: formatDate(item.last_assess),
                    }
                }),
            )
        } catch {
            setError(true)
        } finally {
            setLoading(false) // ตั้งค่า loading เป็น false หลังจากดึงข้อมูลเสร็จ
        }
    }, [setData])

    const deleteUser = async () => {
        setLoading(true)
        try {
            await axiosInstance.delete(`${urlConfig.user}/${row.user_id}`)
            handleAlert('User delete successfully.', 'success')
            getUserByKey()
            setOpen(false)
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getUserByKey()
    }, [getUserByKey])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableComponents
                    refresh={getUserByKey}
                    disabledButton={create}
                    MenuList={ListMenu}
                    setAction={setRow}
                    data={data}
                    columns={columns}
                    error={error}
                    button={{
                        title: 'Add User',
                        action: () => setOpen('add'),
                    }}
                />
            </Grid>

            <ADD_USER
                handleAlert={handleAlert}
                getUserByKey={getUserByKey}
                open={open === 'add'}
                setOpen={setOpen}
            />
            <Edit
                handleAlert={handleAlert}
                getUserByKey={getUserByKey}
                open={open === 'edit'}
                setOpen={setOpen}
                username={row.username}
                row={row}
                disabled={loading}
            />
            <ModalDelete
                disabled={loading}
                onSubmit={deleteUser}
                open={open === 'delete'}
                setOpen={setOpen}
                message={`${row.fullName}`}
            />
            <AlertPopup
                open={openAlert}
                setOpen={setOpenAlert}
                message={message}
            />
        </Grid>
    )
}

const formatDate = (isoString) => {
    const date = new Date(isoString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`
}
