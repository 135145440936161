import { Container } from "../../components/StyleComponent";


export default function Test (){
    const users = [
        { id: 1, name: 'John', role_id: 1 },
        { id: 2, name: 'Jane', role_id: 2 },
        { id: 3, name: 'Alice', role_id: 1 },
        { id: 4, name: 'Bob', role_id: 3 },
        { id: 5, name: 'Eve', role_id: 2 }
      ];
      function filterRole(roleId) {
        // กรองผู้ใช้ที่มี role_id ตามที่กำหนด
        const filteredUsers = users.filter(user => user.role_id === roleId);
        // นับจำนวนผู้ใช้ที่ได้หลังจากกรอง
        const count = filteredUsers.length;
        // ส่งค่ากลับเป็นจำนวนผู้ใช้ที่ตรงเงื่อนไข
        return count;
      }
console.log('จำนวนคนที่มี role_id เป็น 1:', filterRole(1));
console.log('จำนวนคนที่มี role_id เป็น 2:', filterRole(2));
console.log('จำนวนคนที่มี role_id เป็น 3:', filterRole(3));
return(
   <>
   </>
)

}