import { Box, Divider, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import Setting from './Setting'
import {
    AlertPopup,
    Container,
    handdleOpenAlert,
} from '../../components/StyleComponent'
import DeviceIoTContainer from '../../components/Admin/Organization/DeviceIot/DeviceIotContainer'
import Getway from '../Gateway/Getway'
import User from '../User/User'
import OverviewDND from './OverviewDND'
import { useNavigate, useParams } from 'react-router-dom'
import UserReport from '../Report/UserReport'

export default function Organization({
    data,
    create,
    update,
    remove,
    typeCard,
    typeDevice,
    typeAlarm,
    connectedWB,
}) {
    const [tab, setTab] = useState(0)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const navigate = useNavigate()
    const tablabel = [
        'Overview',
        'Device IOT',
        'User',
        'Gateway',
        'Report',
        'Setting',
    ]
    const { organizationId } = useParams()
    const handleAlert = (message, status) => {
        handdleOpenAlert(setOpenAlert, setMessage, message, status)
    }

    const keyName = organizationId
    const renderTabComponent = () => {
        switch (tab) {
            case 0:
                return (
                    <OverviewDND
                        connectedWB={connectedWB}
                        typeCard={typeCard}
                        keyName={keyName}
                        create={create}
                        update={update}
                        remove={remove}
                    />
                )
            case 1:
                return (
                    <DeviceIoTContainer
                        connectedWB={connectedWB}
                        typeAlarm={typeAlarm}
                        typeDevice={typeDevice}
                        typeCard={typeCard}
                        keyName={keyName}
                        create={create}
                        update={update}
                        remove={remove}
                        handleAlert={handleAlert}
                    />
                )
            case 2:
                return (
                    <User
                        keyName={keyName}
                        create={create}
                        update={update}
                        remove={remove}
                    />
                )
            case 3:
                return (
                    <Getway
                        keyName={keyName}
                        handleAlert={handleAlert}
                        create={create}
                        update={update}
                        remove={remove}
                    />
                )
            case 4:
                return <UserReport keyName={keyName} org={true} admin={true} />
            case 5:
                return (
                    <Setting
                        keyName={keyName}
                        handleAlert={handleAlert}
                        create={create}
                        update={update}
                        remove={remove}
                    />
                )
            default:
                return null
        }
    }

    return (
        <Container
            minWidth={tab === 0 ? '1400px' : ''}
            minHeight={tab === 0 ? '850px' : ''}
            // maxHeight={tab === 0 ? '900px' : ''}
            title={
                <Box
                    sx={{
                        boxShadow: ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;',
                        display: 'flex',
                        borderRadius: '60px',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        padding: ' 4px 5px',
                        color: 'grey',
                        // fontWeight: '200',
                        border: '1px solid lightgrey',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                    }}
                >
                    <Box
                        size='small'
                        sx={{
                            p: '5px',
                            borderRadius: '50px',
                            width: '35px',
                            height: '35px',
                            border: '2px solid white',
                            marginRight: '10px',
                            background: 'grey',
                            boxShadow: ` rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;`,
                        }}
                    >
                        <svg
                            viewBox='0 0 24 24'
                            width={20}
                            height={20}
                            fill='white'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path d='M11.62 9.21c.23.18.37.47.37.78v12c0 .55-.45 1-1 1h-9c-.56 0-1-.45-1-1v-10c0-.47.32-.88.78-.98l9-2c.29-.07.6 0 .84.19Zm-8.63 3.58v8.19h7v-9.76l-7 1.55Z' />
                            <path d='M22.64 1.23c.22.18.35.47.35.76v20c0 .55-.45 1-1 1h-11c-.56 0-1-.45-1-1v-18c0-.49.34-.9.82-.99l11-2c.29-.06.59.02.81.21Zm-10.65 3.6v16.16h9V3.18l-9 1.63Z' />
                            <path d='M13.5 7.5V6h2v1.5h-2Z' />
                            <path d='M13.5 12v-1.5h2V12h-2Z' />
                            <path d='M13.5 16.5V15h2v1.5h-2Z' />
                            <path d='M4 15.5V14h2v1.5H4Z' />
                            <path d='M4 19.5V18h2v1.5H4Z' />
                            <path d='M17.5 7.5V6h2v1.5h-2Z' />
                            <path d='M17.5 12v-1.5h2V12h-2Z' />
                            <path d='M17.5 16.5V15h2v1.5h-2Z' />
                            <path d='M7 15.5V14h2v1.5H7Z' />
                            <path d='M7 19.5V18h2v1.5H7Z' />
                        </svg>
                    </Box>
                    <Box
                        onClick={() => navigate('/organizations')}
                        sx={{
                            cursor: 'pointer',
                            transition: '0.3s ease-out',
                            '&:hover': {
                                color: '#A81E1A',
                                // textDecoration: 'underline',
                            },
                        }}
                    >
                        Organizations
                    </Box>
                    <>
                        &#160;{'/'}&#160; <Box>{keyName} </Box> &#160;{'/'}
                        &#160;
                        <Box sx={{ color: '#A81E1A' }}> {tablabel[tab]}</Box>
                    </>
                </Box>
            }
        >
            <Box height={'100%'} display='flex' flexDirection='column'>
                <Tabs value={tab} onChange={(e, nv) => setTab(nv)}>
                    {tablabel.map((item, index) => (
                        <Tab
                            key={index}
                            value={index}
                            label={
                                <span style={{ fontSize: '12px' }}>{item}</span>
                            }
                            sx={{ color: '#706564' }}
                            // textTransform: 'none',
                        />
                    ))}
                </Tabs>
                <Divider></Divider>
                <Box sx={{ pt: 2, flexGrow: 1, justifyContent: 'center' }}>
                    {renderTabComponent()}
                </Box>
            </Box>

            <AlertPopup
                open={openAlert}
                setOpen={setOpenAlert}
                message={message}
            />
        </Container>
    )
}
