import { Box, Button, Grid, Modal } from '@mui/material'

export default function ModalRequest({
    open,
    setOpen,
    children,
    title,
    onSubmit,
    disabled,
    edit,
    setEdit,
    width,
    infoMode,
    deleteMode,
    ex,
}) {
    const handleSubmit = (event) => {
        event.preventDefault()
        onSubmit()
    }

    return (
        <Modal
            open={edit ? edit : open}
            onClose={() => (edit ? setEdit(false) : setOpen(false))}
            BackdropProps={{
                sx: { backgroundColor: 'rgba(0, 0, 0, 0.35)' },
            }}
            sx={{ overflowY: 'auto' }}
        >
            <Box
                sx={{
                    minWidth: '375px',
                    position: 'absolute',
                    width: width ? width : 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 6,
                    padding: '20px 30px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '10px',
                    // overflowY: 'scroll',
                }}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {title}
                            </div>
                        </Grid>

                        {children}
                        <Grid item xs={12} align='right' mt={2} mb={1}>
                            {infoMode ? (
                                <>
                                    <Button
                                        type='submit'
                                        color='buttonSave'
                                        variant='contained'
                                        size='small'
                                        onClick={() => setOpen(false)}
                                        sx={{
                                            padding: '5px 15px',
                                            fontWeight: '200',
                                            fontSize: '12px',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        CLOSE
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <Button
                                        color='buttonCancel'
                                        variant='contained'
                                        onClick={() =>
                                            edit
                                                ? setEdit(false)
                                                : setOpen(false)
                                        }
                                        size='small'
                                        sx={{
                                            marginRight: '15px',
                                            padding: '5px 15px',
                                            fontWeight: '200',
                                            fontSize: '12px',
                                            boxShadow: 'none',
                                        }}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        type='submit'
                                        color='buttonSave'
                                        variant='contained'
                                        size='small'
                                        sx={{
                                            padding: '5px 15px',
                                            fontWeight: '200',
                                            fontSize: '12px',
                                            boxShadow: 'none',
                                        }}
                                        disabled={disabled}
                                    >
                                        {ex ? 'EXPORT ' : 'SAVE'}
                                    </Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    )
}
