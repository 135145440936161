module.exports = {
    urlAPI: process.env.REACT_APP_API_URL || 'http://test-slms.ubonmicrotech.com',
}

const { urlAPI } = require('./apiConfig')

const urlConfig = {
    baseUrlAPI:
        process.env.REACT_APP_API_URL ||
        'https://kumwellslms.ubonmicrotech.com',
    // process.env.REACT_APP_API_URL ||
    loginUser: `${urlAPI}/api/v1/auth/login`,
    loginOut: `${urlAPI}/api/v1/auth/logout`,
    myToken: `${urlAPI}/api/v1/auth/my-account`,
    reToken: `${urlAPI}/api/v1/auth/refresh-token`,
    logout: `${urlAPI}/api/v1/auth/logout`,
    permission: `${urlAPI}/api/v1/permission`,
    role: `${urlAPI}/api/v1/role`,
    organization: `${urlAPI}/api/v1/organization`,
    user: `${urlAPI}/api/v1/user`,
    deviceType: `${urlAPI}/api/v1/device-type`,
    deviceIot: `${urlAPI}/api/v1/device`,
    weather: `${urlAPI}/api/v1/weather`,
    airPollution: `${urlAPI}/api/v1/air_pollution`,
    notificationSetting: `${urlAPI}/api/v1/notification-setting`,
    gateway: `${urlAPI}/api/v1/gateway`,
    image: `${urlAPI}/api/v1`,
    device: `${urlAPI}/api/v1/device`,
    card: `${urlAPI}/api/v1/card`,
    imageByKey: `${urlAPI}/api/v1/images/organization`,
    realtime: `${urlAPI}/api/v1/real-time-log`,
    alarmLog: `${urlAPI}/api/v1/alarm-log`,
    historylog: `${urlAPI}/api/v1/history-log`,
    settingHistoryLog: `${urlAPI}/api/v1/setting-history-log`,
    export: `${urlAPI}/api/v1/report`,

    //*--Websocket--//
    wbRealTime: process.env.REACT_APP_WS || `ws://test-slms.ubonmicrotech.com:8080/websocket`,
    wbAlarm: process.env.REACT_APP_WS || `ws://test-slms.ubonmicrotech.com:8080/websocket`,
    wbDevice: process.env.REACT_APP_WS || `ws://test-slms.ubonmicrotech.com:8080/websocket`,
}

module.exports = { urlConfig }
