import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CardDnd, { Container, Loadding } from '../../components/StyleComponent'
import { Box } from '@mui/material'
import axiosInstance from '../../config/axiosConfig'
import Cookies from 'js-cookie'
import { urlConfig } from '../../config/apiConfig'
import { WidthProvider, Responsive } from 'react-grid-layout'
import WeatherCard from '../../components/WeatherCard'
import { useAuth } from '../../hook/useAuth'
import PopupAlert from '../../components/PopupAlert'
import { AlertContext } from '../../context/AlertContext'
const ResponsiveGridLayout = WidthProvider(Responsive)

export default function UserDashboard({
    imageUrl,
    setImageUrl,
    typeCard,
    connectedWB,
}) {
    const [positions, setPositions] = useState({})
    const [images, setImages] = useState('')
    const [imagesCheck, setImagesCheck] = useState(false)
    const [cardItem, setCardItem] = useState([])
    const [popup, setPopup] = useState(false)
    const [skip, setSkip] = useState(false)
    const { auth } = useAuth()
    const keyName = auth.keyName || 'kumwell'
    const [cardMode, setCardMode] = useState('normal')
    const { handleOpen } = useContext(AlertContext)

    const handleWebSocketData = useCallback(() => {
        if (!typeCard || !typeCard.data) return
        const filtered = typeCard.data.filter((item) => item.isInDashboard)
        const filteredPositions = filtered.reduce((accumulator, item) => {
            accumulator[item._id] = {
                ...item.position,
                w: 2,
                h: item.params?.length > 3 ? 3 : 2,
            }
            return accumulator
        }, {})
        setCardMode(typeCard.card_size || 'normal')
        setPositions(filteredPositions)
        setCardItem(
            filtered.map((item) => ({
                ...item,
                position: { ...item.position },
            })),
        )
        filtered.forEach((item) => {
            if (item.alarm_popup) {
                setPopup(true)
            }
        })
        setImagesCheck(true)
    }, [typeCard])

    const getDevice = useCallback(async () => {
        console.log('request dashbaord using')
        try {
            const res = await axiosInstance.get(`${urlConfig.card}/${keyName}`)
            const data = res.data.message
            const filtered = res.data.message.filter(
                (item) => item.isInDashboard,
            )
            const filteredPositions = filtered.reduce((accumulator, item) => {
                accumulator[item._id] = {
                    ...item.position,
                    w: 2,
                    h: item.params?.length > 3 ? 3 : 2,
                }
                return accumulator
            }, {})
            setCardMode(res.data.card_size)
            setPositions(filteredPositions)
            setCardItem(
                data?.map((item) => ({
                    ...item,
                    position: { ...item.position },
                })),
            )

            filtered.forEach((item) => {
                if (item.alarm_popup) {
                    setPopup(true)
                }
            })

            setImagesCheck(true)
            handleOpen({ open: false })
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [keyName, handleOpen])

    useEffect(() => {
        if (connectedWB && typeCard) {
            console.log('websocket  dasboard using')
            handleWebSocketData()
        }
    }, [connectedWB, typeCard, handleWebSocketData])

    useEffect(() => {
        if (!connectedWB || !typeCard) {
            getDevice()
            const intervalId = setInterval(getDevice, 2000)
            return () => clearInterval(intervalId)
        }
    }, [connectedWB, typeCard])

    // useEffect(() => {
    //     getDevice()
    //     const intervalId = setInterval(getDevice, 1000)
    //     return () => clearInterval(intervalId)
    // }, [getDevice])

    const getImg = useCallback(async () => {
        const localStorageKey = `image_${auth.keyName}`
        try {
            const res = await axiosInstance.get(
                `${urlConfig.imageByKey}/${auth.keyName}`,
                {
                    responseType: 'blob',
                },
            )
            const blob = new Blob([res.data], { type: 'image/png' })
            const imageUrl = URL.createObjectURL(blob)
            localStorage.setItem(localStorageKey, imageUrl)
            localStorage.setItem(
                `${localStorageKey}_timestamp`,
                Date.now().toString(),
            )

            setImageUrl(imageUrl)
        } catch (error) {
            console.log('Error fetching image:', error)
        }
    }, [auth.keyName])

    const fixBugWeather = (id) => {
        const dataW = { x: 0, y: 0, ...positions[id], w: 3, h: 1.4 * 3 }
        return dataW
    }
    useEffect(() => {
        if (!imageUrl) {
            getImg()
        }
    }, [getImg, imageUrl])

    const sizeCard = {
        normal: { h: 3, w: 2 },
        medium: { h: 4, w: 3 },
        large: { h: 5, w: 4 },
    }[cardMode]

    const updateWarning = async (id) => {
        try {
            await axiosInstance.put(`${urlConfig.device}/popup/${id}`, {
                alarm_popup: false,
            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (skip) {
            const intervalId = setInterval(() => {
                setSkip(false)
            }, 100)
            return () => clearInterval(intervalId)
        }
    }, [skip])

    const getCard = () => {
        const fetchAllImages = async () => {
            try {
                const uniqueDeviceTypes = [
                    ...new Set(
                        cardItem
                            ?.map((item) => item.key_type_id)
                            .filter((type) => type !== undefined),
                    ),
                ]
                const imagePromises = uniqueDeviceTypes?.map((deviceType) =>
                    getIcon(deviceType),
                )
                const imageResults = await Promise.all(imagePromises)

                if (imageResults && imageResults[0]) {
                    setImages(imageResults)
                }
            } catch (error) {
                console.error('Error fetching images:', error)
            }
        }
        if (!images) {
            fetchAllImages()
        }
    }

    useEffect(getCard, [imagesCheck, images, cardItem])

    const getIcon = async (deviceType) => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.deviceType}/${deviceType}`,
            )
            return { deviceType, image: res.data }
        } catch (error) {
            console.error(
                `Error fetching image for device type ${deviceType}:`,
                error,
            )
            return { deviceType, image: null }
        }
    }

    return (
        <Container title={false} dashboard={true}>
            <Box
                sx={{
                    padding: '15px 10px',
                    backgroundImage: imageUrl
                        ? `url('${imageUrl}')`
                        : 'url(/images/dfbg.png)',
                    backgroundSize: 'cover',
                    // maxWidth: '1400px',
                    // background: !imageUrl && 'grey',
                    height: '100%',
                    width: '100%',
                }}
            >
                {cardItem ? (
                    <ResponsiveGridLayout
                        className='layout'
                        cols={{
                            lg: 12,
                            md: 12,
                            sm: 12,
                            xs: 12,
                            xxs: 12,
                        }}
                        rowHeight={36}
                        isDraggable={false}
                        isResizable={false}
                        preventCollision={true}
                        compactType={null}
                        // maxRows={7 * 3}
                        maxRows={18}
                    >
                        {cardItem.map((item, index) =>
                            item.isInDashboard && item.type !== 'Weather' ? (
                                <Box
                                    data-grid={{
                                        ...positions[item._id],
                                        ...sizeCard,
                                    }}
                                    key={item._id}
                                    className='grid-item'
                                    sx={{
                                        boxShadow:
                                            'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <CardDnd
                                        images={images}
                                        cardMode={cardMode}
                                        data={item}
                                        warn={!!item.alarm}
                                        type={item.alarm_type}
                                    />
                                    <PopupAlert
                                        message={item.alarm_message || ''}
                                        updateWarning={() => {
                                            setPopup(false)
                                            updateWarning(item.device_id)
                                            getCard()
                                        }}
                                        open={!!item.alarm_popup && popup}
                                        handleClose={() => setSkip(true)}
                                    ></PopupAlert>
                                </Box>
                            ) : (
                                item.isInDashboard && (
                                    <Box
                                        data-grid={fixBugWeather(item._id)}
                                        key={item._id}
                                        className='grid-item'
                                        sx={{
                                            boxShadow:
                                                'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                            cursor: 'pointer',
                                            borderRadius: '5px',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <WeatherCard />
                                    </Box>
                                )
                            ),
                        )}
                    </ResponsiveGridLayout>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80%',
                        }}
                    >
                        <Loadding />
                    </Box>
                )}
            </Box>
        </Container>
    )
}
