import {
    Box,
    Button,
    Checkbox,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material'
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { WidthProvider, Responsive } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import CardDnd, {
    AlertPopup,
    Loadding,
    handdleOpenAlert,
} from '../../components/StyleComponent'
import Skeleton from '@mui/material/Skeleton'
import CustomModal from '../../components/common/popup/CustomModal'
import UploadImage from '../../components/common/upload-image/UploadImage'
import WeatherCard from '../../components/WeatherCard'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { AlertContext } from '../../context/AlertContext'
import Cookies from 'js-cookie'

const ResponsiveGridLayout = WidthProvider(Responsive)

const OverviewDND = ({
    keyName,
    create,
    update,
    remove,
    typeCard,
    connectedWB,
}) => {
    const [imagesCheck, setImagesCheck] = useState(false)
    const [images, setImages] = useState('')
    const [positions, setPositions] = useState({})
    const [cardItem, setCardItem] = useState()
    const [imageUpload, setImageUpload] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [runParams, setRunParams] = useState(false)
    const [message, setMessage] = useState('')
    const [cardMode, setCardMode] = useState('normal')
    const [layoutPosition, setLayoutPosition] = useState('normal')

    const sizeCard = {
        normal: { h: 3, w: 2 },
        medium: { h: 4, w: 3 },
        large: { h: 5, w: 4 },
    }[cardMode]

    const initializePositions = (newItems) => {
        const updatedPositions = { ...positions }
        let currentY = 0
        let currentX = 0
        const maxCols = 10
        const maxRows = 18

        newItems?.forEach((item) => {
            const itemSize =
                item.type === 'Weather' ? { w: 3, h: 1.4 * 3 } : sizeCard
            if (item.isInDashboard && !updatedPositions[item._id]) {
                console.log('yes?')

                while (true) {
                    if (currentY + itemSize.h > maxRows) {
                        currentY = 0
                        currentX += itemSize.w
                        if (currentX + itemSize.w > maxCols) {
                            currentX = 0
                            currentY = 0
                        }
                    }
                    const isTaken = Object.values(updatedPositions).some(
                        (pos) => pos.x === currentX && pos.y === currentY,
                    )
                    if (!isTaken) {
                        updatedPositions[item._id] = {
                            w: itemSize.w,
                            h: itemSize.h,
                            x: currentX,
                            y: currentY,
                        }
                        currentY += itemSize.h
                        break
                    }
                    currentY += itemSize.h
                    if (currentY >= maxRows) {
                        currentY = 0
                        currentX += itemSize.w
                        if (currentX + itemSize.w > maxCols) {
                            currentX = 0
                            currentY = 0
                        }
                    }
                }
            }
        })
        setPositions(updatedPositions)
    }

    const handleLayoutChange = (layout) => {
        const updatedPositions = {}
        layout.forEach((item) => {
            updatedPositions[item.i] = {
                x: item.x,
                y: item.y,
                w: sizeCard.w,
                h: sizeCard.h,
            }
        })
        setLayoutPosition(updatedPositions)
    }

    useEffect(() => {
        initializePositions(cardItem)
    }, [cardItem])

    const handleSave = async () => {
        setLoading(true)

        try {
            const formData = new FormData()
            formData.append('keyName', keyName)
            formData.append('image', imageUpload)
            await axiosInstance.post(
                `${urlConfig.organization}/upload/${keyName}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            )
            setOpen(false)
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                ' IMG Update successfully.',
                'success',
            )

            getImg()
        } catch (error) {
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                'Failed to updating the IMG',
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    const handleCheckboxChange = (index) => {
        const updatedList = [...cardItem]
        updatedList[index].isInDashboard = !updatedList[index].isInDashboard
        setCardItem(updatedList)
    }

    const handleSetAllDashboardFalse = () => {
        const updatedList = cardItem.map((item) => ({
            ...item,
            isInDashboard: false,
        }))
        setPositions('')
        setCardItem(updatedList)
    }

    const getDevice = useCallback(async () => {
        console.log('request dashbaord using')
        try {
            const res = await axiosInstance.get(`${urlConfig.card}/${keyName}`)
            const data = res.data.message
            const filtered = res.data.message?.filter(
                (item) => item.isInDashboard,
            )

            const sizeCard = {
                normal: { h: 3, w: 2 },
                medium: { h: 4, w: 3 },
                large: { h: 5, w: 4 },
            }[res.data.card_size]

            const filteredPositions = filtered.reduce((accumulator, item) => {
                accumulator[item._id] = {
                    ...item.position,
                    // w: 2,
                    // h: item.params.length > 3 ? 3 : 2,
                    w: sizeCard.w,
                    h: sizeCard.h,
                }
                return accumulator
            }, {})

            setCardMode(res.data.card_size)
            setPositions(filteredPositions)

            setImagesCheck(true)
            setCardItem(
                data?.map((item) => ({
                    ...item,
                    position: {
                        ...item.position,
                        w: sizeCard.w,
                        h: sizeCard.h,
                    },
                })),
            )
        } catch {
        } finally {
        }
    }, [keyName])

    const handleWebSocketData = useCallback(() => {
        console.log('websocket  dasboard using')

        if (!typeCard || !typeCard.data) return
        const filtered = typeCard.data.filter((item) => item.isInDashboard)
        const sizeCard = {
            normal: { h: 3, w: 2 },
            medium: { h: 4, w: 3 },
            large: { h: 5, w: 4 },
        }[typeCard.card_size]

        const filteredPositions = filtered.reduce((accumulator, item) => {
            accumulator[item._id] = {
                ...item.position,
                // w: 2,
                // h: item.params.length > 3 ? 3 : 2,
                w: sizeCard.w,
                h: sizeCard.h,
            }
            return accumulator
        }, {})

        setCardMode(typeCard.card_size)
        setPositions(filteredPositions)

        setImagesCheck(true)
        setCardItem(
            typeCard.data?.map((item) => ({
                ...item,
                position: {
                    ...item.position,
                    w: sizeCard.w,
                    h: sizeCard.h,
                },
            })),
        )
    }, [typeCard])

    useEffect(() => {
        if (connectedWB && typeCard && !runParams) {
            handleWebSocketData()
        }
    }, [connectedWB, typeCard, handleWebSocketData, runParams])

    useEffect(() => {
        if (!connectedWB && !typeCard && !runParams) {
            getDevice()
            const intervalId = setInterval(getDevice, 2000)
            return () => clearInterval(intervalId)
        }
    }, [connectedWB, getDevice, typeCard, runParams])

    useEffect(() => {
        if (!runParams) {
            const interval = setInterval(() => {
                getDevice()
            }, 3000)
            return () => clearInterval(interval)
        }
    }, [getDevice, runParams])

    const { handleOpen } = useContext(AlertContext)

    const updateOrg = async () => {
        try {
            await axiosInstance.put(`${urlConfig.organization}/${keyName}`, {
                keyName: keyName,
                card_size: cardMode,
            })
            handleOpen({ open: false })
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data?.message}`,
                isSuccess: false,
            })
        }
    }

    const submit = async () => {
        updateOrg()
        const check = cardItem.filter((item) => item.isInDashboard)
        const result = Object.keys(positions).map((key) => {
            const { x, y } = positions[key]
            return { _id: key, position: { x, y } }
        })

        const result2 = Object.keys(layoutPosition).map((key) => {
            const { x, y } = layoutPosition[key]
            return { _id: key, position: { x, y } }
        })

        const filteredResult = check
            .map((item) => {
                const position = result.find((pos) => pos._id === item._id)
                const position2 = result2.find((pos) => pos._id === item._id)
                console.log('yes', position2)
                return position
                    ? {
                          _id: item._id,
                          isInDashboard: item.isInDashboard,
                          position: { ...position2.position, ...sizeCard },
                      }
                    : null
            })
            .filter((item) => item !== null)

        const old = cardItem.filter(
            (item) =>
                !filteredResult.some(
                    (resultItem) => resultItem._id === item._id,
                ),
        )
        const body = [
            ...filteredResult,
            old[0] &&
                old.map((item) => ({
                    _id: item._id,
                    isInDashboard: false,
                })),
        ].flat()

        try {
            await axiosInstance.put(
                `${urlConfig.card}/${keyName}`,
                old[0] ? body : filteredResult,
            )
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                ' Card Update successfully.',
                'success',
            )
            getDevice()
        } catch {
            handdleOpenAlert(
                setOpenAlert,
                setMessage,
                'Failed to updating the Card',
                'error',
            )
        }
    }

    const getImg = useCallback(async () => {
        setImageUrl('')
        try {
            const res = await axiosInstance.get(
                `${urlConfig.imageByKey}/${keyName}`,
                {
                    responseType: 'blob',
                },
            )
            const blob = new Blob([res.data], { type: 'image/png' })
            const imageUrl = URL.createObjectURL(blob)
            setImageUrl(imageUrl)
            // handleOpen({ open: false })
        } catch (error) {
            // handleOpen({
            //     open: true,
            //     message: `${error.response?.data.message}`,
            //     isSuccess: false,
            // })
        }
    }, [keyName])

    useEffect(() => {
        if (!imageUrl) {
            getImg()
        }
    }, [getImg, imageUrl])

    useEffect(() => {
        getDevice()
    }, [getDevice])

    //*--websocket--//
    // const connectWebSocket = () => {
    //     const encodedAccessToken = Cookies.get('token')
    //     const accessToken = encodedAccessToken ? atob(encodedAccessToken) : null
    //     const wsUrl = `${urlConfig.wbCard}?token=${accessToken}`
    //     wsRef.current = new WebSocket(wsUrl)
    //     wsRef.current.onopen = () => {
    //         setConnected(true)
    //         console.log('WebSocket connected')
    //         setReconnectAttempts(0)
    //     }
    //     wsRef.current.onmessage = (message) => {
    //         console.log(message)
    //         if (!runParams) {
    //         }
    //     }
    //     wsRef.current.onerror = (error) => {
    //         console.error('WebSocket error:', error)
    //     }
    //     wsRef.current.onclose = () => {
    //         console.log('WebSocket closed')
    //         console.log(`Reconnecting... Attempt ${reconnectAttempts + 1}`)
    //         setReconnectAttempts((prev) => prev + 1)
    //         setConnected(false)
    //         setTimeout(connectWebSocket, reconnectDelay)
    //     }
    // }

    // useEffect(() => {
    //     if (!connected && !runParams) {
    //         connectWebSocket()
    //         getDevice()
    //         const intervalId = setInterval(getDevice, 1000)
    //         return () => clearInterval(intervalId)
    //     }
    // }, [getDevice, connected])

    const fixBugWeather = (id) => {
        const dataW = { x: 0, y: 0, ...positions[id], w: 3, h: 1.4 * 3 }
        return dataW
    }

    useEffect(() => {
        const newSize = sizeCard
        const updatedData = Object.fromEntries(
            Object.entries(positions).map(([key, value]) => [
                key,
                { ...value, w: newSize.w, h: newSize.h },
            ]),
        )
        setPositions(updatedData)
    }, [cardMode])

    useEffect(() => {
        const fetchAllImages = async () => {
            try {
                const uniqueDeviceTypes = [
                    ...new Set(
                        cardItem
                            ?.map((item) => item.device_type)
                            .filter((type) => type !== undefined),
                    ),
                ]
                const imagePromises = uniqueDeviceTypes?.map((deviceType) =>
                    getIcon(deviceType),
                )
                const imageResults = await Promise.all(imagePromises)

                if (imageResults && imageResults[0]) {
                    setImages(imageResults)
                }
            } catch (error) {
                console.error('Error fetching images:', error)
            }
        }
        if (!images) {
            fetchAllImages()
        }
    }, [imagesCheck, images])

    const getIcon = async (deviceType) => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.deviceType}/${deviceType}`,
            )
            return { deviceType, image: res.data }
        } catch (error) {
            console.error(
                `Error fetching image for device type ${deviceType}:`,
                error,
            )
            return { deviceType, image: null }
        }
    }

    const iconUse = (data) =>
        images
            ? images.filter((item) => item.deviceType === data.device_type)
            : []
    const thisUseImg = (item) => item.length > 0 && item[0]?.image.message.image

    return (
        <DndProvider backend={HTML5Backend}>
            <Box align='center'>
                <Box sx={{ maxWidth: '1980px', overflow: 'hidden' }}>
                    <Box
                        id='header'
                        sx={{
                            bgcolor: '#F0F2F5 ',
                            borderRadius: '30px',
                            width: '100%',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: 'space-between',
                            justifyContent: 'flex-end',
                            gap: '10px',
                            padding: '0 10px',
                            marginBottom: '5px',
                            border: '1px solid lightgrey',
                            // background: 'lightgrey',
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: '30px',
                                marginRight: 'auto',
                                fontSize: '11px',
                                fontWeight: 'bold',
                                // marginLeft: '10px',
                                alignItems: 'center',
                                gap: '5px',
                                color: 'white',
                                // border: '1px solid grey',
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: '30px',

                                    fontSize: '11px',
                                    fontWeight: 'bold',
                                    // marginLeft: '10px',
                                    color: 'grey',
                                    // border: '1px solid grey',
                                    p: '1px 10px',
                                    background: 'white',
                                }}
                            >
                                EDIT MODE
                                <Switch
                                    disabled={!update}
                                    size='small'
                                    color='success'
                                    sx={{
                                        '& .MuiSwitch-track': {
                                            border: '1px solid white',
                                        },
                                    }}
                                    checked={runParams}
                                    onChange={(_, nv) => setRunParams(nv)}
                                ></Switch>
                            </Box>

                            <Box
                                sx={{
                                    borderRadius: '30px',
                                    height: '25px',
                                    fontSize: '11px',
                                    fontWeight: 'bold',
                                    // marginLeft: '10px',
                                    color: 'grey',
                                    // border: '1px solid grey',
                                    p: '1px 10px',
                                    background: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                CARD SIZE
                                <select
                                    disabled={!runParams}
                                    onChange={(e) => {
                                        handleSetAllDashboardFalse()
                                        setCardMode(e.target.value)
                                    }}
                                    value={cardMode}
                                    style={{
                                        marginLeft: '10px',
                                        width: '70px',
                                        border: '1px solid lightgrey',
                                        borderRadius: '15px',
                                        color: 'grey',
                                        fontSize: '12px',
                                        padding: '2px',
                                        cursor: 'pointer',
                                        height: '20px',
                                    }}
                                >
                                    <option value='normal'>Normal</option>
                                    <option value='medium'>Medium</option>
                                    <option value='large'>Large</option>
                                </select>
                            </Box>
                            {/* <FormControl>
                                <InputLabel>card size</InputLabel>
                                <Select
                                    label='size'
                                    size='small'
                                    sx={{
                                        width: '100px',
                                        bgcolor: 'white',
                                        borderRadius: '35px',
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    }}
                                ></Select>
                            </FormControl> */}
                            {/* <Tooltip
                                title={
                                    'When you turn on the parameters, you will not be able to change the position.'
                                }
                            >
                                <IconButton
                                    size='small'
                                    sx={{ marginRight: 'auto' }}
                                >
                                    <ErrorOutlineOutlinedIcon fontSize='small'></ErrorOutlineOutlinedIcon>
                                </IconButton>
                            </Tooltip> */}
                        </Box>

                        <Box>
                            {cardItem ? (
                                <>
                                    {' '}
                                    <Button
                                        disabled={!update}
                                        color='success'
                                        variant='contained'
                                        sx={{
                                            borderRadius: '15px',
                                            fontSize: '11px',
                                        }}
                                        size='small'
                                        onClick={() => submit()}
                                        startIcon={
                                            <SaveAltOutlinedIcon fontSize='small' />
                                        }
                                    >
                                        SAVE CHANGE
                                    </Button>
                                    {/* <Button
                                        color='secondary'
                                        variant='outlined'
                                        sx={{
                                            borderRadius: '15px',
                                            fontSize: '10px',
                                            marginLeft: '5px',
                                        }}
                                        size='small'
                                        startIcon={
                                            <VisibilityOutlinedIcon fontSize='small' />
                                        }
                                    >
                                        Preview
                                    </Button> */}
                                </>
                            ) : (
                                <Box sx={{ display: 'flex' }}>
                                    <Skeleton
                                        animation='wave'
                                        variant='rounded'
                                        sx={{
                                            borderRadius: '15px',
                                            width: '130px',
                                            height: '25px',
                                            marginRight: '5px',
                                        }}
                                    />{' '}
                                    {/* <Skeleton
                                        animation='wave'
                                        variant='rounded'
                                        sx={{
                                            borderRadius: '15px',
                                            width: '90px',
                                            height: '25px',
                                            marginRight: '5px',
                                        }}
                                    /> */}
                                </Box>
                            )}
                        </Box>
                        {cardItem ? (
                            <Button
                                onClick={() => setOpen(true)}
                                // color='warning'
                                variant='contained'
                                sx={{ borderRadius: '15px', fontSize: '11px' }}
                                size='small'
                                startIcon={
                                    <FileUploadOutlinedIcon fontSize='small' />
                                }
                                disabled={!update}
                            >
                                Upload IMG
                            </Button>
                        ) : (
                            <Skeleton
                                animation='wave'
                                variant='rounded'
                                sx={{
                                    borderRadius: '15px',
                                    width: '100px',
                                    height: '25px',
                                    marginRight: '5px',
                                }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            // bgcolor: 'red',
                            // borderRadius: '5px',
                            boxShadow:
                                ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                            border: '1px solid lightgrey',
                            borderRadius: '10px',
                            display: 'flex',
                            overflow: 'hidden',
                            maxHeight: '900px',
                            height: '100%',
                            // padding: '5px',
                        }}
                    >
                        {cardItem ? (
                            <>
                                {' '}
                                <Box
                                    sx={{
                                        overflowY: 'auto',
                                        width: '15%',
                                        height: '850px',
                                        padding: '10px',
                                        background: '#fafafa',
                                    }}
                                    id='CardMenu'
                                >
                                    {cardItem[0] ? (
                                        cardItem.map((item, index) => (
                                            <Box
                                                key={index + 500}
                                                className='grid-item'
                                                sx={{
                                                    border:
                                                        item.type !== 'Weather'
                                                            ? '1px solid lightgrey'
                                                            : '1px solid #3BA7FF',
                                                    bgcolor:
                                                        item.type !== 'Weather'
                                                            ? 'white'
                                                            : '#bae3ff',
                                                    borderRadius: '5px',
                                                    padding: '5px 5px',
                                                    marginBottom: '8px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    boxShadow:
                                                        ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                                }}
                                            >
                                                {item.type !== 'Weather' ? (
                                                    <Box
                                                        size='small'
                                                        sx={{
                                                            marginTop: '5px',
                                                            background:
                                                                '#e6e6e6',
                                                            borderRadius:
                                                                '50px',
                                                            border: '3px solid white',
                                                            height: '30px',
                                                            width: '30px',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            boxShadow:
                                                                'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                thisUseImg(
                                                                    iconUse(
                                                                        item,
                                                                    ),
                                                                )
                                                                    ? `${
                                                                          urlConfig.baseUrlAPI
                                                                      }/api/v1${thisUseImg(
                                                                          iconUse(
                                                                              item,
                                                                          ),
                                                                      )}`
                                                                    : ''
                                                            }
                                                            alt=''
                                                            height={'70%'}
                                                            width={'70%'}
                                                            style={
                                                                {
                                                                    // objectFit:
                                                                    //     'cover',
                                                                    // borderRadius:
                                                                    //     '50px',
                                                                }
                                                            }
                                                        ></img>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        size='small'
                                                        sx={{
                                                            border: '3px solid  white',
                                                            background:
                                                                '#3ba7ff',
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                            padding: '3px',
                                                            borderRadius:
                                                                '500px',
                                                        }}
                                                    >
                                                        <CloudOutlinedIcon
                                                            sx={{
                                                                color: 'white',
                                                                width: '25px',
                                                                height: '25px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                <Tooltip title={item.type}>
                                                    <Box
                                                        sx={{
                                                            overflow: 'hidden',
                                                            fontSize: '11px',
                                                            textAlign: 'left',
                                                            padding: '0 5px',
                                                        }}
                                                    >
                                                        {item.type !==
                                                            'Weather' && (
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '8px',

                                                                    background:
                                                                        item.status ===
                                                                        'Offline'
                                                                            ? '#d64242'
                                                                            : '#44ba34',
                                                                    display:
                                                                        'inline-block',
                                                                    padding:
                                                                        '0px 5px',
                                                                    color: 'white',
                                                                    borderRadius:
                                                                        '30px',
                                                                }}
                                                            >
                                                                {item.status}
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    '10px',
                                                                overflow:
                                                                    'hidden',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                textTransform:
                                                                    'uppercase',
                                                                color:
                                                                    item.type !==
                                                                    'Weather'
                                                                        ? 'black'
                                                                        : '#205f8a',
                                                            }}
                                                        >
                                                            {item.type}
                                                        </div>

                                                        <div
                                                            style={{
                                                                color: 'grey',
                                                                fontSize: '9px',
                                                                overflow:
                                                                    'hidden',
                                                                whiteSpace:
                                                                    'nowrap',
                                                                textOverflow:
                                                                    'ellipsis',
                                                            }}
                                                        >
                                                            {item.device_id && (
                                                                <>
                                                                    {' '}
                                                                    ID :{' '}
                                                                    {
                                                                        item.device_id
                                                                    }{' '}
                                                                </>
                                                            )}
                                                        </div>
                                                    </Box>
                                                </Tooltip>
                                                <Checkbox
                                                    sx={{ marginLeft: 'auto' }}
                                                    size='small'
                                                    color='success'
                                                    disabled={!runParams}
                                                    checked={item.isInDashboard}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            index,
                                                        )
                                                    }
                                                />
                                            </Box>
                                        ))
                                    ) : (
                                        <span
                                            style={{
                                                fontSize: '14px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <VisibilityOffIcon />
                                            NOT FOUND
                                        </span>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        width: '85%',
                                        backgroundImage: `url(${
                                            imageUrl
                                                ? imageUrl
                                                : `/images/dfbg.png`
                                        })`,
                                        // background: !imageUrl && 'grey',
                                        backgroundSize: 'cover',
                                        height: '850px',
                                    }}
                                >
                                    <ResponsiveGridLayout
                                        className='layout'
                                        cols={{
                                            lg: 12,
                                            md: 12,
                                            sm: 12,
                                            xs: 12,
                                            xxs: 12,
                                        }}
                                        // rowHeight={110}
                                        // rowHeight={110 / 3}
                                        rowHeight={36}
                                        isResizable={false}
                                        // isResizable={true}
                                        isDraggable={runParams}
                                        onLayoutChange={handleLayoutChange}
                                        preventCollision={true}
                                        compactType={null}
                                        // maxRows={7}
                                        // maxRows={7 * 3}
                                        maxRows={18}
                                    >
                                        {cardItem.map((item, index) =>
                                            item.isInDashboard &&
                                            item.type !== 'Weather' ? (
                                                <Box
                                                    data-grid={
                                                        positions[item._id]
                                                    }
                                                    // data-grid={sizeCard}
                                                    key={item._id}
                                                    className='grid-item'
                                                    sx={{
                                                        boxShadow:
                                                            'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                        cursor: 'pointer',
                                                        borderRadius: '5px',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <CardDnd
                                                        images={images}
                                                        cardMode={cardMode}
                                                        data={item}
                                                        warn={!!item.alarm}
                                                        type={item.alarm_type}
                                                        // warn={true}
                                                    />
                                                </Box>
                                            ) : (
                                                item.isInDashboard && (
                                                    <Box
                                                        data-grid={fixBugWeather(
                                                            item._id,
                                                        )}
                                                        key={item._id}
                                                        className='grid-item'
                                                        sx={{
                                                            boxShadow:
                                                                'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                                            cursor: 'pointer',
                                                            borderRadius: '5px',
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <WeatherCard
                                                            keyName={keyName}
                                                        />
                                                    </Box>
                                                )
                                            ),
                                        )}
                                    </ResponsiveGridLayout>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        overflowY: 'auto',
                                        width: '15%',
                                        height: '850px',
                                        padding: '10px',
                                        background: '#fafafa',
                                    }}
                                >
                                    <Skeleton
                                        animation='wave'
                                        height={75}
                                        sx={{
                                            marginTop: '-15px',
                                            borderRadius: '5px',
                                        }}
                                    />
                                    <Skeleton
                                        animation='wave'
                                        height={75}
                                        sx={{
                                            marginTop: '-20px',
                                            borderRadius: '5px',
                                        }}
                                    />
                                    <Skeleton
                                        animation='wave'
                                        height={75}
                                        sx={{
                                            marginTop: '-20px',
                                            borderRadius: '5px',
                                        }}
                                    />
                                    <Skeleton
                                        animation='wave'
                                        height={75}
                                        sx={{
                                            marginTop: '-20px',
                                            borderRadius: '5px',
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        width: '85%',
                                        display: 'flex',
                                        justifyContent: 'center',

                                        height: '850px',
                                    }}
                                >
                                    <Box sx={{ marginTop: '15%' }}>
                                        <Loadding />
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <CustomModal
                open={!!open}
                setOpen={setOpen}
                size={'xs'}
                loading={loading}
            >
                <Typography>Upload Image</Typography>
                <UploadImage
                    setImage={setImageUpload}
                    setOpen={setOpen}
                    onSave={handleSave}
                    canSave={true}
                />
            </CustomModal>
            <AlertPopup
                open={openAlert}
                setOpen={setOpenAlert}
                message={message || ''}
            />
        </DndProvider>
    )
}

export default OverviewDND
