import { useContext, useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import TextFieldInput from '../../../common/input/TextFieldInput'
import IOSSwitchInputNormal from '../../../common/input/IosSwitchInputNormal'
import DropdownWithTitle from '../../../common/input/DropdownWithTitle'
import { urlConfig } from '../../../../config/apiConfig'
import axiosInstance from '../../../../config/axiosConfig'
import { useParams } from 'react-router-dom'
import { AlertContext } from '../../../../context/AlertContext'

export default function DeviceIotForm({ state, setState, isEdit }) {
    const [deviceType, setDeviceType] = useState(null)
    const [gateway, setGateway] = useState(null)
    const { organizationId } = useParams()
    const { handleOpen } = useContext(AlertContext)

    useEffect(() => {
        const handleGetAllDeviceType = async () => {
            try {
                const res = await axiosInstance.get(`${urlConfig.deviceType}`)
                if (res.data.status === 200) {
                    const options = res.data.message?.map((item) => ({
                        id: item.key_type_id,
                        value: item.name,
                    }))
                    setDeviceType(options)
                }
                handleOpen({ open: false })
            } catch (error) {
                console.log('Error:', error)
                handleOpen({
                    open: true,
                    message: `${error.response?.data.message}`,
                    isSuccess: false,
                })
            }
        }

        const handleGetAllGateway = async () => {
            try {
                const res = await axiosInstance.get(
                    `${urlConfig.gateway}?keyName=${organizationId}`,
                )
                if (res.data.status === 200) {
                    const options = res.data.message?.map((item) => ({
                        id: item._id,
                        value: item.name,
                    }))
                    setGateway(options)
                }
                handleOpen({ open: false })
            } catch (error) {
                console.log('Error:', error)
                handleOpen({
                    open: true,
                    message: `${error.response?.data.message}`,
                    isSuccess: false,
                })
            }
        }

        handleGetAllDeviceType()
        handleGetAllGateway()
    }, [])

    return (
        <Grid container gap={3}>
            <Typography variant='h5'>
                {isEdit ? 'Edit' : 'Add New '} Device IoT
            </Typography>
            <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={2}
            >
                <Typography variant='body1' fontWeight={'bold'}>
                    Enable
                </Typography>
                <IOSSwitchInputNormal
                    setState={setState}
                    name={'enable'}
                    status={state.enable}
                />
            </Grid>
            <Grid item xs={12}>
                <DropdownWithTitle
                    state={state}
                    setState={setState}
                    options={deviceType}
                    title='Device Type'
                    name='keyTypeId'
                    disabled={isEdit}
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput
                    state={state}
                    setState={setState}
                    name='desc'
                    label='Area'
                    size='small'
                />
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput
                    state={state}
                    setState={setState}
                    name='s_n'
                    label='S/N'
                    size='small'
                />
            </Grid>
            <Grid item xs={12}>
                <DropdownWithTitle
                    state={state}
                    setState={setState}
                    options={gateway}
                    title='Gateway'
                    name='gatewayId'
                />
            </Grid>
        </Grid>
    )
}
