import { Grid, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

export default function TextFieldInput({
    state,
    setState,
    placeholder,
    size,
    name,
    label,
    require,
    isEmail,
    isNumber,
    minValue,
    isMultiple,
    disabled
}) {
    const [errMessage, setErrMessage] = useState('')
    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
    }

    useEffect(() => {
        if (name === 'trigger_time_interval') {
            console.log(state[name])
        }
    }, [name])
    const handleChange = (e) => {
        const value = e.target.value

        if (isEmail && name === 'emailAddress' && !validateEmail(value)) {
            setErrMessage('Invalid email format.')
        } else if (isNumber && !/^\d*\.?\d*$/.test(value)) {
            setErrMessage('Only numbers and decimal points are allowed.')
        } else if (
            isNumber &&
            minValue !== undefined &&
            Number(value) < minValue
        ) {
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }))
            setErrMessage(
                `Enter a number greater than or equal to ${minValue}.`,
            )
        } else {
            setErrMessage('')
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }
    }

    return (
        <Grid container>
            {/* <Grid item xs={12}>
                <Typography variant='body1' fontWeight={'bold'}>
                    {label}{' '}
                    {require && <span style={{ color: 'error.main' }}>*</span>}
                </Typography>
            </Grid> */}
            <Grid
                item
                xs={12}
                display={minValue ? 'flex' : 'block'}
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{
                    xs: minValue ? 'left' : '',
                    sm: minValue ? 'center' : '',
                }}
                gap={minValue ? 0.5 : 0}
            >
                <TextField
                    value={state[name]}
                    required
                    label={label}
                    variant='outlined'
                    multiline={isMultiple}
                    minRows={isMultiple && 4}
                    type={isNumber ? 'number' : 'text'}
                    placeholder={placeholder}
                    size={size || 'medium'}
                    onChange={handleChange}
                    InputProps={{
                        inputProps: {
                            min: minValue,
                        },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            // background: 'white',
                            borderRadius: 1.5,
                        },
                        // '& input::placeholder': {
                        //     fontSize: '20px',
                        //     top: '17px',
                        //     position: 'absolute',
                        // },
                    }}
                    fullWidth
                    disabled={disabled}
                />
                {minValue && <Typography variant='body2'>Seconds</Typography>}
            </Grid>
            <Grid item>
                {errMessage !== '' && (
                    <Typography variant='body2' color='error'>
                        {errMessage}
                    </Typography>
                )}
            </Grid>
        </Grid>
    )
}
