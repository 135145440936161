const columnConfig = (name) => {
    let columns = []
    switch (name) {
        case 'Report':
            columns = [
                {
                    id: 'index',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    align: 'center',
                },
                {
                    id: 'device_type',
                    label: 'Device type',
                    width: '90%',
                    sortable: true,
                    align: 'left',
                },
                { id: 'actions', label: 'menu', width: '5%', align: 'center' },
            ]
            break

        case 'device':
            columns = [
                {
                    id: 'index',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    align: 'center',
                },
                {
                    id: 'name',
                    label: 'name',
                    width: '90%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'actions',
                    label: '',
                    width: '5%',
                    align: 'center',
                },
            ]
            break
        case 'Org':
            columns = [
                {
                    id: 'index',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'site_name',
                    label: 'Name ',
                    width: '10%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'keyName',
                    label: 'keyName ',
                    width: '10%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'address',
                    label: 'Location',
                    width: '20%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'latitude',
                    label: 'Latitude',
                    width: '10%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'longitude',
                    label: 'Longitude',
                    width: '10%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'status',
                    label: 'status',
                    type: 'status',
                    width: '10%',
                    sortable: true,
                    // align: 'center',
                },
                // { id: 'actions', label: 'menu', width: '5%', align: 'center' },
            ]
            break
        case 'User':
            columns = [
                {
                    id: 'index',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'fullName',
                    label: 'Fullname',
                    width: '20%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'username',
                    label: 'Username',
                    width: '15%',
                    sortable: true,
                    // align: 'center',
                },

                {
                    id: 'role_name',
                    label: 'Role',
                    width: '20%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'status',
                    label: 'Status',
                    type: 'status',
                    width: '15%',
                    sortable: true,
                    // align: 'center',
                },

                {
                    id: 'date',
                    label: 'Last Active',
                    width: '25%',
                    sortable: true,
                    // align: 'center',
                },
                {
                    id: 'actions',
                    label: '',
                    width: '5%',
                    align: 'center',
                },
            ]
            break
        case 'User Management':
            columns = [
                {
                    id: 'index',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'fullName',
                    label: 'Fullname',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'username',
                    label: 'Username',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'group',
                    label: 'Group',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'role_name',
                    label: 'Role',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'status',
                    label: 'Status',
                    type: 'status',
                    width: '10%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'date',
                    label: 'Last Active',
                    width: '20%',
                    sortable: true,
                    align: 'center',
                },
                {
                    id: 'actions',
                    label: '',
                    width: '5%',
                    align: 'center',
                },
            ]
            break

        case 'deviceIOT':
            columns = [
                {
                    id: 'id',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'device_id',
                    label: 'ID Name',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'device_type',
                    label: 'Device',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'enable',
                    label: 'Enable',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                    type: 'status',
                },

                {
                    id: 'status',
                    label: 'Status',
                    type: 'status',
                    width: '10%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'last_update',
                    label: 'Last update',
                    width: '20%',
                    sortable: true,
                    align: 'center',
                },
                {
                    id: 'actions',
                    label: '',
                    width: '5%',
                    align: 'center',
                },
            ]
            break

        case 'deviceIOTadmin':
            columns = [
                {
                    id: 'id',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'device_id',
                    label: 'ID Name',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'device_type',
                    label: 'Device',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'enable',
                    label: 'Enable',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                    type: 'enable',
                },

                {
                    id: 'status',
                    label: 'Status',
                    type: 'status',
                    width: '10%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'last_update',
                    label: 'Last update',
                    width: '20%',
                    sortable: true,
                    align: 'center',
                },
                {
                    id: 'actions',
                    label: '',
                    width: '5%',
                    align: 'center',
                },
            ]
            break

        case 'deviceList':
            columns = [
                {
                    id: 'id',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'device_id',
                    label: 'ID Name',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'device_type',
                    label: 'Device',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                // {
                //     id: 'enable',
                //     label: 'Enable',
                //     width: '15%',
                //     sortable: true,
                //     align: 'left',
                //     type: 'status',
                // },

                {
                    id: 'status',
                    label: 'Status',
                    type: 'status',
                    width: '10%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'last_update',
                    label: 'Last update',
                    width: '20%',
                    sortable: true,
                    align: 'center',
                },
                // {
                //     id: 'actions',
                //     label: '',
                //     width: '5%',
                //     align: 'center',
                // },
            ]
            break
        case 'mqtt':
            columns = [
                {
                    id: 'id',
                    label: 'No.',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'username',
                    label: 'ID Gateway',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'name',
                    label: 'Name',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'keyName',
                    label: 'Site ID (Keyname)',
                    width: '15%',
                    sortable: true,
                    align: 'left',
                },

                {
                    id: 'status',
                    label: 'Status',
                    type: 'status',
                    width: '10%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'ip_address',
                    label: 'IP Address',
                    width: '20%',
                    sortable: true,
                    align: 'center',
                },
                {
                    id: 'updatedAt',
                    label: 'Last update',
                    width: '5%',
                    align: 'center',
                    sortable: true,
                },
            ]
            break

        case 'AlarmReport':
            columns = [
                {
                    id: 'no',
                    label: 'No.',
                    width: '3%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'timeStampTo',
                    label: 'timestamp',
                    width: '3%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_parameter_1',
                    label: 'parameter',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_value_1',
                    label: 'Process Value',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_condition_1',
                    label: 'condition',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_set_point_1',
                    label: 'Set Point',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_connector',
                    label: 'Connector',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_parameter_2',
                    label: 'parameter',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_value_2',
                    label: 'Process Value',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_condition_2',
                    label: 'condition',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'remain_set_point_2',
                    label: 'Set Point',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'name_notification_setting',
                    label: 'message',
                    width: '10%',
                    sortable: true,
                    align: 'left',
                },
            ]
            break

        case 'AlarmReport2':
            columns = [
                {
                    id: 'no',
                    label: 'No.',
                    width: '3%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'timeStampTo',
                    label: 'timestamp',
                    width: '3%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'trigger_parameter',
                    label: 'parameter',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },
                {
                    id: 'trigger_value',
                    label: 'Process Value',
                    width: '5%',
                    sortable: true,
                    align: 'left',
                },

                {
                    id: 'name_notification_setting',
                    label: 'message',
                    width: '10%',
                    sortable: true,
                    align: 'left',
                },
            ]
            break

        default:
            break
    }
    return columns
}

export default columnConfig
