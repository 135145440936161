import React, { useEffect, useState } from 'react'
import ModalRequest from '../../components/ModalRequest'
import { Grid, Switch } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/StyleComponent'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
export default function EditGetway({
    open,
    setOpen,
    handleAlert,
    keyName,
    req,
    edit,
}) {
    const { handleSubmit, setValue, watch } = useForm()
    const [status, setStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const onSubmit = async (data) => {
        const body = {
            enable: status,
            keyName: keyName,
            name: data.name,
            description: data.description,
            // s_n: data.s_n,
        }
        setLoading(true)
        try {
            await axiosInstance.put(`${urlConfig.gateway}/${edit._id}`, body)
            handleAlert('gateway update successfully.', 'success')
            setOpen(false)
            req()
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    const handleChange = (name, value) => {
        setValue(name, value)
    }

    useEffect(() => {
        Object.keys(edit).forEach((key) => {
            setValue(key, edit[key])
        })
        setStatus(edit.enable)
    }, [edit, setValue])

    return (
        <ModalRequest
            disabled={loading}
            open={open}
            setOpen={setOpen}
            title={'Edit Gateway'}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid item xs={12}>
                <Input
                    label='Gateway name'
                    name='name'
                    required
                    value={watch().name || ''}
                    onChange={(e) => handleChange('name', e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    label='Descriptions '
                    required
                    name='description'
                    value={watch().description || ''}
                    onChange={(e) =>
                        handleChange('description', e.target.value)
                    }
                />
            </Grid>

            {/* <Grid item xs={12}>
                <Input
                    label='SN'
                    required
                    value={watch().s_n || ''}
                    name='s_n'
                    onChange={(e) => handleChange('s_n', e.target.value)}
                />
            </Grid> */}
            <Grid item xs={12} align='right'>
                <span style={{ fontWeight: 'bold' }}>Enable</span>
                <Switch
                    color='success'
                    onChange={(_, v) => setStatus(v)}
                    checked={status}
                />
            </Grid>
        </ModalRequest>
    )
}
