import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Container, Loadding } from '../../components/StyleComponent'
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded'
import {
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Skeleton,
    Checkbox,
    ListItemText,
    TextField,
} from '@mui/material'
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    Bar,
} from 'recharts'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import Tabs from './TabSelect'
import { AlertContext } from '../../context/AlertContext'
import TimelineIcon from '@mui/icons-material/Timeline'
import BarChartIcon from '@mui/icons-material/BarChart'
const NewGraph = () => {
    const [log, setLog] = useState('')
    const [device, setDevice] = useState('')
    const { deviceId } = useParams()
    const [activeTab, setActiveTab] = useState('DAY')
    const [selectedParameters, setSelectedParameters] = useState()
    const [detailParam, setDetailParam] = useState('')
    const [minY, setMinY] = useState(0)
    const [maxY, setMaxY] = useState(0)
    const { handleOpen } = useContext(AlertContext)
    const [chart, setChart] = useState('Line')
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth()
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth)

    const handleChange = (event) => {
        const {
            target: { value },
        } = event
        setSelectedParameters(
            typeof value === 'string' ? value.split(',') : value,
        )
    }

    const getData = useCallback(async () => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.device}/id/${deviceId}`,
            )
            setDevice(res.data.message)
            setSelectedParameters(
                res.data.message.deviceTypeParameter.map((item) => item.key),
            )
            // handleOpen({ open: false })
        } catch (error) {
            console.log('Error fetching image:', error)
            // handleOpen({
            //     open: true,
            //     message: `${error.response?.data.message}`,
            //     isSuccess: false,
            // })
        }
    }, [deviceId])

    function sortByTime(data) {
        return data.sort((a, b) => {
            return (
                new Date(`1970-01-01T${a.name}Z`) -
                new Date(`1970-01-01T${b.name}Z`)
            )
        })
    }

    const getlog = useCallback(
        async (mode) => {
            if (!mode) {
                setLog(null)
            }

            try {
                const res = await axiosInstance.get(
                    `${
                        urlConfig.realtime
                    }/${deviceId}?period=${activeTab.toLocaleLowerCase()}&name_parameter=${selectedParameters}`,
                )
                const logData = res.data.message
                // Sort logData by the time in the 'name' property
                const sortedLogData = sortByTime(logData)
                setLog(sortedLogData)

                // console.log('asdsd', sortedByTemp)

                if (
                    logData.length > 0 &&
                    !mode &&
                    logData[0] &&
                    selectedParameters &&
                    selectedParameters[0]
                ) {
                    const allValues = logData.flatMap((item) =>
                        selectedParameters?.map((param) => item[param]),
                    )
                    const fallValues = allValues.filter(
                        (item) => item !== 'NaN' && item !== undefined,
                    )

                    const minValue = Math.min(...fallValues)

                    const maxValue = Math.max(...fallValues)
                    const min = Math.floor(minValue - minValue * 0.2)
                    const max = Math.ceil(maxValue + maxValue * 0.2)

                    if (max !== -Infinity || max !== Infinity) {
                        setMaxY(max)
                    }
                    if (min !== -Infinity || min !== Infinity) {
                        setMinY(min)
                    }
                }
                handleOpen({ open: false })
            } catch (error) {
                console.log('Error fetching image:', error)
                handleOpen({
                    open: true,
                    message: `${error.response?.data.message}`,
                    isSuccess: false,
                })
            }
        },
        [selectedParameters, deviceId, activeTab],
    )

    useEffect(() => {
        if (activeTab === 'DAY' && selectedParameters) {
            const interval = setInterval(() => {
                getlog(true)
            }, 3000)

            return () => clearInterval(interval)
        }
    }, [getlog, activeTab])

    useEffect(() => {
        if (selectedParameters) {
            getlog()
        }
    }, [getlog, activeTab, selectedParameters])

    useEffect(() => {
        getData()
    }, [getData])

    const filteredData = device?.params
        ?.filter((item) => selectedParameters?.includes(item.key))
        ?.map((item) => {
            return item.name
        })

    const dataOfMoths =
        activeTab === 'MONTH' &&
        Array.from({ length: daysInCurrentMonth }, (_, i) => {
            const data = { name: (i + 1).toString() }
            filteredData?.forEach((key) => {
                data[key] = null
            })
            return data
        })

    const dateOfYears =
        activeTab === 'YEAR' &&
        Array.from({ length: 12 }, (_, i) => {
            const data = { name: (i + 1).toString() }
            filteredData?.forEach((key) => {
                data[key] = null
            })
            return data
        })

    const mergedData =
        activeTab === 'MONTH' &&
        log &&
        dataOfMoths.map((day) => {
            const existingData = log?.find((d) => {
                const date = new Date(d.name)
                return date.getDate() === parseInt(day.name, 10)
            })
            return existingData ? { ...existingData, name: day.name } : day
        })

    const mergedYear =
        activeTab === 'YEAR' &&
        log &&
        dateOfYears.map((day) => {
            const existingData = log?.find((d) => {
                const date = new Date(d.name)
                return date.getMonth() + 1 === parseInt(day.name, 10)
            })
            return existingData ? { ...existingData, name: day.name } : day
        })

    const formattedData =
        log &&
        log[0] &&
        log.map((item) => {
            // Extract the first 5 characters to get "hh:mm"
            const formattedName = item.name.slice(0, 5)

            // Return a new object with the formatted name
            return { ...item, name: formattedName }
        })

    console.log('device', detailParam)

    useEffect(() => {
        if (device) {
            const paramKeys = device.params?.map((param) => param.key)
            setSelectedParameters(paramKeys)
            setDetailParam(device.params)
        }
    }, [device])

    return (
        <Container
            header={header}
            navigate={{ title: deviceId, navigate: '/graph-trend' }}
        >
            <Grid container spacing={2} p={3}>
                <Grid item xs={12} md={8} sx={{ display: 'flex' }}>
                    <img
                        src={`${urlConfig.baseUrlAPI}/api/v1${device.image}`}
                        alt='gd'
                        width={70}
                        height={70}
                    />
                    <div style={{ marginTop: '5px', marginLeft: '10px' }}>
                        <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                            {device ? (
                                device?.device_type
                            ) : (
                                <Skeleton
                                    animation='wave'
                                    variant='rounded'
                                    sx={{
                                        borderRadius: '15px',
                                        width: '150px',
                                        height: '12px',
                                        marginRight: '5px',
                                    }}
                                />
                            )}
                        </div>
                        <div style={{ fontSize: '12px' }}>
                            {device ? (
                                `ID : ${device?.device_id}`
                            ) : (
                                <Skeleton
                                    animation='wave'
                                    variant='rounded'
                                    sx={{
                                        marginTop: '8px',
                                        borderRadius: '15px',
                                        width: '60px',
                                        height: '12px',
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            padding: '20px 20px',
                            border: '1px solid grey',
                            borderRadius: '10px',
                        }}
                    >
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} md={3} mb={1}>
                                <FormControl size='small' fullWidth>
                                    <InputLabel>Parameter</InputLabel>
                                    <Select
                                        sx={{ borderRadius: '30px' }}
                                        label='Parameter'
                                        multiple
                                        value={selectedParameters || ''}
                                        onChange={handleChange}
                                        renderValue={(selected) => {
                                            const filteredNames = device?.params
                                                ?.filter((item) =>
                                                    selected.includes(item.key),
                                                )
                                                ?.map((item) => item.name)
                                                .join(', ')
                                            return filteredNames || ''
                                        }}
                                    >
                                        {device &&
                                            device.params?.map((option) => (
                                                <MenuItem
                                                    key={option.key}
                                                    value={option.key}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            selectedParameters.indexOf(
                                                                option.key,
                                                            ) > -1
                                                        }
                                                    />

                                                    <ListItemText
                                                        primary={option.name}
                                                    />
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '15px',
                                        fontSize: '14px',
                                    }}
                                >
                                    <Tabs
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                    />
                                </div>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{ display: 'flex', gap: '10px' }}
                            >
                                {/* <div>
                                    <InputLabel>minY</InputLabel>
                                    <TextField
                                        type='number'
                                        value={minY || 0}
                                        onChange={(e) =>
                                            setMinY(Number(e.target.value))
                                        }
                                        size='small'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                padding: '0',
                                                height: '30px',
                                                width: '80px',
                                            },
                                        }}
                                    />
                                </div>
                                <div>
                                    <InputLabel>maxY</InputLabel>

                                    <TextField
                                        type='number'
                                        value={maxY || 0}
                                        onChange={(e) =>
                                            setMaxY(Number(e.target.value))
                                        }
                                        size='small'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                padding: '0',
                                                height: '30px',
                                                width: '80px',
                                            },
                                        }}
                                    />
                                </div> */}
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        whiteSpace: 'nowrap',
                                        padding: '10px',
                                    }}
                                >
                                    <button
                                        style={{
                                            cursor: 'pointer',
                                            border: '1px solid lightgrey',
                                            padding: '5px 10px',
                                            borderTopLeftRadius: '30px',
                                            borderBottomLeftRadius: '30px',
                                            transition: 'background-color 0.3s',
                                            width: '60px',
                                            background:
                                                chart === 'Line' && 'lightgrey',
                                        }}
                                        onClick={() => setChart('Line')}
                                    >
                                        <TimelineIcon
                                            sx={{
                                                color: '#A81E1A',
                                                fontSize: 24,
                                            }}
                                        />
                                    </button>
                                    <button
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent',
                                            border: '1px solid lightgrey',
                                            padding: '5px 10px',
                                            width: '60px',
                                            borderTopRightRadius: '30px',
                                            borderBottomRightRadius: '30px',
                                            transition: 'background-color 0.3s',
                                            background:
                                                chart === 'Bar' && 'lightgrey',
                                        }}
                                        onClick={() => setChart('Bar')}
                                    >
                                        <BarChartIcon
                                            sx={{
                                                color: '#A81E1A',
                                                fontSize: 24,
                                            }}
                                        />
                                    </button>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {log ? (
                                    <>
                                        {log[0] && detailParam ? (
                                            detailParam
                                                ?.filter(
                                                    (item) => item.show_graph,
                                                )
                                                .map((item, index) => (
                                                    <ResponsiveContainer
                                                        width='100%'
                                                        height={300}
                                                        style={{
                                                            marginTop:
                                                                index > 0 &&
                                                                '20px',
                                                        }}
                                                        key={index}
                                                    >
                                                        {chart === 'Line' ? (
                                                            <LineChart
                                                                data={
                                                                    activeTab ===
                                                                    'MONTH'
                                                                        ? mergedData
                                                                        : activeTab ===
                                                                          'DAY'
                                                                        ? formattedData
                                                                        : mergedYear
                                                                }
                                                            >
                                                                <CartesianGrid strokeDasharray='4 4' />
                                                                <XAxis
                                                                    dataKey='name'
                                                                    tick={{
                                                                        fontSize: 14,
                                                                    }}
                                                                />
                                                                <YAxis
                                                                    domain={[
                                                                        'auto',
                                                                        'auto',
                                                                    ]}
                                                                    label={{
                                                                        value: item.unit,
                                                                        angle: -90,
                                                                        position:
                                                                            'insideLeft',
                                                                        style: {
                                                                            fontSize: 14,
                                                                        }, // You can style the label here
                                                                    }}
                                                                />
                                                                <Tooltip
                                                                    contentStyle={{
                                                                        backgroundColor:
                                                                            '#fff', // Tooltip background color
                                                                        borderRadius:
                                                                            '10px', // Rounded corners
                                                                        fontSize:
                                                                            '14px', // Font size
                                                                    }}
                                                                    formatter={(
                                                                        value,
                                                                        name,
                                                                        props,
                                                                    ) => [
                                                                        `${value} ${item.unit}`,
                                                                        item.name,
                                                                    ]} // Format tooltip values
                                                                />
                                                                <Legend
                                                                    layout='horizontal' // Legend layout
                                                                    verticalAlign='top' // Position legend on top
                                                                    align='center' // Center the legend horizontally
                                                                    formatter={(
                                                                        value,
                                                                        entry,
                                                                    ) =>
                                                                        `${item.name}`
                                                                    } // Format legend labels if needed
                                                                />
                                                                <Line
                                                                    dot={''}
                                                                    key={index}
                                                                    type='monotone'
                                                                    dataKey={
                                                                        item.key
                                                                    }
                                                                    stroke={getColor(
                                                                        index,
                                                                    )}
                                                                    strokeWidth={
                                                                        2
                                                                    }
                                                                />
                                                            </LineChart>
                                                        ) : (
                                                            <BarChart
                                                                data={
                                                                    activeTab ===
                                                                    'MONTH'
                                                                        ? mergedData
                                                                        : activeTab ===
                                                                          'DAY'
                                                                        ? formattedData
                                                                        : mergedYear
                                                                }
                                                            >
                                                                <CartesianGrid strokeDasharray='4 4' />
                                                                <XAxis
                                                                    dataKey='name'
                                                                    tick={{
                                                                        fontSize: 14,
                                                                    }}
                                                                />
                                                                <YAxis
                                                                    domain={[
                                                                        'auto',
                                                                        'auto',
                                                                    ]}
                                                                    label={{
                                                                        value: item.unit, // Set your Y-axis label here
                                                                        angle: -90,
                                                                        position:
                                                                            'insideLeft',
                                                                        style: {
                                                                            fontSize: 14,
                                                                        }, // You can style the label here
                                                                    }}
                                                                />
                                                                <Tooltip
                                                                    contentStyle={{
                                                                        backgroundColor:
                                                                            '#fff', // Tooltip background color
                                                                        borderRadius:
                                                                            '10px', // Rounded corners
                                                                        fontSize:
                                                                            '14px', // Font size
                                                                    }}
                                                                    formatter={(
                                                                        value,
                                                                        name,
                                                                        props,
                                                                    ) => [
                                                                        `${value} ${item.unit}`,
                                                                        item.name,
                                                                    ]} // Format tooltip values
                                                                />
                                                                <Legend
                                                                    layout='horizontal' // Legend layout
                                                                    verticalAlign='top' // Position legend on top
                                                                    align='center' // Center the legend horizontally
                                                                    formatter={(
                                                                        value,
                                                                        entry,
                                                                    ) =>
                                                                        `${item.name}`
                                                                    } // Format legend labels if needed
                                                                />
                                                                <Bar
                                                                    key={index}
                                                                    dataKey={
                                                                        item.key
                                                                    }
                                                                    fill={getColor(
                                                                        index,
                                                                    )}
                                                                    strokeWidth={
                                                                        3
                                                                    }
                                                                />

                                                                {/* {selectedParameters.map(
                                                                (
                                                                    param,
                                                                    index,
                                                                ) => (
                                                                    <Bar
                                                                        key={
                                                                            index
                                                                        }
                                                                        dataKey={
                                                                            param
                                                                        }
                                                                        fill={getColor(
                                                                            index,
                                                                        )}
                                                                        strokeWidth={
                                                                            3
                                                                        }
                                                                    />
                                                                ),
                                                            )} */}
                                                            </BarChart>
                                                        )}
                                                    </ResponsiveContainer>
                                                ))
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    height: '400px',
                                                    padding: '100px 0',
                                                }}
                                            >
                                                Not Found
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <Box
                                        sx={{
                                            height: 400,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Loadding></Loadding>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

const header = {
    icon: <InsertChartRoundedIcon sx={{ color: 'white' }} />,
    color: 'grey',
    title: 'Graph Trend',
}

const getColor = (index) => {
    const colors = [
        '#1f77b4', // Blue
        '#ff7f0e', // Orange
        '#2ca02c', // Green
        '#d62728', // Red
        '#9467bd', // Purple
        '#8c564b', // Brown
        '#e377c2', // Pink
        '#7f7f7f', // Gray
        '#bcbd22', // Olive
        '#17becf', // Teal
    ]
    return colors[index % colors.length]
}

export default NewGraph
const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate()
}
