import { useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    TextField,
    Button,
    Box,
    TablePagination,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Select,
} from '@mui/material'
// import AddIcon from '@mui/icons-material/Add'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import * as XLSX from 'xlsx'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Loadding } from '../../components/StyleComponent'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

export default function DynamicTable({
    trigger,
    setOpenTrigger,
    loadd,
    data,
    columns,
    error,
    exportDoc,
    button,
    setAction,
    MenuList,
    clickRow,
    report,
    subButton,
    disableSearch,
    deviceList,
    foot,
    setPageChange,
    pageChange,
    limit,
    setLimit,
    setOpen,
    getReports,
    modeOK,
    loading,
    refresh,
}) {
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(limit)
    const [filteredData, setFilteredData] = useState(data)
    const [anchorEl, setAnchorEl] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        setFilteredData(data)
    }, [data])

    const sortedData =
        filteredData &&
        filteredData.sort((a, b) => {
            if (orderBy) {
                const isAsc = order === 'asc'
                if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1
                if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1
                return 0
            }
            return data
        })

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    // const handleSearch = () => {
    //     const lowercasedQuery = searchQuery.toLowerCase()
    //     const filtered = data.filter((item) =>
    //         columns.some((column) =>
    //             item[column.id]
    //                 ?.toString()
    //                 .toLowerCase()
    //                 .includes(lowercasedQuery),
    //         ),
    //     )
    //     setFilteredData(filtered)
    // }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(sortedData || ['s'])
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, `${new Date().toISOString()}.xlsx`)
    }

    const statusColor = (text) => {
        const colorConfig = {
            Active: 'green',
            Inactive: 'red',
            Expired: 'orange',
            Offline: 'red',
            Online: 'green',
            // Default color for statuses not explicitly listed
        }
        return colorConfig[text] || 'black' // Use 'defaultColor' or any fallback color of your choice
    }
    const limitOption = [10, 25, 50, 100]
    return (
        <>
            <Grid container padding={2} spacing={1}>
                <Grid item xs={12} sm={6} align='left'>
                    <Grid container spacing={1}>
                        {!report ? (
                            <>
                                {!disableSearch && (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={subButton ? 12 : 6}
                                        align='left'
                                    >
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={12}
                                                md={subButton ? 5 : 12}
                                            >
                                                <TextField
                                                    fullWidth
                                                    sx={{
                                                        '& .MuiOutlinedInput-root':
                                                            {
                                                                maxWidth:
                                                                    subButton
                                                                        ? 'auto'
                                                                        : '300px',
                                                                minWidth:
                                                                    subButton
                                                                        ? 'auto'
                                                                        : '120px',
                                                                borderRadius:
                                                                    '30px',
                                                                // height: '35px',
                                                                // background: '#F3F3F3',
                                                                boxShadow: `rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;`,
                                                            },
                                                    }}
                                                    size='small'
                                                    variant='outlined'
                                                    placeholder='Search'
                                                    value={searchQuery}
                                                    onChange={(e) => {
                                                        setSearchQuery(
                                                            e.target.value,
                                                        )
                                                        const value =
                                                            e.target.value
                                                        const lowercasedQuery =
                                                            value.toLowerCase()
                                                        const filtered =
                                                            data.filter(
                                                                (item) =>
                                                                    columns.some(
                                                                        (
                                                                            column,
                                                                        ) =>
                                                                            item[
                                                                                column
                                                                                    .id
                                                                            ]
                                                                                ?.toString()
                                                                                .toLowerCase()
                                                                                .includes(
                                                                                    lowercasedQuery,
                                                                                ),
                                                                    ),
                                                            )
                                                        setFilteredData(
                                                            filtered,
                                                        )
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <IconButton
                                                                size='small'
                                                                sx={{
                                                                    // width: '24px',
                                                                    // height: '24px',
                                                                    alignItems:
                                                                        'center',
                                                                    display:
                                                                        'flex',
                                                                    left: '-4px',
                                                                }}
                                                            >
                                                                <svg
                                                                    viewBox='0 0 24 24'
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='20px'
                                                                    fill='#757575'
                                                                >
                                                                    <path d='M10 4c-3.32 0-6 2.68-6 6 0 3.31 2.68 6 6 6 3.31 0 6-2.69 6-6 0-3.32-2.69-6-6-6Zm-8 6c0-4.42 3.58-8 8-8 4.41 0 8 3.58 8 8 0 4.41-3.59 8-8 8 -4.42 0-8-3.59-8-8Z' />
                                                                    <path d='M13.5 10c0-1.94-1.57-3.5-3.5-3.5v-2c3.03 0 5.5 2.46 5.5 5.5h-2Z' />
                                                                    <path d='M15.7 14.29l6 6 -1.42 1.41 -6-6 1.41-1.42Z' />
                                                                </svg>
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            {subButton && (
                                                <Grid item xs={12} md={5}>
                                                    {subButton}
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <>{report}</>
                        )}
                    </Grid>
                </Grid>
                {button && (
                    <Button
                        startIcon={
                            <AddCircleOutlineOutlinedIcon
                                sx={{ width: '20px', height: '20px' }}
                            />
                        }
                        variant='contained'
                        color='buttonSave'
                        sx={{
                            textTransform: 'uppercase',
                            borderRadius: '30px',
                            // textTransform: 'none',
                            height: '30px',
                            fontWeight: 'bold',
                            fontSize: '12px',
                        }}
                        onClick={button.action}
                        // disabled={!disabledButton || false}
                    >
                        {button.title}
                    </Button>
                )}

                {exportDoc && (
                    <Grid item xs={12} sm={6} align='right'>
                        <Button
                            disabled={loading || data.length === 0}
                            sx={{
                                borderRadius: '35px',
                                textTransform: 'none',
                                height: '30px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                my: 1,
                                width: '150px',
                            }}
                            variant='contained'
                            color='error'
                            onClick={() =>
                                modeOK === 0
                                    ? trigger
                                        ? setOpenTrigger('pdf')
                                        : getReports('pdf')
                                    : setOpen('pdf')
                            }
                        >
                            EXPORT PDF
                        </Button>
                        <Button
                            disabled={loading || data.length === 0}
                            sx={{
                                borderRadius: '35px',
                                textTransform: 'none',
                                height: '30px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                marginLeft: 1,
                                width: '150px',
                            }}
                            variant='contained'
                            color='success'
                            onClick={() =>
                                modeOK === 0
                                    ? trigger
                                        ? setOpenTrigger('excel')
                                        : getReports('excel')
                                    : setOpen('excel')
                            }
                        >
                            EXPORT EXCEL
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12} align='right'>
                    {refresh && (
                        <Button
                            startIcon={
                                <RefreshIcon
                                    sx={{ width: '20px', height: '20px' }}
                                />
                            }
                            variant='contained'
                            color='inherit'
                            sx={{
                                textTransform: 'uppercase',
                                borderRadius: '30px',
                                // textTransform: 'none',
                                height: '30px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                            }}
                            onClick={refresh}
                            disabled={loading || data.length === 0}
                        >
                            refresh
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Box padding={'0 20px'}>
                <Paper
                    sx={{
                        // borderRadius: '8px',
                        overflow: 'auto',
                        // boxShadow: `rgba(0, 0, 0, 0.15) 0px 1.95px 5px 2.0px 2px;`,
                    }}
                >
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ bgcolor: '#f5f5f5' }}>
                                <TableRow>
                                    {columns.map((item, index) => (
                                        <TableCell
                                            key={index + 500}
                                            sx={{
                                                width: item.width,
                                                fontWeight: 'bold',
                                                color: '#242424',
                                                padding: '10px 20px',
                                                textTransform: 'uppercase',
                                            }}
                                            align={item.align}
                                            sortDirection={
                                                orderBy === item.id
                                                    ? order
                                                    : false
                                            }
                                        >
                                            {item.sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === item.id}
                                                    direction={
                                                        orderBy === item.id
                                                            ? order
                                                            : 'asc'
                                                    }
                                                    onClick={() =>
                                                        handleRequestSort(
                                                            item.id,
                                                        )
                                                    }
                                                >
                                                    <Box
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: '#242424',
                                                            fontSize: '12px',
                                                            textTransform:
                                                                'uppercase',
                                                            marginLeft: '0px',
                                                        }}
                                                    >
                                                        {item.label}
                                                    </Box>
                                                </TableSortLabel>
                                            ) : (
                                                item.label
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData && data[0] ? (
                                    sortedData
                                        .slice(
                                            page * limit,
                                            page * limit + limit,
                                        )
                                        .map((row, rowIndex) => (
                                            <TableRow
                                                onClick={() =>
                                                    clickRow
                                                        ? navigate(
                                                              `/organizations/${row.keyName}`,
                                                          )
                                                        : deviceList
                                                        ? navigate(
                                                              `/graph-trend/${row.device_id}`,
                                                          )
                                                        : null
                                                }
                                                key={rowIndex + 1000}
                                                sx={{
                                                    // background:
                                                    //     rowIndex % 2 === 0
                                                    //         ? '#f5f5f5'
                                                    //         : 'defaultColor',
                                                    transition:
                                                        '0.05s ease-out',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        background: '#FFCCCE',
                                                    },
                                                }}
                                            >
                                                {columns.map(
                                                    (col, colIndex) => (
                                                        <TableCell
                                                            sx={{
                                                                padding:
                                                                    '8px 30px',
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                            key={`${rowIndex}-${col.id}+${colIndex}`}
                                                            align={col.align}
                                                        >
                                                            {col.type ===
                                                                'payload' && (
                                                                <span>
                                                                    {
                                                                        row
                                                                            .payload[
                                                                            col
                                                                                .id
                                                                        ]
                                                                    }
                                                                </span>
                                                            )}
                                                            {!col.type ===
                                                                'payload' &&
                                                            col.type ===
                                                                'status' ? (
                                                                typeof row[
                                                                    col.id
                                                                ] ===
                                                                'string' ? (
                                                                    <span
                                                                        style={{
                                                                            color: statusColor(
                                                                                row[
                                                                                    col
                                                                                        .id
                                                                                ],
                                                                            ),
                                                                        }}
                                                                    >
                                                                        {
                                                                            row[
                                                                                col
                                                                                    .id
                                                                            ]
                                                                        }
                                                                    </span>
                                                                ) : <span
                                                                      style={{
                                                                          color: statusColor(
                                                                              row[
                                                                                  col
                                                                                      .id
                                                                              ],
                                                                          ),
                                                                      }}
                                                                  >
                                                                      {
                                                                          row[
                                                                              col
                                                                                  .id
                                                                          ]
                                                                      }
                                                                  </span> ? (
                                                                    <CheckCircleOutlineOutlinedIcon
                                                                        color='success'
                                                                        fontSize='small'
                                                                    />
                                                                ) : (
                                                                    <CancelOutlinedIcon
                                                                        color='error'
                                                                        fontSize='small'
                                                                    />
                                                                )
                                                            ) : col.id ===
                                                              'actions' ? (
                                                                <IconButton
                                                                    size='small'
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        setAction(
                                                                            row,
                                                                        )
                                                                        setAnchorEl(
                                                                            e.currentTarget,
                                                                        )
                                                                    }}
                                                                >
                                                                    <MoreVertOutlinedIcon fontSize='small' />
                                                                </IconButton>
                                                            ) : (
                                                                row[col.id]
                                                            )}
                                                        </TableCell>
                                                    ),
                                                )}
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns && columns.length}
                                        >
                                            {error ? (
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        color: 'grey',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    something went wrong please
                                                    try again
                                                </Box>
                                            ) : (
                                                <>
                                                    {data && !data[0] ? (
                                                        <Box
                                                            sx={{
                                                                textAlign:
                                                                    'center',
                                                                color: 'grey',
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            Not Found
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            {!data[0] &&
                                                            !loadd ? (
                                                                <Box
                                                                    sx={{
                                                                        textAlign:
                                                                            'center',
                                                                        color: 'grey',
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    Search
                                                                </Box>
                                                            ) : (
                                                                <Loadding />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {data && data[0] && (
                        <Box
                            sx={{
                                background: 'white',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: '15px',
                                gap: '8px',
                                alignContent: 'center',
                            }}
                        >
                            <select
                                value={limit}
                                onChange={(e) => {
                                    setLimit(e.target.value)
                                    setPageChange(1)
                                }}
                                style={{
                                    background: 'white',
                                    border: '1px solid lightgrey',
                                    borderRadius: '5px',
                                    transition: 'border 0.3s, font-weight 0.3s',
                                }}
                            >
                                {limitOption.map((item, index) => (
                                    <option value={item} key={index}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                            <Box
                                style={{ fontSize: '14px' }}
                                display={'flex'}
                                alignItems={'center'}
                            >
                                {foot &&
                                    `${
                                        (foot.page - 1) * foot.limit + 1
                                    } - ${Math.min(
                                        foot.page * foot.limit,
                                        foot.total,
                                    )} of ${foot.total}`}
                            </Box>
                            <button
                                disabled={foot && foot.page === 1}
                                onClick={() => setPageChange(pageChange - 1)}
                                style={{
                                    background: 'white',
                                    border: '1px solid lightgrey',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    cursor:
                                        foot && foot.page === 1
                                            ? ''
                                            : 'pointer',
                                    transition: 'border 0.3s, font-weight 0.3s',
                                }}
                            >
                                <ArrowBackIosNewRoundedIcon
                                    fontSize='10px'
                                    sx={{
                                        color:
                                            foot && foot.page === 1
                                                ? 'lightgrey'
                                                : 'black',
                                    }}
                                ></ArrowBackIosNewRoundedIcon>
                            </button>
                            <button
                                onClick={() => setPageChange(pageChange + 1)}
                                disabled={
                                    foot &&
                                    foot.page ===
                                        Math.ceil(foot.total / foot.limit)
                                }
                                style={{
                                    background: 'white',
                                    border: '1px solid lightgrey',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    transition: 'border 0.3s, font-weight 0.3s',
                                }}
                            >
                                <ArrowForwardIosRoundedIcon
                                    fontSize='10px'
                                    sx={{
                                        color:
                                            foot &&
                                            foot.page ===
                                                Math.ceil(
                                                    foot.total / foot.limit,
                                                )
                                                ? 'lightgrey'
                                                : 'black',
                                    }}
                                ></ArrowForwardIosRoundedIcon>
                            </button>
                        </Box>
                    )}
                    {/* <TablePagination
                        sx={{
                            // backgroundColor: '#E8E8E8',
                            // padding: '5px 30px',
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            '.MuiSelect-select': {
                                padding: '4px 8px',
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                '&:focus': {
                                    borderRadius: '5px',
                                    backgroundColor: '#fff',
                                },
                            },
                            '.MuiTablePagination-actions .MuiButtonBase-root': {
                                padding: '0px',
                                margin: '0 4px',
                                backgroundColor: '#fff',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                },
                            },
                        }}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component='div'
                        count={sortedData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(v, newPage) => setPage(newPage)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage=''
                    /> */}
                </Paper>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {MenuList &&
                        MenuList.map((item, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    setAnchorEl(null)
                                    item.action()
                                }}
                                index={index}
                                sx={{
                                    fontSize: '14px',
                                    transition: '0.2s ease-out',
                                }}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                {item.title}
                            </MenuItem>
                        ))}
                </Menu>
            </Box>
        </>
    )
}
