import React, { useCallback, useEffect, useState } from 'react'
import { Container } from '../../components/StyleComponent'
import { Grid } from '@mui/material'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import columnConfig from '../../config/columnsConfig'
import TableComponents from '../../components/TableComponents'
import AddOrg from './AddOrg'

export default function OrganizationList({ handleAlert, create, update }) {
    const [data, setData] = useState('')
    const [open, setOpen] = useState('')
    const [error, setEror] = useState('')
    const columns = columnConfig('Org')
    // const [message, setMessage] = useState('')
    // const [openAlert, setOpenAlert] = useState(false)

    const getOrg = useCallback(async () => {
        try {
            setEror(false)
            const res = await axiosInstance(urlConfig.organization)

            setData(
                res.data.message?.map((item, index) => ({
                    ...item,
                    index: index + 1,
                })),
            )
        } catch (err) {
            console.log(err)
            setEror(true)
        }
    }, [])

    useEffect(() => {
        getOrg()
    }, [getOrg])

    return (
        <Container header={header}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TableComponents
                        clickRow={true}
                        // MenuList={ListMenu}
                        // setAction={setRow}
                        disabledButton={create}
                        data={data}
                        columns={columns}
                        error={error}
                        button={{
                            title: 'ADD Organization',
                            action: () => setOpen(true),
                        }}
                    />
                </Grid>
            </Grid>
            {open && (
                <AddOrg
                    open={open}
                    setOpen={setOpen}
                    req={getOrg}
                    handleAlert={handleAlert}
                />
            )}
        </Container>
    )
}
const header = {
    icon: (
        <svg
            viewBox='0 0 24 24'
            width={20}
            height={20}
            fill='white'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M11.62 9.21c.23.18.37.47.37.78v12c0 .55-.45 1-1 1h-9c-.56 0-1-.45-1-1v-10c0-.47.32-.88.78-.98l9-2c.29-.07.6 0 .84.19Zm-8.63 3.58v8.19h7v-9.76l-7 1.55Z' />
            <path d='M22.64 1.23c.22.18.35.47.35.76v20c0 .55-.45 1-1 1h-11c-.56 0-1-.45-1-1v-18c0-.49.34-.9.82-.99l11-2c.29-.06.59.02.81.21Zm-10.65 3.6v16.16h9V3.18l-9 1.63Z' />
            <path d='M13.5 7.5V6h2v1.5h-2Z' />
            <path d='M13.5 12v-1.5h2V12h-2Z' />
            <path d='M13.5 16.5V15h2v1.5h-2Z' />
            <path d='M4 15.5V14h2v1.5H4Z' />
            <path d='M4 19.5V18h2v1.5H4Z' />
            <path d='M17.5 7.5V6h2v1.5h-2Z' />
            <path d='M17.5 12v-1.5h2V12h-2Z' />
            <path d='M17.5 16.5V15h2v1.5h-2Z' />
            <path d='M7 15.5V14h2v1.5H7Z' />
            <path d='M7 19.5V18h2v1.5H7Z' />
        </svg>
    ),
    color: 'grey',
    title: 'ORGANIZATIONS',
}
