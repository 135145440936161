import { IconButton, Dialog, DialogContent, Stack } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import LoadingBackdrop from '../LoadingBackdrop'

export default function CustomModal(props) {
    const handleClose = () => {
        props.setOpen(false)
        props.handleClose?.()
    }

    return (
        <Dialog
            open={!!props.open || false}
            onClose={() => {
                handleClose()
            }}
            maxWidth={props.size || 'md'}
            fullWidth
            scroll='body'
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: 3,
                },
            }}
        >
            <LoadingBackdrop loading={props.loading} />
            <Stack justifyContent={'center'}>
                <IconButton
                    onClick={() => handleClose()}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                    }}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </Stack>
            <DialogContent>{props.children}</DialogContent>
        </Dialog>
    )
}
