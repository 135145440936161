import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export default function DropdownWithTitle({
    state,
    setState,
    options,
    name,
    title,
    unit,
    disabled
}) {
    const handleChange = (event) => {
        setState((prev) => ({ ...prev, [name]: event.target.value }))
    }

    return (
        <FormControl fullWidth size='small'>
            <InputLabel id='demo-simple-select-label'>{title}</InputLabel>
            <Select
                labelId='demo-simple-select-label'
                value={state[name]}
                onChange={handleChange}
                sx={{ borderRadius: 1.5 }}
                label={title}
                disabled={disabled}
            >
                {options &&
                    options.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.value}
                            {unit ? `${' '}${unit}` : ''}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}
