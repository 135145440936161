import React from 'react'
import { Modal, Box, Button } from '@mui/material'

export default function PopupAlert({
    open,
    handleClose,
    updateWarning,
    message,
}) {
    return (
        <Modal open={open}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        background: '#A81E1A',
                        padding: '8px 15px',
                        color: 'white',
                    }}
                >
                    Warning!
                </div>
                <div style={{ display: 'flex' }}>
                    <img
                        src='./images/warning.png'
                        alt='Warning'
                        style={{ padding: '20px 20px 0 20px' }}
                    />
                    <div
                        style={{
                            padding: '20px',
                            wordWrap: 'break-word',
                            flex: 1,
                            maxWidth: '260px',
                        }}
                    >
                        {message}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '0px 10px 10px 0',
                        gap: '15px',
                    }}
                >
                    <Button
                        color='inherit'
                        variant='contained'
                        onClick={() => updateWarning()}
                        sx={{
                            borderRadius: '10px',
                            width: '100px',
                            height: '30px',
                        }}
                    >
                        Close
                    </Button>
                    {/* <Button
                        color='inherit'
                        variant='contained'
                        onClick={() => handleClose()}
                        sx={{
                            borderRadius: '10px',
                            width: '100px',
                            height: '30px',
                            marginRight: '10px',
                        }}
                    >
                        No
                    </Button> */}
                </div>

                {/* <Button onClick={handleClose} color='primary'>
                    Close
                </Button> */}
            </Box>
        </Modal>
    )
}
