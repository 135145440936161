import NotificationSetting from './NotificationSetting'
import AlarmRealTime from './AlarmRealTime'
import DetailHeader from './DetailHeader'
import { Grid } from '@mui/material'
import ParamsTable from './ParamsTable'
import { useAuth } from '../../../../../hook/useAuth'

export default function DetailContainer({
    deviceId,
    data,
    user,
    create,
    update,
    remove,
    handleAlert,
    typeAlarm,
    connectedWB,
    typeDevice,
}) {
    const { auth } = useAuth()

    return (
        <Grid
            container
            display={'flex'}
            justifyContent={'space-between'}
            flexDirection={'row-reverse'}
        >
            <Grid item xs={12} sm={7.5} lg={8.8} order={2}>
                <Grid item>
                    <DetailHeader
                        data={data}
                        typeDevice={typeDevice}
                        handleAlert={handleAlert}
                        user={user}
                        connectedWB={connectedWB}
                    />
                </Grid>
                <Grid item my={3}>
                    <AlarmRealTime
                        deviceId={data?.device_id}
                        deviceData={data}
                        typeAlarm={typeAlarm}
                        connectedWB={connectedWB}
                    />
                </Grid>
                <Grid item>
                    {auth.group === 'Admin' && (
                        <NotificationSetting
                            deviceId={deviceId}
                            dataItem={data}
                            user={user}
                            create={create}
                            update={update}
                            remove={remove}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4} lg={3} order={1} mb={{ xs: 4, sm: 0 }}>
                <ParamsTable
                    params={data.deviceTypeParameter}
                    data={data}
                    typeDevice={typeDevice}
                    connectedWB={connectedWB}
                />
            </Grid>
        </Grid>
    )
}
