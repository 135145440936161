import { Grid } from '@mui/material'
import LoadingBackdrop from '../../../common/LoadingBackdrop'
import { useEffect, useState } from 'react'
import DeviceIotForm from './DeviceIotForm'
import CustomModal from '../../../common/popup/CustomModal'
import ActionButtons from '../../../common/popup/ActionButtons'
import { urlConfig } from '../../../../config/apiConfig'
import axiosInstance from '../../../../config/axiosConfig'
import { useParams } from 'react-router-dom'
import { AlertPopup } from '../../../StyleComponent'

const initialInput = {
    enable: true,
    keyTypeId: '',
    gatewayId: '',
    desc: '',
    s_n: '',
}

export default function DeviceIoTHeader({
    onFetchData,
    searchText,
    setSearchText,
    deviceTypeSelect,
    setDeviceTypeSelect,
    onSearch,
    input,
    setInput,
    openAdd,
    setOpenAdd,
}) {
    // const [openAdd, setOpenAdd] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deviceType, setDeviceType] = useState(null)
    const { organizationId } = useParams()

    const isAllInputFilled = () => {
        const inputKeys = Object.keys(input)
        for (const key of inputKeys) {
            if (typeof input[key] !== 'boolean' && !input[key]) {
                return false
            }
        }
        return true
    }

    const handleSave = async () => {
        const payload = {
            keyName: organizationId,
            description: input.desc,
            enable: input.enable,
            key_type_id: input.keyTypeId,
            gateway_id: input.gatewayId,
            s_n: input.s_n,
        }

        setLoading(true)
        try {
            const resp = await axiosInstance.post(
                `${urlConfig.deviceIot}`,
                payload,
            )
            if (resp.data.status === 201) {
                setIsSuccess(true)
                onFetchData()
                setOpenAdd(false)
                setAnchorEl(true)
                setInput(initialInput)
            }
        } catch (error) {
            console.log('Error:', error)
            setIsSuccess(false)
            setAnchorEl(true)
        } finally {
            setLoading(false)
        }
    }

    const handleCancel = () => {
        setOpenAdd(false)
        setInput(initialInput)
    }

    useEffect(() => {
        const handleGetAllDeviceType = async () => {
            try {
                const res = await axiosInstance.get(`${urlConfig.deviceType}`)
                if (res.status === 200) {
                    const options = res.data.message?.map((item) => ({
                        id: item.key_type_id,
                        value: item.name,
                    }))
                    setDeviceType([{ id: 'ALL', value: 'ALL' }, ...options])
                }
            } catch (error) {
                console.log('Error:', error)
            }
        }

        handleGetAllDeviceType()
    }, [])

    return (
        <Grid container justifyContent={'space-between'} mt={0}>
            <LoadingBackdrop loading={loading} />
            <CustomModal
                open={openAdd}
                setOpen={setOpenAdd}
                size='xs'
                loading={loading}
            >
                <DeviceIotForm state={input} setState={setInput} />
                <ActionButtons
                    canSave={isAllInputFilled()}
                    onSave={handleSave}
                    onCancel={handleCancel}
                />
            </CustomModal>
            <AlertPopup
                open={anchorEl}
                setOpen={setAnchorEl}
                message={{
                    status: isSuccess ? 'success' : 'error',
                    name: 'Add new device iot',
                }}
            />
        </Grid>
    )
}
