import { Button, Grid } from '@mui/material'

export default function ActionButtons({ canSave, onSave, onCancel }) {
    return (
        <Grid container justifyContent='flex-end' mt={!canSave ? 3 : 2} gap={2}>
            <Button variant='contained' color='buttonCancel' onClick={onCancel}>
                Cancel
            </Button>
            <Button
                variant='contained'
                color='secondary'
                disabled={!canSave}
                onClick={onSave}
                sx={{ color: 'primary.lightest' }}
            >
                Save
            </Button>
        </Grid>
    )
}
