import ModalRequest from '../../components/ModalRequest'
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { Input } from '../../components/StyleComponent'
import { useCallback, useContext, useEffect, useState } from 'react'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import { AlertContext } from '../../context/AlertContext'
import { DatePicker } from '@mui/x-date-pickers'
export default function AddUser({
    open,
    setOpen,
    keyName,
    handleAlert,
    getUserByKey,
}) {
    const [role, setRole] = useState('')
    const [loading, setLoading] = useState(false)
    const { handleSubmit, setValue, watch } = useForm()
    const onSubmit = async (data) => {
        if (data.password !== data.confirmPassword) {
            alert('Passwords do not match!')
            return
        }
        let body = {
            ...data,
            keyName: keyName,
            expire_date: convertDate(data.expire_date),
            // verified_account: !!data.verified_account,
            status: !!data.status ? 'Active' : 'Inactive',
        }
        delete body.confirmPassword
        console.log(body)
        console.log(urlConfig.user)
        try {
            setLoading(true)
            await axiosInstance.post(urlConfig.user, body)
            handleAlert('User added successfully.', 'success')
            getUserByKey()
            setOpen(false)
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    const convertDate = (dateString) => {
        const date = new Date(dateString)

        // Extract year, month (note: getMonth returns 0-indexed months), and day
        const year = date.getUTCFullYear()
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0') // Add leading zero if needed
        const day = date.getUTCDate().toString().padStart(2, '0') // Add leading zero if needed

        return `${year}-${month}-${day}`
    }

    const handleChange = (name, value) => {
        setValue(name, value)
    }

    const password = watch('password')
    const confirmPassword = watch('confirmPassword')

    const { handleOpen } = useContext(AlertContext)
    const getRole = useCallback(async () => {
        try {
            const res = await axiosInstance.get(`${urlConfig.role}`)
            setRole(res.data.message)
            handleOpen({ open: false })
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [])

    useEffect(() => {
        getRole()
    }, [getRole])

    return (
        <ModalRequest
            open={open}
            disabled={loading}
            setOpen={setOpen}
            title={`Add New User [${keyName}]`}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing={2} p={2}>
                <Grid item xs={12}>
                    <Input
                        label='Username'
                        required={true}
                        name='Username'
                        onChange={(e) =>
                            handleChange('username', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        label='Password'
                        type={'password'}
                        required={true}
                        name='Password'
                        onChange={(e) =>
                            handleChange('password', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        label='Confirm Password'
                        type={'password'}
                        required={true}
                        name='Confirm Password'
                        onChange={(e) =>
                            handleChange('confirmPassword', e.target.value)
                        }
                    />
                    {password &&
                        confirmPassword &&
                        password !== confirmPassword && (
                            <p style={{ color: 'red', fontSize: '14px' }}>
                                Passwords do not match!
                            </p>
                        )}
                </Grid>

                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        size='small'
                        sx={{
                            height: '35px',
                            // borderRadius: '10px',
                        }}
                    >
                        <InputLabel id='demo-simple-select-label'>
                            Group
                        </InputLabel>
                        <Select
                            label='Group'
                            id='Role'
                            required
                            onChange={(e) =>
                                handleChange('group', e.target.value)
                            }
                            defaultValue={''}
                            // value={watch('group') || ''}
                        >
                            <MenuItem value={'User'}>User</MenuItem>
                            <MenuItem value={'Admin'}>Admin</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {watch('group') && (
                    <Grid item xs={12} md={6}>
                        <FormControl
                            fullWidth
                            size='small'
                            //
                        >
                            <InputLabel>Role *</InputLabel>
                            <Select
                                sx={{ borderRadius: '5px' }}
                                label='Role'
                                required
                                onChange={(e) =>
                                    handleChange('role_id', e.target.value)
                                }
                                defaultValue={''}
                                fullWidth

                                // sx={{
                                //     height: '35px',
                                //     borderRadius: '10px',
                                //     border: '1px solid black',
                                // }}
                            >
                                <MenuItem value={''}>-</MenuItem>
                                {role &&
                                    role
                                        .filter(
                                            (item) =>
                                                item.group === watch('group'),
                                        )
                                        .map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.role_id}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    {/* <Input
                        label='Expire'
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        // required={true}
                        name='Expire'
                        onChange={(e) =>
                            handleChange('expire_date', e.target.value)
                        }
                    /> */}
                    <DatePicker
                        name='expire_date'
                        label='Expire Date'
                        InputLabelProps={{ shrink: true }}
                        slotProps={{ textField: { size: 'small' } }}
                        format='DD/MM/YYYY'
                        value={watch('expire_date')}
                        onChange={(date) =>
                            setValue('expire_date', date ? date : '')
                        }
                        sx={{ width: '100%' }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Input
                        required={true}
                        label='First name'
                        name='FirstName'
                        onChange={(e) =>
                            handleChange('first_name', e.target.value)
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Input
                        label='Last name'
                        required={true}
                        name='LastName'
                        onChange={(e) =>
                            handleChange('last_name', e.target.value)
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Input
                        label='email'
                        type='email'
                        required={true}
                        name='Email'
                        onChange={(e) => handleChange('email', e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* <label>Phone number</label> */}
                    <Input
                        label='Phone number'
                        name='phone_number'
                        onChange={(e) =>
                            handleChange('phone_number', e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12} align='right'>
                    <Box>
                        <label>Active</label>
                        <Switch
                            color='success'
                            onChange={(e, nv) => handleChange('status', nv)}
                        />
                    </Box>
                    {/* <Box>
                        <label>Verified</label>
                        <Switch
                            color='success'
                            onChange={(e, nv) =>
                                handleChange('verified_account', nv)
                            }
                        />
                    </Box> */}
                </Grid>
            </Grid>
        </ModalRequest>
    )
}
