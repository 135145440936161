import { Grid, IconButton, Stack, Typography } from '@mui/material'
import TextFieldInput from '../../common/input/TextFieldInput'
import IOSSwitchInput from '../../common/input/IosSwitchInput'
import TextFieldInputParams from '../../common/input/TextFieldInputParams'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveIcon from '@mui/icons-material/Remove'
import { useState } from 'react'
import DropdownWithTitle from '../../common/input/DropdownWithTitle'

export default function AddDeviceType({ input, setInput, isEdit, setErr }) {
    const MAX_PARAMETERS = 5

    const [error, setError] = useState({
        index: 0,
        errMessage: '',
    })

    const handleParameterStatusChange = (parameterIndex, value) => {
        setInput((prevInput) => ({
            ...prevInput,
            deviceTypeParameter: prevInput.deviceTypeParameter.map(
                (param, index) =>
                    index === parameterIndex
                        ? { ...param, status: value }
                        : param,
            ),
        }))
    }

    const typeAlertOptions = [
        { id: 'Remain state', value: 'Remain state' },
        { id: 'Trigger state', value: 'Trigger state' },
    ]

    const handleParameterInputChange = (parameterIndex, field, value) => {
        if (field === 'key' && !/^[a-zA-Z0-9]*$/.test(value)) {
            setError({
                index: parameterIndex,
                errMessage: 'Only English letters and numbers are allowed.',
            })
            setErr(true)
            setInput((prevInput) => ({
                ...prevInput,
                deviceTypeParameter: prevInput.deviceTypeParameter.map(
                    (param, index) =>
                        index === parameterIndex
                            ? { ...param, [field]: value }
                            : param,
                ),
            }))
        } else {
            setError({
                index: 0,
                errMessage: '',
            })
            setErr(false)
            setInput((prevInput) => ({
                ...prevInput,
                deviceTypeParameter: prevInput.deviceTypeParameter.map(
                    (param, index) =>
                        index === parameterIndex
                            ? { ...param, [field]: value }
                            : param,
                ),
            }))
        }
    }

    const addNewParameter = () => {
        const newParameter = {
            parameter: input.deviceTypeParameter.length + 1,
            name: '',
            key: '',
            unit: '',
            status: false,
        }
        setInput((prevInput) => ({
            ...prevInput,
            deviceTypeParameter: [
                ...prevInput.deviceTypeParameter,
                newParameter,
            ],
        }))
    }

    const deleteParameter = (parameterIndex) => {
        setInput((prevInput) => {
            const updatedParameters = prevInput.deviceTypeParameter
                .filter((_, index) => index !== parameterIndex)
                .map((param, index) => ({
                    ...param,
                    parameter: index + 1,
                }))
            return {
                ...prevInput,
                deviceTypeParameter: updatedParameters,
            }
        })
    }

    return (
        <Stack spacing={2}>
            <Typography variant='h5'>
                {isEdit ? 'Edit' : 'Add new'} device type
            </Typography>
            <Grid container direction='row' gap={2}>
                <Grid item xs={12} sm={4.6}>
                    <TextFieldInput
                        state={input}
                        setState={setInput}
                        name='name'
                        label='Device name'
                        size='small'
                    />
                </Grid>
                <Grid item>
                    <TextFieldInput
                        state={input}
                        setState={setInput}
                        name='key_type_id'
                        label='Key ID'
                        size='small'
                        disabled={isEdit}
                    />
                </Grid>
                <Grid item xs={12} sm={2.5}>
                    <DropdownWithTitle
                        state={input}
                        setState={setInput}
                        name='type_alert'
                        title='Type alert'
                        options={typeAlertOptions}
                    />
                </Grid>
            </Grid>
            <Grid container gap={2}>
                {input.deviceTypeParameter.map((parameter, index) => {
                    const parameterName = `parameter${parameter.parameter}`
                    return (
                        <Grid container key={index}>
                            <Grid
                                container
                                direction='row'
                                display='flex'
                                alignItems='center'
                                gap={4}
                            >
                                <Typography variant='h6'>
                                    Parameter {parameter.parameter}
                                </Typography>
                                <IOSSwitchInput
                                    setState={(name, value) =>
                                        handleParameterStatusChange(
                                            index,
                                            value,
                                        )
                                    }
                                    name={parameterName}
                                    status={parameter.status}
                                />
                            </Grid>
                            <Grid
                                container
                                key={index}
                                direction={{ xs: 'column', sm: 'row' }}
                                gap={1}
                            >
                                <Grid item flex={1}>
                                    <TextFieldInputParams
                                        state={parameter}
                                        setState={(value) =>
                                            handleParameterInputChange(
                                                index,
                                                'name',
                                                value,
                                            )
                                        }
                                        name='name'
                                        label='Name'
                                        size='small'
                                        require={true}
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <TextFieldInputParams
                                        state={parameter}
                                        setState={(value) =>
                                            handleParameterInputChange(
                                                index,
                                                'key',
                                                value,
                                            )
                                        }
                                        name='key'
                                        label='Key JSON'
                                        size='small'
                                        isValidateKeyJson
                                        require={true}
                                    />
                                    {error.index === index &&
                                        error.errMessage !== '' && (
                                            <Typography
                                                variant='caption'
                                                color='error'
                                            >
                                                {error.errMessage}
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid item flex={1}>
                                    <TextFieldInputParams
                                        state={parameter}
                                        setState={(value) =>
                                            handleParameterInputChange(
                                                index,
                                                'unit',
                                                value,
                                            )
                                        }
                                        name='unit'
                                        label='Unit'
                                        size='small'
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1.2}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    sx={{ height: 40 }}
                                >
                                    {index ===
                                        input.deviceTypeParameter.length - 1 &&
                                    input.deviceTypeParameter.length <
                                        MAX_PARAMETERS ? (
                                        <>
                                            {index !== 0 &&
                                                index ===
                                                    input.deviceTypeParameter
                                                        .length -
                                                        1 && (
                                                    <IconButton
                                                        variant='contained'
                                                        sx={{
                                                            bgcolor:
                                                                'error.main',
                                                            '&:hover': {
                                                                bgcolor:
                                                                    'error.dark',
                                                            },
                                                        }}
                                                        onClick={() =>
                                                            deleteParameter(
                                                                index,
                                                            )
                                                        }
                                                    >
                                                        <RemoveIcon
                                                            sx={{
                                                                color: 'primary.lightest',
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            <IconButton
                                                variant='contained'
                                                sx={{
                                                    bgcolor: 'secondary.main',
                                                    '&:hover': {
                                                        bgcolor:
                                                            'secondary.dark',
                                                    },
                                                }}
                                                onClick={addNewParameter}
                                            >
                                                <AddRoundedIcon
                                                    sx={{
                                                        color: 'primary.lightest',
                                                    }}
                                                />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <IconButton
                                            variant='contained'
                                            sx={{
                                                bgcolor: 'error.main',
                                                '&:hover': {
                                                    bgcolor: 'error.dark',
                                                },
                                            }}
                                            onClick={() =>
                                                deleteParameter(index)
                                            }
                                        >
                                            <RemoveIcon
                                                sx={{
                                                    color: 'primary.lightest',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Stack>
    )
}
