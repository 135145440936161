import {
    Box,
    Button,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
} from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddGetway from './AddGetway'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import EditGetway from './EditGetway'
import InfoGetway from './InfoGetway'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import { urlConfig } from '../../config/apiConfig'
import axiosInstance from '../../config/axiosConfig'
import ModalDelete from '../../components/ModalDelete'
import { Loadding } from '../../components/StyleComponent'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { AlertContext } from '../../context/AlertContext'
import RefreshIcon from '@mui/icons-material/Refresh'

export default function Getway({
    handleAlert,
    keyName,
    create,
    update,
    remove,
}) {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [row, setRow] = useState(null)
    const [data, setData] = useState(null)
    const { handleOpen } = useContext(AlertContext)

    const getData = useCallback(async () => {
        setData('')
        try {
            const res = await axiosInstance.get(
                `${urlConfig.gateway}/?keyName=${keyName}`,
            )
            console.log('res', res)
            setData(res.data.message)
            handleOpen({ open: false })
        } catch (error) {
            setData([])
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [keyName])

    useEffect(() => {
        getData()
    }, [getData])

    const deleteGataway = async () => {
        setLoading(true)
        try {
            await axiosInstance.delete(`${urlConfig.gateway}/${row._id}`)
            handleAlert('gateway delete successfully.', 'success')
            getData()
            setOpen(false)
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid container spacing={2} p={2}>
            <Grid item xs={12} align='right' mt={0}>
                <Button
                    startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                    variant='contained'
                    color='buttonSave'
                    sx={{
                        borderRadius: '35px',
                        textTransform: 'none',
                        height: '30px',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        marginRight: '10px',
                    }}
                    onClick={() => setOpen('add')}
                    disabled={!create}
                >
                    ADD GATEWAY
                </Button>

                <Button
                    startIcon={
                        <RefreshIcon
                            sx={{
                                width: '20px',
                                height: '20px',
                            }}
                        />
                    }
                    variant='contained'
                    color='inherit'
                    sx={{
                        textTransform: 'uppercase',
                        borderRadius: '30px',
                        // textTransform: 'none',
                        height: '30px',
                        fontWeight: 'bold',
                        fontSize: '12px',
                    }}
                    onClick={getData}
                >
                    refresh
                </Button>
            </Grid>

            {data ? (
                data[0] ? (
                    data.map((item, index) => (
                        <Grid item xs={12} md={12} key={index} mt={2}>
                            {console.log('item', item)}
                            <Paper
                                sx={{
                                    border: '1px solid grey',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                                align='left'
                            >
                                <Box
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <>{item.username}</>
                                    <Box
                                        sx={{
                                            borderRadius: '30px',
                                            fontSize: '12px',
                                            padding: '1px 20px',
                                            background: item.enable
                                                ? item.status === 'Offline'
                                                    ? '#f02f22'
                                                    : '#73BE30'
                                                : 'grey',
                                            color: 'white',
                                        }}
                                    >
                                        {item.enable ? item.status : 'Disable'}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        fontSize: '14px',
                                    }}
                                >
                                    <> Name :{item.name}</>
                                </Box>
                                <Box
                                    sx={{
                                        fontSize: '14px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <> S/N : {item.s_n}</> */}
                                    <Box sx={{ fontSize: '14px' }}>
                                        Client ID : {item._id}
                                    </Box>
                                    {
                                        <IconButton
                                            size='small'
                                            onClick={(e) => {
                                                setAnchorEl(e.currentTarget)
                                                setRow(item)
                                            }}
                                        >
                                            <MoreVertIcon fontSize='small' />
                                        </IconButton>
                                    }
                                </Box>
                                {/* <Box sx={{ fontSize: '14px' }}>
                                    Client ID : {item._id}
                                </Box> */}
                            </Paper>
                        </Grid>
                    ))
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100px',
                            fontWeight: 'bold',
                            color: 'grey',
                        }}
                    >
                        Not Found
                        {/* <StorageIcon
                            color='success'
                            sx={{ marginRight: '10px' }}
                        ></StorageIcon>
                        Not Found */}
                    </Box>
                )
            ) : (
                <Loadding></Loadding>
            )}

            <AddGetway
                req={getData}
                open={open === 'add'}
                setOpen={setOpen}
                handleAlert={handleAlert}
                keyName={keyName}
            />
            {row && (
                <EditGetway
                    req={getData}
                    open={open === 'edit'}
                    setOpen={setOpen}
                    handleAlert={handleAlert}
                    keyName={keyName}
                    edit={row}
                />
            )}
            {row && (
                <InfoGetway
                    open={open === 'Info'}
                    setOpen={setOpen}
                    row={row}
                />
            )}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    onClick={() => {
                        setOpen('Info')
                        setAnchorEl(null)
                    }}
                >
                    <ListItemIcon>
                        <RemoveRedEyeOutlinedIcon
                            fontSize='small'
                            color='secondary'
                        />
                    </ListItemIcon>
                    Information
                </MenuItem>
                {update && (
                    <MenuItem
                        onClick={() => {
                            setOpen('edit')
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon fontSize='small' color='warning' />
                        </ListItemIcon>
                        Edit
                    </MenuItem>
                )}
                {remove && (
                    <MenuItem
                        onClick={() => {
                            setOpen('delete')
                            setAnchorEl(null)
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize='small' color='error' />
                        </ListItemIcon>
                        Delete
                    </MenuItem>
                )}
            </Menu>

            <ModalDelete
                disabled={loading}
                onSubmit={deleteGataway}
                open={open === 'delete'}
                setOpen={setOpen}
                message={`${row?.name}`}
            />
        </Grid>
    )
}
