import ModalRequest from '../../components/ModalRequest'
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useCallback, useContext, useEffect, useState } from 'react'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { AlertContext } from '../../context/AlertContext'

export default function Edit({
    open,
    setOpen,
    keyName,
    username,
    handleAlert,
    getUserByKey,
    row,
}) {
    const [role, setRole] = useState([])
    const [loading, setLoading] = useState(false)
    const [site, setSite] = useState('')
    const { handleSubmit, setValue, watch, register, reset } = useForm()

    const onSubmit = async (data) => {
        if (data.password !== data.confirmPassword) {
            alert('Passwords do not match!')
            return
        }
        const body = {
            ...data,
            username: username,
            expire_date: dayjs(data.expire_date, 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
            ),
        }
        try {
            setLoading(true)
            await axiosInstance.put(`${urlConfig.user}/${row.user_id}`, body)
            handleAlert('User updated successfully.', 'success')
            getUserByKey()
            setOpen(false)
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (row) {
            const formattedRow = {
                ...row,
                expire_date: row.expire_date
                    ? dayjs(row.expire_date).format('DD/MM/YYYY')
                    : '',
            }
            reset(formattedRow)
        }
    }, [row, reset])

    const getRole = useCallback(async () => {
        try {
            const res = await axiosInstance.get(urlConfig.role)
            setRole(res.data.message)
        } catch {
            // Handle error
        }
    }, [])

    useEffect(() => {
        getRole()
    }, [getRole])

    const { handleOpen } = useContext(AlertContext)

    const getSite = useCallback(async () => {
        try {
            const res = await axiosInstance.get(`${urlConfig.organization}`)
            setSite(res.data.message)
            handleOpen({ open: false })
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [setSite, urlConfig.organization])

    useEffect(() => {
        getSite()
    }, [getSite])

    const handleChange = (name, value) => {
        name === 'keyName'
            ? setValue(name, value)
            : setValue(name, value ? 'Active' : 'Inactive')
    }

    return (
        <ModalRequest
            open={open}
            disabled={loading}
            setOpen={setOpen}
            title={`Edit User [${username}]`}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid item xs={12}>
                <FormControl
                    fullWidth
                    size='small'
                    sx={{
                        height: '35px',
                        // borderRadius: '10px',
                    }}
                >
                    <InputLabel id='demo-simple-select-label'>Group</InputLabel>
                    <Select
                        label='Group'
                        id='Role'
                        required
                        onChange={(e) => setValue('group', e.target.value)}
                        defaultValue={''}
                        value={watch('group') || ''}
                    >
                        <MenuItem value={'User'}>User</MenuItem>
                        <MenuItem value={'Admin'}>Admin</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
                <FormControl fullWidth size='small'>
                    <InputLabel id='demo-simple-select-label'>Role</InputLabel>
                    <Select
                        label='Role'
                        id='Role'
                        value={watch('role_id') || ''}
                        onChange={(e) => setValue('role_id', e.target.value)}
                        defaultValue={''}
                        // sx={{ borderRadius: '10px' }}
                    >
                        <MenuItem value={''}>-</MenuItem>
                        {role &&
                            role.map((item, index) => (
                                <MenuItem key={index} value={item.role_id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid> */}
            {watch('group') && (
                <Grid item xs={12}>
                    <FormControl
                        fullWidth
                        size='small'
                        sx={{
                            height: '35px',
                            // borderRadius: '10px',
                        }}
                    >
                        <InputLabel id='demo-simple-select-label'>
                            Role
                        </InputLabel>
                        <Select
                            label='Role'
                            id='Role'
                            required
                            onChange={(e) =>
                                setValue('role_id', e.target.value)
                            }
                            value={watch('role_id') || ''}
                        >
                            <MenuItem value={''}>-</MenuItem>
                            {role &&
                                role
                                    .filter(
                                        (item) => item.group === watch('group'),
                                    )
                                    .map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item.role_id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            <Grid item xs={12}>
                <FormControl
                    fullWidth
                    size='small'
                    sx={{
                        height: '35px',
                        // borderRadius: '10px',
                    }}
                >
                    <InputLabel id='demo-simple-select-label'>
                        Organization
                    </InputLabel>
                    <Select
                        label='Organization'
                        id='Role'
                        required
                        onChange={(e) =>
                            handleChange('keyName', e.target.value)
                        }
                        value={watch('keyName')}
                        disabled
                    >
                        {/* <MenuItem value={''}>-</MenuItem> */}
                        {site &&
                            site.map((item, index) => (
                                <MenuItem key={index} value={item.keyName}>
                                    {item.site_name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
                <TextField
                    fullWidth
                    label='Username'
                    size='small'
                    defaultValue={watch('username')}
                    {...register('username')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label='First Name'
                    size='small'
                    defaultValue={watch('first_name')}
                    {...register('first_name')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label='Last Name'
                    size='small'
                    defaultValue={watch('last_name')}
                    {...register('last_name')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label='Email'
                    size='small'
                    type='email'
                    defaultValue={watch('email')}
                    {...register('email')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label='Phone Number'
                    size='small'
                    defaultValue={watch('phone_number')}
                    {...register('phone_number')}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <DatePicker
                    sx={{ width: '100%' }}
                    name='expire_date'
                    label='Expire Date'
                    InputLabelProps={{ shrink: true }}
                    slotProps={{ textField: { size: 'small' } }}
                    value={
                        watch('expire_date')
                            ? dayjs(watch('expire_date'), 'DD/MM/YYYY')
                            : null
                    }
                    format='DD/MM/YYYY'
                    onChange={(date) => {
                        setValue(
                            'expire_date',
                            date ? date.format('DD/MM/YYYY') : '',
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} align='right'>
                <Box>
                    <label>Active</label>
                    <Switch
                        checked={watch('status') === 'Active'}
                        color='success'
                        onChange={(e, nv) => handleChange('status', nv)}
                    />
                </Box>
            </Grid>
        </ModalRequest>
    )
}
