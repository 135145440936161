import React, { useEffect, useState } from 'react'
import { urlConfig } from '../config/apiConfig'
import { useAuth } from '../hook/useAuth'
import { Outlet } from 'react-router-dom'
import MenuBar from './Header/MenuBar'
import MenuDrawer from './Header/SideBar'
import { Box } from '@mui/material'
import Cookies from 'js-cookie'
import axiosInstance from '../config/axiosConfig'

export const checkToken = async (setAuth, setLoading, setError) => {
    const encodedAccessToken = Cookies.get('token')
    const accessToken = encodedAccessToken ? atob(encodedAccessToken) : null

    setError(false)
    try {
        if (!accessToken) {
            logout()
        }
        const response = await axiosInstance.get(urlConfig.myToken, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
        setAuth(response.data.message)
        setLoading(false)
    } catch (error) {
        if (error.message === 'Network Error') {
            setError(error.message)
        } else if (error.response && error.response.status === 404) {
            setError('404: Resource not found')
        } else if (error.response && error.response.status === 500) {
            setError('500: Internal Server Error')
        } else if (error.code === 'ECONNABORTED') {
            setError('Request Timeout')
        }
    }
}

export const logout = async () => {
    try {
        const encodedAccessToken = Cookies.get('token')
        const accessToken = encodedAccessToken ? atob(encodedAccessToken) : null
        await axiosInstance.post(urlConfig.logout, null, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        Cookies.remove('token')
        Cookies.remove('retoken')
        window.location.href = '/login'
    } catch (error) {
        if (error.response && error.response.status === 401) {
            Cookies.remove('token')
            Cookies.remove('retoken')
            window.location.href = '/login'
        }
        console.error('Logout failed:', error)
    }
}

export default function ProtectRoute({ handleAlert }) {
    const [open, setOpen] = React.useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const { auth, setAuth } = useAuth()
    const encodedAccessToken = Cookies.get('token')
    const accessToken = encodedAccessToken ? atob(encodedAccessToken) : null

    useEffect(() => {
        if (accessToken && !auth && window.location.pathname !== '/login') {
            checkToken(setAuth, setLoading, setError)
            console.log(' check')
        } else if (!accessToken && window.location.pathname !== '/login') {
            console.log('yes wrong')

            setAuth(null)
            Cookies.remove('token')
            Cookies.remove('retoken')
            window.location.href = '/login'
        }
    }, [accessToken, auth, setAuth])

    //   useEffect(() => {

    //           setAuth(null)
    //           Cookies.remove('token')
    //           Cookies.remove('retoken')
    //           window.location.href = '/login'

    //   }, [accessToken, auth, setAuth])

    if (loading && window.location.pathname !== '/login') {
        return (
            <React.Fragment>
                {error ? (
                    error
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: '100vw',
                            height: '100vh',
                        }}
                    >
                        <div>
                            <div className='loader2' />
                            <div style={{ fontSize: '40px', color: 'white' }}>
                                <div className='wrapper'>
                                    <h1> SMART LIGHTNING MANAGEMENT SYSTEM</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    minHeight: '100vh',
                    overflowX: 'auto',
                }}
            >
                <MenuBar
                    handleDrawerOpen={() => setOpen((state) => !state)}
                    handleAlert={handleAlert}
                />
                <MenuDrawer
                    open={open}
                    handleDrawerClose={() => setOpen(false)}
                />
                <Outlet />
            </Box>
        </React.Fragment>
    )
}
