import { Alert, Box, IconButton, Snackbar, Typography } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export default function PopOver({ anchorEl, setAnchorEl, isSuccess, message }) {
    const open = Boolean(anchorEl)

    const handleClosePopover = () => {
        setAnchorEl(false)
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={
                <IconButton
                    size='small'
                    aria-label='close'
                    color='inherit'
                    onClick={handleClosePopover}
                >
                    <CloseRoundedIcon fontSize='small' />
                </IconButton>
            }
            sx={{
                '& .MuiSnackbarContent-root': {
                    border:
                        isSuccess === 'success'
                            ? '1px solid green'
                            : '1px solid red',
                    backgroundColor: isSuccess ? '#EDF7ED' : '#ffe8e8',
                    color: isSuccess ? '#1E4620' : 'red',
                    display: 'flex',
                },
            }}
        >
            <Alert
                onClose={handleClosePopover}
                severity={isSuccess ? 'success' : 'error'}
                sx={{
                    width: '100%',
                    border: '1px solid',
                    borderColor: isSuccess ? 'success.main' : 'error.main',
                    bgcolor: isSuccess ? '#edf7ed' : '#fff3f3',
                    color: isSuccess ? '#1E4620' : 'red',
                }}
                icon={
                    isSuccess ? (
                        <CheckCircleIcon
                            sx={{
                                marginRight: '8px',
                                color: '#4CAF50',
                            }}
                        />
                    ) : (
                        <CancelIcon sx={{ marginRight: '8px', color: 'red' }} />
                    )
                }
            >
                <Box>
                    <div>
                        <div style={{ fontSize: '16px' }}>
                            {isSuccess ? 'SUCCESS' : 'ERROR'}
                        </div>
                        <div style={{ fontSize: '14px' }}>{message}</div>
                    </div>
                    {/* <Typography
                        fontWeight={'bold'}
                        color={isSuccess ? 'success.main' : 'error.main'}
                    >
                        {isSuccess ? 'SUCCESS' : 'FAILED'}
                    </Typography>
                    <Typography
                        color={isSuccess ? 'success.main' : 'error.main'}
                    >
                        {message}
                    </Typography> */}
                </Box>
            </Alert>
        </Snackbar>
    )
}
