import React, { useState } from 'react'
import ModalRequest from '../../components/ModalRequest'
import { Box, IconButton, Snackbar, Switch } from '@mui/material'
import { Grid } from '@mui/material'
import { Input } from '../../components/StyleComponent'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MuiAlert from '@mui/material/Alert'

export default function InfoGetway({ open, setOpen, row }) {
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setOpenSnackbar(true) // Show the snackbar when copy is successful
        })
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }

    return (
        <ModalRequest
            infoMode={true}
            open={open}
            setOpen={setOpen}
            title={`Gateway ID : ${row?.username}`}
        >
            <Grid container spacing={1} sx={{ padding: '10px 40px' }}>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid item xs={2}>
                        Name
                    </Grid>
                    <Grid item xs={10} align='left'>
                        : {row.name}
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid item xs={2}>
                        S/N
                    </Grid>
                    <Grid item xs={10} align='left'>
                        : {row.s_n}
                    </Grid>
                </Grid> */}
                <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Grid item xs={2}>
                        Status
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        align='left'
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        :{' '}
                        <Box
                            sx={{
                                borderRadius: '30px',
                                fontSize: '12px',
                                padding: '1px 20px',
                                background: row.enable
                                    ? row.status === 'Offline'
                                        ? '#f02f22'
                                        : '#73BE30'
                                    : 'grey',
                                color: 'white',
                            }}
                        >
                            {row.enable ? row.status : 'Disable'}
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        sx={{
                            borderRadius: '10px',
                            border: '2px solid lightgrey',
                            padding: '10px',
                        }}
                    >
                        <Box sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                            Client ID :
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Input
                                defaultValue={row.username}
                                readOnly={true}
                                sx={{
                                    background: 'white',
                                    border: 'none',
                                    fontSize: '14px',
                                    marginRight: '5px',
                                }}
                            ></Input>
                            <IconButton
                                onClick={() => handleCopy(row.username)}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{ fontSize: '14px', fontWeight: 'bold', mt: 1 }}
                        >
                            Password :
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Input
                                defaultValue={row.password}
                                readOnly={true}
                                sx={{
                                    background: 'white',
                                    border: 'none',
                                    fontSize: '14px',
                                    marginRight: '5px',
                                }}
                            ></Input>
                            <IconButton
                                onClick={() => handleCopy(row.password)}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{ fontSize: '14px', fontWeight: 'bold', mt: 1 }}
                        >
                            Topic main
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Input
                                defaultValue={row.main_topic}
                                readOnly={true}
                                sx={{
                                    background: 'white',
                                    border: 'none',
                                    fontSize: '14px',
                                    marginRight: '5px',
                                }}
                            ></Input>
                            <IconButton
                                onClick={() => handleCopy(row.main_topic)}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* Snackbar for copy success */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <MuiAlert
                    onClose={handleCloseSnackbar}
                    severity='success'
                    sx={{ width: '100%' }}
                >
                    Copied to clipboard!
                </MuiAlert>
            </Snackbar>
        </ModalRequest>
    )
}
