import React, { useState } from 'react'
import ModalRequest from '../../components/ModalRequest'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { Input } from '../../components/StyleComponent'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export default function AddOrg({ open, setOpen, req, handleAlert }) {
    const { handleSubmit, control, setValue, watch } = useForm()
    const [selectedTimezone, setSelectedTimezone] = useState('')
    const [loading, setLoading] = useState(false)

    const labelStyle = 'original'
    const timezones = {
        ...allTimezones,
        'Europe/Berlin': 'Frankfurt',
    }

    const { options } = useTimezoneSelect({
        labelStyle,
        timezones,
    })

    const expire_date = watch('expire_date')

    const onSubmit = async (data) => {
        const body = {
            ...data,
            timezone: selectedTimezone,
            expire_date: dayjs(data.expire_date, 'DD/MM/YYYY').format(
                'YYYY-MM-DD',
            ),
        }
        console.log('body', body)
        setLoading(true)
        try {
            await axiosInstance.post(urlConfig.organization, body)
            handleAlert('Organization added successfully.', 'success')
            setOpen(false)
            req()
        } catch (error) {
            const err = error.response?.data?.message
            handleAlert(`Failed to create the Organization. ${err}.`, 'error')
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalRequest
            open={open}
            setOpen={setOpen}
            title={`Add Organization`}
            onSubmit={handleSubmit(onSubmit)}
            disabled={loading}
        >
            <Grid item xs={12} md={4}>
                <Controller
                    name='site_name'
                    control={control}
                    defaultValue='' // provide a default value
                    render={({ field }) => (
                        <Input
                            label='Customer Name'
                            value={field.value} // explicitly pass value
                            onChange={field.onChange} // explicitly pass onChange
                            required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Controller
                    name='keyName'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='KeyName'
                            value={field.value}
                            onChange={(e) => {
                                const value = e.target.value
                                    .toUpperCase()
                                    .slice(0, 5)
                                field.onChange(value)
                            }}
                            required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DatePicker
                    name='expire_date'
                    label='Expire Date'
                    InputLabelProps={{ shrink: true }}
                    slotProps={{ textField: { size: 'small' } }}
                    format='DD/MM/YYYY'
                    value={expire_date}
                    onChange={(date) =>
                        setValue('expire_date', date ? date : '')
                    }
                    sx={{ width: '100%' }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Controller
                    name='latitude'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Latitude'
                            type='number'
                            value={field.value}
                            onChange={(e) => {
                                let value = e.target.value
                                if (value === '-' || value === '') {
                                    field.onChange(value)
                                    return
                                }
                                const regex = /^-?\d*(\.\d{0,5})?$/
                                if (regex.test(value)) {
                                    let parsedValue = parseFloat(value)
                                    if (!isNaN(parsedValue)) {
                                        if (parsedValue > 90) {
                                            parsedValue = 90
                                        } else if (parsedValue < -90) {
                                            parsedValue = -90
                                        }
                                    }
                                    field.onChange(parsedValue)
                                }
                            }}
                            required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Controller
                    name='longitude'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Longitude'
                            type='number'
                            value={field.value}
                            onChange={(e) => {
                                let value = e.target.value
                                if (value === '-' || value === '') {
                                    field.onChange(value)
                                    return
                                }
                                const regex = /^-?\d*(\.\d{0,5})?$/
                                if (regex.test(value)) {
                                    let parsedValue = parseFloat(value)
                                    if (!isNaN(parsedValue)) {
                                        if (parsedValue > 180) {
                                            parsedValue = 180
                                        } else if (parsedValue < -180) {
                                            parsedValue = -180
                                        }
                                    }
                                    field.onChange(parsedValue)
                                }
                            }}
                            required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <FormControl fullWidth size='small'>
                    <InputLabel id='timezone-label'>TimeZone</InputLabel>
                    <Controller
                        name='timeZone'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                            <Select
                                labelId='timezone-label'
                                label='TimeZone'
                                value={selectedTimezone}
                                onChange={(e) => {
                                    setSelectedTimezone(e.target.value)
                                }}
                            >
                                {options.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
                <Controller
                    name='address'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Address'
                            rows={3}
                            multiline
                            value={field.value}
                            onChange={field.onChange}
                            required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    name='phone_number'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Phone Number'
                            value={field.value}
                            onChange={field.onChange}
                            required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    name='email'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Email'
                            type='email'
                            value={field.value}
                            onChange={field.onChange}
                            // required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Controller
                    name='line_notify_token'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Line Token'
                            value={field.value}
                            onChange={field.onChange}
                            // required
                        />
                    )}
                />
            </Grid>
        </ModalRequest>
    )
}
