import { Autocomplete, Grid, Stack, TextField, Typography } from '@mui/material'
import TextFieldInput from '../../../../../common/input/TextFieldInput'
import DropdownInput from '../../../../../common/input/DropdownInput'
import IOSSwitchInputNormal from '../../../../../common/input/IosSwitchInputNormal'
import { useContext, useEffect, useState } from 'react'
import axiosInstance from '../../../../../../config/axiosConfig'
import { urlConfig } from '../../../../../../config/apiConfig'
import { AlertContext } from '../../../../../../context/AlertContext'

export default function NotificationForm({
    input,
    setInput,
    parameterOptions,
    isEdit,
    keyTypeId,
}) {
    const [paramOptions, setParamOption] = useState([])
    const { handleOpen } = useContext(AlertContext)

    const handleGetParamOption = async () => {
        try {
            const resp = await axiosInstance.get(
                `${urlConfig.deviceType}/${keyTypeId}`,
            )
            if (resp.data.status === 200) {
                setParamOption(resp.data.message?.deviceTypeParameter)
            }
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }

    const paramsAOptions = (keyTypeId ? paramOptions : parameterOptions)?.map(
        (item) => ({
            id: item.name,
            value: item.name,
        }),
    )

    const options = [
        { id: '>', value: '>' },
        { id: '<', value: '<' },
        { id: '>=', value: '>=' },
        { id: '<=', value: '<=' },
        { id: '==', value: '==' },
        { id: '!=', value: '!=' },
    ]

    const remain_connector = [
        { id: 'no', value: 'no' },
        { id: 'and', value: 'and' },
        { id: 'or', value: 'or' },
    ]

    useEffect(() => {
        if (keyTypeId) handleGetParamOption()
    }, [keyTypeId])

    // useEffect(() => {
    //     console.log('---------------------------------', input)
    // }, [input])

    return (
        <Stack spacing={2}>
            <Typography variant='h5'>
                {isEdit ? 'Edit' : ''} Notification Setting
            </Typography>
            <Grid
                container
                direction={{ xs: 'column', sm: 'row' }}
                gap={1}
                display={'flex'}
                justifyContent={'space-between'}
            >
                <Grid item xs={12}>
                    <TextFieldInput
                        state={input}
                        setState={setInput}
                        name='typeAlert'
                        label={'Type Alert'}
                        size='small'
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <TextFieldInput
                        state={input}
                        setState={setInput}
                        name='name'
                        label={'Notification Name'}
                        size='small'
                    />
                </Grid>
                {input.typeAlert === 'Remain state' ? (
                    <>
                        <Grid
                            container
                            direction={{ xs: 'column', sm: 'row' }}
                            display={'flex'}
                            justifyContent={'space-between'}
                            mt={1}
                            gap={1}
                        >
                            <Grid
                                item
                                display={'flex'}
                                flex={1}
                                gap={1}
                                justifyContent={'space-between'}
                            >
                                <Grid item flex={1}>
                                    <DropdownInput
                                        state={input.remain_parameter_1}
                                        setState={setInput}
                                        name='remain_parameter_1'
                                        options={paramsAOptions}
                                        borderRadius={1.5}
                                        title={`Parameter ${
                                            input.remain_connector === 'and' ||
                                            input.remain_connector === 'or'
                                                ? '1'
                                                : ''
                                        }`}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <DropdownInput
                                        state={input.remain_condition_1}
                                        setState={setInput}
                                        name='remain_condition_1'
                                        options={options}
                                        borderRadius={1.5}
                                        title={`Condition ${
                                            input.remain_connector === 'and' ||
                                            input.remain_connector === 'or'
                                                ? '1'
                                                : ''
                                        }`}
                                    />
                                </Grid>
                                <Grid item flex={1}>
                                    <Autocomplete
                                        freeSolo
                                        options={paramsAOptions.map(
                                            (option) => option.value,
                                        )}
                                        value={
                                            input.remain_set_point_1
                                                ? input.remain_set_point_1
                                                : input.remain_set_point_1_name
                                        }
                                        onChange={(event, newValue) => {
                                            const selectedOption =
                                                paramsAOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        newValue,
                                                )
                                            setInput((prev) => ({
                                                ...prev,
                                                remain_set_point_1:
                                                    selectedOption
                                                        ? null
                                                        : newValue,
                                                remain_set_point_1_name:
                                                    selectedOption
                                                        ? newValue
                                                        : null,
                                            }))
                                        }}
                                        onInputChange={(
                                            event,
                                            newInputValue,
                                        ) => {
                                            const isOptionValue =
                                                paramsAOptions.some(
                                                    (option) =>
                                                        option.value ===
                                                        newInputValue,
                                                )

                                            if (!isOptionValue) {
                                                setInput((prev) => ({
                                                    ...prev,
                                                    remain_set_point_1:
                                                        newInputValue,
                                                }))
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={`Set point ${
                                                    input.remain_connector ===
                                                        'and' ||
                                                    input.remain_connector ===
                                                        'or'
                                                        ? '1'
                                                        : ''
                                                }`}
                                                size='small'
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <DropdownInput
                                    state={input.remain_connector}
                                    setState={setInput}
                                    name='remain_connector'
                                    options={remain_connector}
                                    borderRadius={1.5}
                                    title='remain_connector'
                                />
                            </Grid>
                        </Grid>
                        {(input.remain_connector === 'and' ||
                            input.remain_connector === 'or') && (
                            <Grid
                                container
                                direction={{ xs: 'column', sm: 'row' }}
                                display={'flex'}
                                justifyContent={'space-between'}
                                mt={1}
                                gap={1}
                            >
                                <Grid
                                    item
                                    display={'flex'}
                                    gap={1}
                                    xs={12}
                                    justifyContent={'space-between'}
                                >
                                    <Grid item flex={1}>
                                        <DropdownInput
                                            state={input.remain_parameter_2}
                                            setState={setInput}
                                            name='remain_parameter_2'
                                            options={paramsAOptions}
                                            borderRadius={1.5}
                                            title={`Parameter ${
                                                input.remain_connector ===
                                                    'and' ||
                                                input.remain_connector === 'or'
                                                    ? '2'
                                                    : ''
                                            }`}
                                        />
                                    </Grid>
                                    <Grid item xs={1.65}>
                                        <DropdownInput
                                            state={input.remain_condition_2}
                                            setState={setInput}
                                            name='remain_condition_2'
                                            options={options}
                                            borderRadius={1.5}
                                            title={`Condition ${
                                                input.remain_connector ===
                                                    'and' ||
                                                input.remain_connector === 'or'
                                                    ? '2'
                                                    : ''
                                            }`}
                                        />
                                    </Grid>
                                    <Grid item flex={1}>
                                        <Autocomplete
                                            freeSolo
                                            options={paramsAOptions.map(
                                                (option) => option.value,
                                            )}
                                            value={
                                                input.remain_set_point_2
                                                    ? input.remain_set_point_2
                                                    : input.remain_set_point_2_name
                                            }
                                            onChange={(event, newValue) => {
                                                const selectedOption =
                                                    paramsAOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            newValue,
                                                    )
                                                setInput((prev) => ({
                                                    ...prev,
                                                    remain_set_point_2:
                                                        selectedOption
                                                            ? null
                                                            : newValue,
                                                    remain_set_point_2_name:
                                                        selectedOption
                                                            ? newValue
                                                            : null,
                                                }))
                                            }}
                                            onInputChange={(
                                                event,
                                                newInputValue,
                                            ) => {
                                                const isOptionValue =
                                                    paramsAOptions.some(
                                                        (option) =>
                                                            option.value ===
                                                            newInputValue,
                                                    )

                                                if (!isOptionValue) {
                                                    setInput((prev) => ({
                                                        ...prev,
                                                        remain_set_point_2:
                                                            newInputValue,
                                                    }))
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`Set point ${
                                                        input.remain_connector ===
                                                            'and' ||
                                                        input.remain_connector ===
                                                            'or'
                                                            ? '2'
                                                            : ''
                                                    }`}
                                                    size='small'
                                                />
                                            )}
                                        />
                                        {/* <TextFieldInput
                                            state={input}
                                            setState={setInput}
                                            name='remain_set_point_2'
                                            label={`Set point ${
                                                input.remain_connector ===
                                                    'and' ||
                                                input.remain_connector === 'or'
                                                    ? '2'
                                                    : ''
                                            }`}
                                            size='small'
                                            isNumber={true}
                                        /> */}
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid container gap={1} mt={1}>
                        <Grid item xs={5}>
                            {input.typeAlert !== 'Remain state' && (
                                <TextFieldInput
                                    state={input}
                                    setState={setInput}
                                    name='trigger_time_interval'
                                    label='Time'
                                    size='small'
                                    isNumber={true}
                                    minValue={5}
                                />
                            )}
                        </Grid>
                        <Grid item flex={1}>
                            <DropdownInput
                                state={input.trigger_parameter}
                                setState={setInput}
                                name='trigger_parameter'
                                options={paramsAOptions}
                                borderRadius={1.5}
                                title='Parameter Detect'
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <TextFieldInput
                    state={input}
                    setState={setInput}
                    name='desc'
                    label='Description notification'
                    size='small'
                />
            </Grid>
            <Grid container>
                <Grid
                    container
                    direction='row'
                    display='flex'
                    alignItems='center'
                    justifyContent={'space-between'}
                    gap={2}
                >
                    <Typography variant='body1' fontWeight={'bold'}>
                        Line Message
                    </Typography>
                    <IOSSwitchInputNormal
                        setState={setInput}
                        name={'lineEnable'}
                        status={input.lineEnable}
                    />
                </Grid>
                <TextFieldInput
                    state={input}
                    setState={setInput}
                    name='lineToken'
                    size='small'
                    isMultiple={true}
                />
            </Grid>
            <Grid container>
                <Grid
                    container
                    direction='row'
                    display='flex'
                    alignItems='center'
                    justifyContent={'space-between'}
                    gap={2}
                >
                    <Typography variant='body1' fontWeight={'bold'}>
                        Email Message
                    </Typography>
                    <IOSSwitchInputNormal
                        setState={setInput}
                        name={'emailEnable'}
                        status={input.emailEnable}
                    />
                </Grid>
                <TextFieldInput
                    state={input}
                    setState={setInput}
                    name='emailAddress'
                    size='small'
                    isMultiple={true}
                    // isEmail={true}
                />
            </Grid>
        </Stack>
    )
}
