import { Grid, Typography } from '@mui/material'

export default function MqttSummary({ summary }) {
    return (
        <>
            <Grid container mt={-2}>
                <Grid item xs={12} sm={12} md={8} lg={6}>
                    <Grid
                        container
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        display={'flex'}
                        justifyContent={'space-between'}
                        gap={2}
                    >
                        <Grid
                            item
                            flex={1}
                            bgcolor={'background.paper'}
                            borderRadius={3}
                            py={3}
                            boxShadow={3}
                            sx={{
                                borderLeft: '7px solid',
                                borderColor: 'primary.main',
                            }}
                        >
                            <Typography textAlign={'center'} variant='h6'>
                                Total clients
                            </Typography>
                            <Typography
                                textAlign={'center'}
                                variant='h1'
                                mt={1}
                            >
                                {summary.totalClients}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            flex={1}
                            bgcolor={'background.paper'}
                            borderRadius={3}
                            py={3}
                            boxShadow={3}
                            sx={{
                                borderLeft: '7px solid',
                                borderColor: 'primary.main',
                            }}
                        >
                            <Typography textAlign={'center'} variant='h6'>
                                Active clients
                            </Typography>
                            <Typography
                                textAlign={'center'}
                                variant='h1'
                                mt={1}
                            >
                                {summary.activeClients}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            flex={1}
                            bgcolor={'background.paper'}
                            borderRadius={3}
                            py={3}
                            boxShadow={3}
                            sx={{
                                borderLeft: '7px solid',
                                borderColor: 'primary.main',
                            }}
                        >
                            <Typography textAlign={'center'} variant='h6'>
                                Connected clients
                            </Typography>
                            <Typography
                                textAlign={'center'}
                                variant='h1'
                                mt={1}
                            >
                                {summary.connectedClients}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
            </Grid>
        </>
    )
}
