import { TextField } from '@mui/material'

export default function TextFieldInputParams({
    state,
    setState,
    placeholder,
    size,
    name,
    label,
    require,
}) {
    return (
        <>
            {/* <Typography fontWeight={700}>
                {label}{' '}
                {require && <span style={{ color: 'error.main' }}>*</span>}
            </Typography> */}
            <TextField
                value={state[name]}
                required={require}
                label={label}
                placeholder={placeholder}
                size={size || 'medium'}
                onChange={(e) => {
                    setState(e.target.value)
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: 1.5,
                    },
                }}
                fullWidth
            />
        </>
    )
}
