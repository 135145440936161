import {
    Box,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@mui/material'
import React, { useState } from 'react'
import { useAuth } from '../../hook/useAuth'
import { AccountCircle, Lock, ExitToApp } from '@mui/icons-material'
import { logout } from '../../components/ProtectRoute'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import UserManage from '../../pages/User/UserManage'
import PasswordChange from '../../pages/User/PasswordChange'

export default function MenuBar({ handleDrawerOpen, handleAlert }) {
    const { auth } = useAuth()
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)

    return (
        <React.Fragment>
            <Box
                sx={{
                    background: 'white',
                    width: '100%',
                    position: 'fixed',
                    height: '55px',
                    zIndex: 1300,
                    padding: '0 20px 0 5px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    whiteSpace: 'nowrap',
                }}
            >
                <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        height: '100%',
                        whiteSpace: 'nowrap',
                        flexWrap: 'nowrap',
                    }}
                >
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton onClick={handleDrawerOpen}>
                            <svg
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                                height='35px'
                                fill='#DA1C23'
                            >
                                <path d='M18 8H6V6h12v2Z' />
                                <path d='M18 13H6v-2h12v2Z' />
                                <path d='M18 18H6v-2h12v2Z' />
                            </svg>
                        </IconButton>
                        <Box sx={{ marginLeft: '5px', cursor: 'pointer' }}>
                            <Box
                                sx={{
                                    userSelect: 'none',
                                    fontSize: '30px',
                                    fontWeight: 'bold',
                                    marginTop: '-8px',
                                    color: '#DA1C23',
                                }}
                            >
                                <img
                                    src='/images/logo_kumwell.png'
                                    width={90}
                                ></img>
                            </Box>
                            <Box
                                sx={{
                                    fontSize: '11px',
                                    marginTop: '-10px',
                                    userSelect: 'none',
                                }}
                            >
                                Smart Lightning Management System
                            </Box>
                        </Box>
                        {auth.group === 'Admin' && (
                            <Box
                                variant='contained'
                                sx={{
                                    fontSize: '11px',
                                    marginLeft: '15px',
                                    borderRadius: '30px',
                                    fontWeight: '200',
                                    padding: '1px 10px',
                                    color: 'white',
                                    py: 0.5,
                                    bgcolor: 'buttonSave.main',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <AdminPanelSettingsOutlinedIcon
                                    fontSize='small'
                                    sx={{ marginRight: '5px' }}
                                />
                                ADMIN CONSOLE
                            </Box>
                        )}
                    </Grid>
                    <Grid item>
                        <Box
                            onClick={(event) =>
                                setAnchorEl(event.currentTarget)
                            }
                            sx={{
                                cursor: 'pointer',
                                background: '#E8E8E8',
                                padding: '4px',
                                borderRadius: '30px',
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    color: '#DA1C23',
                                    boxShadow: 'rgba(0, 0, 0, 0.5) 0px 1px 4px',
                                },
                                transition: '0.3s ease-out',
                            }}
                        >
                            <Box
                                sx={{
                                    background: '#DA1C23',
                                    color: 'white',
                                    borderRadius: '100%',
                                    width: '30px',
                                    height: '30px',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    marginRight: {
                                        xs: '0px',
                                        sm: '7px',
                                        md: '7px',
                                    },
                                    fontSize: '12px',
                                }}
                            >
                                {auth &&
                                    auth.first_name
                                        .substring(0, 2)
                                        .toUpperCase()}
                            </Box>
                            <Box
                                sx={{
                                    display: {
                                        xs: 'none',
                                        sm: 'block',
                                        md: 'block',
                                    },
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    marginRight: '10px',
                                    fontSize: '16px',
                                    color: '5F5F5F',
                                }}
                            >
                                {auth && auth.first_name}{' '}
                                {auth && auth.last_name}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null)
                        setOpen('user')
                    }}
                    sx={{
                        fontSize: '14px',
                        transition: '0.2s ease-out',
                    }}
                >
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    My Account
                </MenuItem>
                <MenuItem
                    sx={{
                        fontSize: '14px',
                        transition: '0.2s ease-out',
                    }}
                    onClick={() => {
                        setAnchorEl(null)
                        setOpen('password')
                    }}
                >
                    <ListItemIcon>
                        <Lock />
                    </ListItemIcon>
                    Change Password
                </MenuItem>
                <MenuItem
                    sx={{
                        transition: '0.2s ease-out',
                        fontSize: '14px',
                        '&:hover': {
                            color: '#DA1C23',
                            '& svg': {
                                fill: '#DA1C23',
                            },
                        },
                    }}
                    onClick={logout}
                >
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            {open === 'user' && (
                <UserManage
                    open={open === 'user'}
                    setOpen={setOpen}
                    handleAlert={handleAlert}
                />
            )}
            {open === 'password' && (
                <PasswordChange
                    open={open === 'password'}
                    setOpen={setOpen}
                    handleAlert={handleAlert}
                />
            )}
        </React.Fragment>
    )
}
