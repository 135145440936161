import { IconButton, Snackbar } from '@mui/material'
import { useContext } from 'react'
import { AlertContext } from '../../context/AlertContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'

export default function Alert() {
    const {
        handleClose,
        modal: { open, isSuccess, message = 'Not found data' },
    } = useContext(AlertContext)

    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            sx={{
                '& .MuiSnackbarContent-root': {
                    border: isSuccess ? '1px solid green' : '1px solid red',
                    backgroundColor: isSuccess ? '#EDF7ED' : '#ffe8e8',
                    color: isSuccess ? '#1E4620' : 'red',
                    display: 'flex',
                },
            }}
            message={
                <div style={{ display: 'flex' }}>
                    {isSuccess ? (
                        <CheckCircleIcon
                            sx={{
                                marginRight: '8px',
                                color: '#4CAF50',
                            }}
                        />
                    ) : (
                        <CancelIcon sx={{ marginRight: '8px', color: 'red' }} />
                    )}
                    <div>
                        <div style={{ fontSize: '16px' }}>
                            {isSuccess ? 'SUCCESS' : 'ERROR'}
                        </div>
                        <div style={{ fontSize: '14px' }}>{`${
                            message === (undefined || 'undefined')
                                ? 'Not found data'
                                : message
                        }. Please try again later.`}</div>
                    </div>
                    <IconButton
                        size='small'
                        aria-label='close'
                        color='inherit'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                        }}
                    >
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </div>
            }
        />
    )
}
