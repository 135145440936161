import { CardMedia, Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { urlConfig } from '../../../../../config/apiConfig'
import axiosInstance from '../../../../../config/axiosConfig'
import dayjs from 'dayjs'
import { AlertContext } from '../../../../../context/AlertContext'
import SettingsIcon from '@mui/icons-material/Settings'
import CustomModal from '../../../../common/popup/CustomModal'
import ActionButtons from '../../../../common/popup/ActionButtons'
import TextFieldInput from '../../../../common/input/TextFieldInput'

export default function DeviceDetailCard({ data, handleAlert, user }) {
    const [gatewayDetail, setGatewayDetail] = useState(null)
    const { handleOpen } = useContext(AlertContext)
    const [open, setOpen] = useState(false)
    const [hasSettingLog, setHasSettingLog] = useState({
        data: null,
        has: false,
    })
    const [input, setInput] = useState({
        time: '',
    })

    const handleGetGatewayById = async () => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.gateway}/${data.gateway_id}`,
            )
            if (res.data.status === 200) {
                setGatewayDetail(res.data.message)
            }
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }

    const getSettingHistory = async () => {
        try {
            const body = {
                time: input.time,
            }
            const res = await axiosInstance.get(
                `${urlConfig.settingHistoryLog}/${data.device_id}`,
                body,
            )
            if (res.data.status === 200) {
                // console.log('res:', res.data.message)
                setHasSettingLog({ data: res.data.message, has: true })
            } else {
                setHasSettingLog({ data: null, has: false })
            }
        } catch (error) {
            setHasSettingLog({ data: null, has: false })
            // const err = error.response?.data?.message
            // handleAlert(`Failed to get setting history log. ${err}`, 'error')
        }
    }

    const handleSaveSettingHistoryLog = async () => {
        try {
            const body = {
                time: input.time,
            }

            const method = hasSettingLog.has ? 'put' : 'post'
            const url = `${urlConfig.settingHistoryLog}/${data.device_id}`

            const res = await axiosInstance[method](url, body)

            if (res.data.status === 201 || res.data.status === 200) {
                handleAlert(
                    'Setting History Log saved successfully.',
                    'success',
                )
                setOpen(false)
                getSettingHistory()
            }
        } catch (error) {
            const err = error.response?.data?.message
            handleAlert(`Failed to save setting history log. ${err}`, 'error')
        }
    }

    useEffect(() => {
        handleGetGatewayById()
        getSettingHistory()
    }, [])

    useEffect(() => {
        // console.log('setting log:', hasSettingLog)
        if (hasSettingLog) setInput({ time: hasSettingLog?.data?.time })
    }, [hasSettingLog])

    return (
        <>
            <Grid container sx={{ position: 'relative' }}>
                {!user && (
                    <SettingsIcon
                        onClick={() => setOpen(true)}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            cursor: 'pointer',
                        }}
                    />
                )}
                <Typography variant='h6' textAlign={'left'} mb={1}>
                    Device detail
                </Typography>
                <Grid container gap={1}>
                    <Grid item xs={12} md={4} sm={5}>
                        <CardMedia
                            component='img'
                            image={
                                data.image
                                    ? `${urlConfig.baseUrlAPI}/api/v1${data.image}`
                                    : 'https://t4.ftcdn.net/jpg/04/73/25/49/360_F_473254957_bxG9yf4ly7OBO5I0O5KABlN930GwaMQz.jpg'
                            }
                            width={'100%'}
                            sx={{ borderRadius: 2 }}
                            alt='Device Image'
                        />
                    </Grid>
                    <Grid item flex={1} xs={12} sm={6} md={7}>
                        <Typography variant='body2' fontWeight={'bold'}>
                            Device Type:{' '}
                            <Typography
                                variant='body2'
                                sx={{ display: 'inline' }}
                            >
                                {data.device_type}
                            </Typography>
                        </Typography>
                        <Typography variant='body2' fontWeight={'bold'}>
                            ID Name:{' '}
                            <Typography
                                variant='body2'
                                sx={{ display: 'inline' }}
                            >
                                {data.device_id}
                            </Typography>
                        </Typography>
                        <Typography variant='body2' fontWeight={'bold'}>
                            S/N:{' '}
                            <Typography
                                variant='body2'
                                sx={{ display: 'inline' }}
                            >
                                {/* {gatewayDetail && gatewayDetail.s_n} */}
                                {data && data.s_n}
                            </Typography>
                        </Typography>
                        <Typography variant='body2' fontWeight={'bold'}>
                            Location:{' '}
                            <Typography
                                variant='body2'
                                sx={{ display: 'inline' }}
                            >
                                {gatewayDetail && gatewayDetail.description}
                            </Typography>
                        </Typography>
                        <Typography variant='body2' fontWeight={'bold'}>
                            Gateway:{' '}
                            <Typography
                                variant='body2'
                                sx={{ display: 'inline' }}
                            >
                                {gatewayDetail && gatewayDetail.name}
                            </Typography>
                        </Typography>
                        <Typography variant='body2' fontWeight={'bold'}>
                            Last update:{' '}
                            <Typography
                                variant='body2'
                                sx={{ display: 'inline' }}
                            >
                                {dayjs(data.updatedAt).format(
                                    'DD/MM/YYYY, HH:mm:ss',
                                )}
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <CustomModal
                size='sm'
                open={open}
                setOpen={setOpen}
                handleClose={() => {
                    setOpen(false)
                    getSettingHistory()
                }}
            >
                <>
                    <Typography variant='h5'>
                        {hasSettingLog.has ? 'Edit' : 'Setting'} device
                    </Typography>
                    <Typography mt={2}>
                        History log interval (seconds)
                    </Typography>
                    <TextFieldInput
                        state={input}
                        setState={setInput}
                        name='time'
                        size='small'
                        isNumber={true}
                    />

                    <ActionButtons
                        canSave={Number(input.time) > 0}
                        onSave={handleSaveSettingHistoryLog}
                        onCancel={() => {
                            setOpen(false)
                            getSettingHistory()
                        }}
                    />
                </>
            </CustomModal>
        </>
    )
}
