import { FormControlLabel, Switch } from '@mui/material'

export default function IOSSwitchInputNormal({ setState, name, status }) {
    const isChecked = status || false

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={isChecked}
                    onChange={(e) => {
                        setState((prev) => ({
                            ...prev,
                            [name]: e.target.checked,
                        }))
                    }}
                    color='success'
                />
            }
        />
    )
}
