import { Grid } from '@mui/material'
import DeviceHeader from './DeviceHeader'
import { AlertPopup, Container } from '../../StyleComponent'
import { useContext, useEffect, useState } from 'react'
import { urlConfig } from '../../../config/apiConfig'
import axiosInstance from '../../../config/axiosConfig'
import CustomModal from '../../common/popup/CustomModal'
import DeviceTypeForm from './DeviceTypeForm'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModalDelete from '../../ModalDelete'
import TuneIcon from '@mui/icons-material/Tune'
import columnConfig from '../../../config/columnsConfig'
import TableComponents from '../../TableComponents'
import { AlertContext } from '../../../context/AlertContext'

const initialState = {
    name: '',
    key_type_id: '',
    deviceTypeParameter: [
        {
            parameter: 1,
            name: '',
            key: '',
            unit: '',
            status: false,
        },
    ],
    type_alert: 'Remain state',
}

export default function DeviceContainer({ create, update, remove }) {
    const [data, setData] = useState('')
    const [row, setRow] = useState('')
    const [filterData, setFilterData] = useState([])
    const [searchText, setSearchText] = useState('')
    const [loading, setLoading] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [selectRow, setSelectRow] = useState(null)
    const [anchorEl, setAnchorEl] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [input, setInput] = useState(initialState)
    const [imageUpload, setImageUpload] = useState(null)
    const [message, setMessage] = useState('')
    const [err, setErr] = useState(false)
    const { handleOpen } = useContext(AlertContext)

    const canClickNext =
        input.name.trim() !== '' &&
        input.key_type_id.trim() !== '' &&
        input.deviceTypeParameter.every(
            (param) => param.name.trim() !== '' && param.key.trim() !== '',
        ) &&
        err === false

    const handleGetAllDeviceType = async () => {
        setData('')
        setLoading(true)
        try {
            const res = await axiosInstance.get(`${urlConfig.deviceType}`)
            const sortedData = res.data?.message?.map((item, index) => ({
                ...item,
                deviceTypeParameter: item.deviceTypeParameter.sort(
                    (a, b) => a.parameter - b.parameter,
                ),
                index: index + 1,
            }))
            setData(sortedData)
            setFilterData(res.data.message)
            setLoading(false)
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            setLoading(false)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }

    const handleSearch = () => {
        const filteredData = data
            .filter((item) => {
                const matchesSearchText =
                    !searchText ||
                    item.name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1
                return matchesSearchText
            })
            .map((item, index) => ({
                ...item,
                id: index + 1,
            }))
        setFilterData(filteredData)
    }

    const handleClickMore = (data, isDelete) => {
        if (isDelete) {
            setOpenDelete(true)
        } else {
            setInput((prev) => ({
                ...prev,
                name: data.name,
                key_type_id: data.key_type_id,
                deviceTypeParameter: data.deviceTypeParameter,
                type_alert: data.type_alert,
            }))
            setOpenEdit(true)
        }
        setSelectRow(data)
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.delete(
                `${urlConfig.deviceType}/${selectRow?.key_type_id}`,
            )
            if (res.status === 204) {
                setIsSuccess(true)
                setAnchorEl(true)
                handleGetAllDeviceType()
                setMessage('Delete device type')
            }
        } catch (error) {
            setAnchorEl(true)
            setIsSuccess(false)
            setMessage(
                `Can not delete device type. ${error.response.data?.message}.`,
            )
        } finally {
            setLoading(false)
            setOpenDelete(false)
        }
    }

    const handleSubmitEdit = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.put(
                `${urlConfig.deviceType}/${selectRow.key_type_id}`,
                input,
            )
            if (res.status !== 200) {
                throw new Error('Failed to create device type')
            }

            if (imageUpload) {
                const formData = new FormData()
                formData.append('image', imageUpload)
                const uploadImage = await axiosInstance.post(
                    `${urlConfig.deviceType}/upload/${selectRow.key_type_id}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )
                if (uploadImage.status !== 200) {
                    throw new Error('Failed to upload image')
                }
            }

            handleGetAllDeviceType()
            setIsSuccess(true)
            setOpenEdit(false)
            setAnchorEl(true)
        } catch (error) {
            console.log('Error:', error)
            setIsSuccess(false)
            setAnchorEl(true)
            // setOpenEdit(false)
        } finally {
            // setOpenEdit(false)
            // setInput(initialState)
            setLoading(false)
            setMessage('Update device type')
        }
    }

    const handleCloseUploadImage = () => {
        setInput(initialState)
    }

    useEffect(() => {
        handleGetAllDeviceType()
    }, [])

    const columns = columnConfig('device')

    const listMenu = []
    if (update) {
        listMenu.push({
            title: 'Edit',
            icon: <EditOutlinedIcon color='warning' />,
            action: () => handleClickMore(row, false),
        })
    }

    if (remove) {
        listMenu.push({
            title: 'Delete',
            icon: <DeleteOutlineOutlinedIcon color='error' />,
            action: () => handleClickMore(row, true),
        })
    }
    return (
        <Container header={header}>
            <Grid container gap={0}>
                <DeviceHeader
                    onFetch={handleGetAllDeviceType}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    onSearch={handleSearch}
                    openAdd={openAdd}
                    setOpenAdd={setOpenAdd}
                />
                <Grid item xs={12}>
                    <TableComponents
                        refresh={handleGetAllDeviceType}
                        disabledButton={create}
                        button={{
                            title: 'ADD DEVICE',
                            action: () => setOpenAdd(true),
                        }}
                        columns={columns}
                        data={data}
                        MenuList={listMenu}
                        setAction={setRow}
                    />
                </Grid>
            </Grid>
            <CustomModal
                open={openEdit}
                setOpen={setOpenEdit}
                handleClose={handleCloseUploadImage}
                loading={loading}
            >
                <DeviceTypeForm
                    input={input}
                    setInput={setInput}
                    setImageUpload={setImageUpload}
                    handleSave={handleSubmitEdit}
                    isEdit={true}
                    imagePath={selectRow?.image}
                    setOpen={setOpenEdit}
                    canSave={canClickNext}
                    setErr={setErr}
                />
            </CustomModal>
            <ModalDelete
                disabled={loading}
                onSubmit={handleSubmit}
                open={openDelete}
                setOpen={setOpenDelete}
                message={selectRow?.name}
            />
            <AlertPopup
                open={anchorEl}
                setOpen={setAnchorEl}
                message={{
                    status: isSuccess ? 'success' : 'error',
                    name: message,
                }}
            />
        </Container>
    )
}
const header = {
    icon: <TuneIcon sx={{ color: 'white' }}></TuneIcon>,
    color: 'grey',
    title: 'Devices',
}
