import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export default function DropdownInput({
    state,
    setState,
    options,
    borderRadius,
    name,
    isSearch,
    title
}) {
    const handleChange = (event) => {
        if (name) {
            setState((prev) => ({ ...prev, [name]: event.target.value }))
        } else {
            setState(event.target.value)
        }
    }

    return (
        <FormControl fullWidth size='small'>
            <InputLabel id='demo-simple-select-label'>{title}</InputLabel>
            <Select
                value={state}
                labelId='demo-simple-select-label'
                onChange={handleChange}
                sx={{
                    borderRadius: borderRadius || 10,
                    height: isSearch && '35px',
                }}
                label={title}
            >
                {options &&
                    options.map((item) => (
                        <MenuItem key={item.id} value={item.value}>
                            {item.value}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}
