import {
    Button,
    Grid,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { useContext, useEffect, useState } from 'react'
import CustomModal from '../../../../common/popup/CustomModal'
import PopOver from '../../../../common/popup/PopOver'
import ActionButtons from '../../../../common/popup/ActionButtons'
import NotificationForm from './Notification/NotificationForm'
import LoadingBackdrop from '../../../../common/LoadingBackdrop'
import { urlConfig } from '../../../../../config/apiConfig'
import axiosInstance from '../../../../../config/axiosConfig'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModalDelete from '../../../../ModalDelete'
import { useParams } from 'react-router-dom'
import { AlertContext } from '../../../../../context/AlertContext'

const initialState = {
    typeAlert: 'Remain state',
    desc: '',
    lineEnable: true,
    lineToken: '',
    emailEnable: true,
    emailAddress: '',
    remain_connector: 'no',
    remain_parameter_1: '',
    remain_condition_1: '',
    remain_set_point_1: '',
    remain_set_point_1_name: '',
    trigger_parameter: '',
    trigger_time_interval: '',
    name: '',
}

export default function NotificationSetting({
    deviceId,
    dataItem,
    user,
    create,
    update,
    remove,
}) {
    const [data, setData] = useState([])
    const [openAdd, setOpenAdd] = useState(false)
    const [anchorEl, setAnchorEl] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [input, setInput] = useState(initialState)
    const [selectedRow, setSelectedRow] = useState(null)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(null)
    const [messageText, setMessageText] = useState('')
    const [anchorElMenu, setAnchorElMenu] = useState(null)
    const { organizationId } = useParams()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const { handleOpen } = useContext(AlertContext)

    const [deviceDetail, setDeviceDetail] = useState(null)

    const handleDeviceDetail = async () => {
        try {
            const resp = await axiosInstance.get(
                `${urlConfig.deviceIot}/id/${deviceId}`,
            )
            if (resp.data.status === 200) {
                setDeviceDetail(resp.data.message)
            }
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            setDeviceDetail([])
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }

    useEffect(() => {
        if (deviceId) handleDeviceDetail()
    }, [])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const isAllInputFilled = () => {
        // const {
        //     remain_connector,
        //     remain_parameter_1,
        //     remain_condition_1,
        //     remain_set_point_1,
        //     trigger_parameter,
        //     trigger_time_interval,
        // } = input

        // if (input.typeAlert === 'Trigger state') {
        //     return (
        //         trigger_parameter.trim() !== '' &&
        //         trigger_time_interval.trim() !== ''
        //     )
        // } else {
        //     return (
        //         remain_connector.trim() !== '' &&
        //         remain_parameter_1.trim() !== '' &&
        //         remain_condition_1.trim() !== '' &&
        //         remain_set_point_1.trim() !== ''
        //     )
        // }
        return true
    }

    const handleMenuOpen = (event, row) => {
        setAnchorElMenu(event.currentTarget)
        setSelectedRow(row)
    }

    const handleGetAllNotificationSetting = async () => {
        setLoading(true)
        try {
            const resp = await axiosInstance.get(
                `${urlConfig.notificationSetting}/keyName/${organizationId}`,
            )
            if (resp.data.status === 200) {
                const newData = resp.data.message
                    ?.filter((item) => item.device_id === deviceId)
                    .map((item, index) => ({
                        id: index + 1,
                        ...item,
                    }))
                setData(newData)
            }
            setLoading(false)
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
            setLoading(false)
            setData([])
        }
    }

    const handleOpenNotificationSetting = () => {
        setOpenAdd(true)
        setInput((prev) => ({ ...prev, typeAlert: deviceDetail?.type_alert }))
    }

    const handleCancel = () => {
        setOpenAdd(false)
        setInput(initialState)
        setOpenEdit(false)
    }

    const handleMoreClick = (data, isDelete) => {
        // console.log('----------------')
        // console.log('data', data)
        // console.log('set point 1', data.remain_set_point_1)
        // console.log('set point 1 name', data.remain_set_point_1_name)
        // console.log('set point 2', data.remain_set_point_2)
        // console.log('set point 2 name', data.remain_set_point_2_name)
        // console.log('type alert', data.typeAlert, data.device?.deviceType.type_alert)
        // console.log('----------------')
        if (isDelete) {
            setOpenDelete(true)
        } else {
            setInput((prev) => ({
                ...prev,
                desc: data.description,
                name: data.name,
                emailAddress: data.email_message,
                emailEnable: data.email_enable,
                lineToken: data.line_message,
                lineEnable: data.line_enable,
                typeAlert: deviceDetail?.type_alert,
                trigger_time_interval:
                    data.type_alert === 'Trigger state' ||
                    data.device?.deviceType.type_alert === 'Trigger state'
                        ? data.trigger_time_interval
                        : null,
                trigger_parameter:
                    data.type_alert === 'Trigger state' ||
                    data.device?.deviceType.type_alert === 'Trigger state'
                        ? data.trigger_parameter
                        : null,
                remain_connector:
                    data?.type_alert === 'Remain state' ||
                    data.device?.deviceType.type_alert === 'Remain state'
                        ? data.remain_connector
                        : 'no',
                remain_parameter_1:
                    data.type_alert === 'Remain state' ||
                    data.device?.deviceType.type_alert === 'Remain state'
                        ? data.remain_parameter_1
                        : null,
                remain_condition_1:
                    data?.type_alert === 'Remain state' ||
                    data.device?.deviceType.type_alert === 'Remain state'
                        ? data.remain_condition_1
                        : null,
                remain_set_point_1:
                    data.device?.deviceType.type_alert === 'Remain state' &&
                    data.remain_set_point_1 !== undefined &&
                    data.remain_set_point_1 !== null
                        ? data.remain_set_point_1
                        : null,
                remain_set_point_1_name:
                    data.type_alert === 'Remain state' ||
                    data.device?.deviceType.type_alert === 'Remain state'
                        ? data.remain_set_point_1_name
                        : null,
                remain_parameter_2:
                    data.type_alert === 'Remain state' ||
                    data.device?.deviceType.type_alert === 'Remain state'
                        ? data.remain_parameter_2
                        : null,
                remain_condition_2:
                    data.type_alert === 'Remain state' ||
                    data.device?.deviceType.type_alert === 'Remain state'
                        ? data.remain_condition_2
                        : null,
                remain_set_point_2:
                    data.device?.deviceType.type_alert === 'Remain state' &&
                    data.remain_set_point_2 !== undefined &&
                    data.remain_set_point_2 !== null
                        ? data.remain_set_point_2
                        : null,
                remain_set_point_2_name:
                    data.type_alert === 'Remain state' ||
                    data.device?.deviceType.type_alert === 'Remain state'
                        ? data.remain_set_point_2_name
                        : null,
            }))
            setOpenEdit(true)
        }
        setSelectedRow(data)
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.delete(
                `${urlConfig.notificationSetting}/${selectedRow?.notification_setting_id}`,
            )
            if (res.status === 204) {
                setIsSuccess(true)
                setAnchorEl(true)
                setInput(initialState)
                handleGetAllNotificationSetting()
            }
        } catch (error) {
            setAnchorEl(true)
            setIsSuccess(false)
        } finally {
            setLoading(false)
            setOpenDelete(false)
            setMessageText('Delete notification setting')
        }
    }

    const handleSaveOrEdit = async (isEdit) => {
        const commonPayload = {
            device_id: deviceId,
            description: input.desc,
            line_enable: input.lineEnable,
            line_message: input.lineToken,
            email_enable: input.emailEnable,
            email_message: input.emailAddress,
            name: input.name,
        }

        const triggerPayload = {
            trigger_time_interval: input.trigger_time_interval,
            trigger_parameter: input.trigger_parameter,
        }

        const remainPayload = {
            remain_connector: input.remain_connector,
            remain_parameter_1: input.remain_parameter_1,
            remain_condition_1: input.remain_condition_1,
            ...(input.remain_set_point_1 !== null && {
                remain_set_point_1: input.remain_set_point_1,
                ...(input.remain_set_point_1_name === null && {
                    remain_set_point_1_name: undefined,
                }),
            }),
            ...(input.remain_set_point_1_name !== null && {
                remain_set_point_1_name: input.remain_set_point_1_name,
                ...(input.remain_set_point_1 === null && {
                    remain_set_point_1: undefined,
                }),
            }),
            ...(input.remain_connector !== 'no' && {
                remain_parameter_2: input.remain_parameter_2,
                remain_condition_2: input.remain_condition_2,
                ...(input.remain_set_point_2 !== null && {
                    remain_set_point_2: input.remain_set_point_2,
                    ...(input.remain_set_point_2_name === null && {
                        remain_set_point_2_name: undefined,
                    }),
                }),
                ...(input.remain_set_point_2_name !== null && {
                    remain_set_point_2_name: input.remain_set_point_2_name,
                    ...(input.remain_set_point_2 === null && {
                        remain_set_point_2: undefined,
                    }),
                }),
            }),
        }

        Object.keys(remainPayload).forEach(
            (key) =>
                (remainPayload[key] === undefined ||
                    remainPayload[key] === null) &&
                delete remainPayload[key],
        )

        const stateSpecificPayload =
            deviceDetail?.type_alert === 'Trigger state'
                ? triggerPayload
                : remainPayload

        const payload = {
            ...commonPayload,
            ...stateSpecificPayload,
        }

        setLoading(true)
        try {
            let resp
            if (isEdit) {
                resp = await axiosInstance.put(
                    `${urlConfig.notificationSetting}/${selectedRow?.notification_setting_id}`,
                    payload,
                )
            } else {
                resp = await axiosInstance.post(
                    `${urlConfig.notificationSetting}`,
                    payload,
                )
            }

            if (resp.status === (isEdit ? 200 : 201)) {
                setIsSuccess(true)
                handleGetAllNotificationSetting()
                setOpenAdd(false)
                setOpenEdit(false)
                setAnchorEl(true)
                setInput(initialState)
            }
        } catch (error) {
            console.log('Error:', error)
            setIsSuccess(false)
            setAnchorEl(true)
        } finally {
            setLoading(false)
            setMessageText(
                isEdit
                    ? 'Update notification setting'
                    : 'Add new notification setting',
            )
        }
    }

    const listMenu = []

    if (update) {
        listMenu.push({
            title: 'Edit',
            icon: <EditOutlinedIcon color='warning' />,
            action: () => handleMoreClick(selectedRow, false),
        })
    }

    if (remove) {
        listMenu.push({
            title: 'Delete',
            icon: <DeleteOutlineOutlinedIcon color='error' />,
            action: () => handleMoreClick(selectedRow, true),
        })
    }

    const paginatedData = data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    )

    useEffect(() => {
        handleGetAllNotificationSetting()
    }, [])

    // useEffect(() => {
    //     console.log('-------input---------', input)
    // }, [input])

    return (
        <Grid container justifyContent='space-between' gap={1}>
            <LoadingBackdrop loading={loading} />
            <Typography variant='h5'>Notification Setting</Typography>
            <Button
                startIcon={<AddRoundedIcon />}
                variant='contained'
                color='secondary'
                sx={{
                    textTransform: 'uppercase',
                    borderRadius: '30px',
                    height: '30px',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => handleOpenNotificationSetting()}
                disabled={!create}
            >
                Add
            </Button>
            {/* {!user && paginatedData.length <= 0 && (
            )} */}
            <Grid container>
                <Paper
                    sx={{
                        overflow: 'auto',
                        width: '100%',
                    }}
                >
                    <TableContainer
                        component={Paper}
                        sx={{
                            boxShadow: 0,
                            borderBottom: '1px solid #ccc',
                            borderRadius: 0,
                        }}
                    >
                        <Table sx={{}}>
                            <TableHead
                                sx={{
                                    bgcolor: '#f5f5f5',
                                    '& .MuiTableCell-root': {
                                        fontWeight: 'bold',
                                        color: 'info.contrastText',
                                    },
                                }}
                            >
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Notification Name</TableCell>
                                    <TableCell>Condition</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Line</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>More</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData?.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:hover': {
                                                background: '#FFCCCE',
                                            },
                                            '& .MuiTableCell-root': {
                                                boxShadow: 0,
                                                borderBottom:
                                                    index ===
                                                    paginatedData.length - 1
                                                        ? '1px solid transparent'
                                                        : '',
                                            },
                                        }}
                                    >
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>
                                            <Stack
                                                display='flex'
                                                justifyContent={'center'}
                                                sx={{ height: '100%' }}
                                            >
                                                <Typography variant='body2'>
                                                    {row.type_alert ===
                                                        'Trigger state' ||
                                                    row.device?.deviceType
                                                        .type_alert ===
                                                        'Trigger state'
                                                        ? `${row.trigger_time_interval} seconds.`
                                                        : `1. ${
                                                              row.remain_parameter_1
                                                          } ${
                                                              row.remain_condition_1
                                                          } ${
                                                              row.remain_set_point_1 ||
                                                              row.remain_set_point_1_name
                                                          }`}
                                                </Typography>
                                                {row.type_alert ===
                                                    'Remain state' ||
                                                    (row.device?.deviceType
                                                        .type_alert ===
                                                        'Remain state' &&
                                                        row.remain_connector !==
                                                            'no' && (
                                                            <Typography variant='body2'>
                                                                2.
                                                                {
                                                                    row.remain_parameter_2
                                                                }{' '}
                                                                {
                                                                    row.remain_condition_2
                                                                }{' '}
                                                                {row.remain_set_point_2 ||
                                                                    row.remain_set_point_2_name}
                                                            </Typography>
                                                        ))}
                                            </Stack>
                                        </TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>
                                            {row.device?.deviceType.type_alert}
                                        </TableCell>
                                        <TableCell>
                                            {row.line_enable ? 'True' : 'False'}
                                        </TableCell>
                                        <TableCell>
                                            {row.email_enable
                                                ? 'True'
                                                : 'False'}
                                        </TableCell>
                                        {listMenu[0] && (
                                            <TableCell>
                                                <Stack
                                                    display='flex'
                                                    justifyContent={'center'}
                                                    sx={{ height: '100%' }}
                                                >
                                                    <MoreVertIcon
                                                        onClick={(event) =>
                                                            handleMenuOpen(
                                                                event,
                                                                row,
                                                            )
                                                        }
                                                        sx={{
                                                            cursor: 'pointer',
                                                            color: 'info.darkest',
                                                        }}
                                                    />
                                                    <Menu
                                                        anchorEl={anchorElMenu}
                                                        open={Boolean(
                                                            anchorElMenu,
                                                        )}
                                                        onClose={() =>
                                                            setAnchorElMenu(
                                                                null,
                                                            )
                                                        }
                                                    >
                                                        {listMenu?.map(
                                                            (menu, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setAnchorElMenu(
                                                                            null,
                                                                        )
                                                                        menu.action(
                                                                            row,
                                                                        )
                                                                    }}
                                                                >
                                                                    <ListItemIcon>
                                                                        {
                                                                            menu.icon
                                                                        }
                                                                    </ListItemIcon>
                                                                    {menu.title}
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </Menu>
                                                </Stack>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            '.MuiSelect-select': {
                                padding: '4px 8px',
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                '&:focus': {
                                    borderRadius: '5px',
                                    backgroundColor: '#fff',
                                },
                            },
                            '.MuiTablePagination-actions .MuiButtonBase-root': {
                                padding: '0px',
                                margin: '0 4px',
                                backgroundColor: '#fff',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                },
                            },
                        }}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component='div'
                        count={data.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage=''
                    />
                </Paper>
            </Grid>
            <CustomModal
                open={openAdd}
                setOpen={setOpenAdd}
                loading={loading}
                handleClose={handleCancel}
            >
                <NotificationForm
                    input={input}
                    setInput={setInput}
                    parameterOptions={dataItem.deviceTypeParameter}
                />
                <ActionButtons
                    canSave={isAllInputFilled()}
                    onSave={() => handleSaveOrEdit(false)}
                    onCancel={handleCancel}
                />
            </CustomModal>
            <CustomModal
                open={openEdit}
                setOpen={setOpenEdit}
                loading={loading}
                handleClose={handleCancel}
            >
                <NotificationForm
                    isEdit={true}
                    input={input}
                    setInput={setInput}
                    parameterOptions={dataItem.deviceTypeParameter}
                    keyTypeId={selectedRow?.key_type_id}
                />
                <ActionButtons
                    canSave={isAllInputFilled()}
                    onSave={() => handleSaveOrEdit(true)}
                    onCancel={handleCancel}
                />
            </CustomModal>
            <PopOver
                isSuccess={isSuccess}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                message={messageText}
            />
            <ModalDelete
                disabled={loading}
                onSubmit={handleSubmit}
                open={openDelete}
                setOpen={setOpenDelete}
                message={selectedRow?.notification_setting_id}
            />
        </Grid>
    )
}
