import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingBackdrop({ loading }) {
    return (
        <React.Fragment>
            <Backdrop
                sx={{
                    color: 'primary.lightest',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" size={50} />
            </Backdrop>
        </React.Fragment>
    )
}
