import { Grid, Paper, Stack, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
// import DataGridTable from '../../../../common/DataGridTable'
// import dayjs from 'dayjs'
import { urlConfig } from '../../../../../config/apiConfig'
import axiosInstance from '../../../../../config/axiosConfig'
import { AlertContext } from '../../../../../context/AlertContext'
import TableComponents from '../../../../TableComponents'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import moment from 'moment-timezone'

export default function AlarmRealTime({
    deviceId,
    deviceData,
    typeAlarm,
    connectedWB,
}) {
    const [data, setData] = useState('')
    const [dataType, setDataType] = useState('')
    const { handleOpen } = useContext(AlertContext)
    const localTimezone = localStorage.getItem('timezone')

    const labelStyle = 'original'
    const timezones = {
        ...allTimezones,
        'Europe/Berlin': 'Frankfurt',
    }
    const { options } = useTimezoneSelect({
        labelStyle,
        timezones,
    })

    const timezoneOffsets = options.reduce((acc, option) => {
        acc[option.value] = option.offset * 60
        return acc
    }, {})

    const formatZone = (isoString, timezone) => {
        if (isoString) {
            const offset = timezoneOffsets[timezone] || 0
            const date = moment.utc(isoString)
            date.add(offset, 'minutes')
            return date.format('DD/MM/YYYY HH:mm:ss')
        }
        return null
    }
    const column2 = [
        {
            id: 'id',
            label: 'No.',
            width: '5%',
            sortable: true,
            align: 'left',
        },
        {
            id: 'timeuse',
            label: 'timetamp',
            width: '10%',
            sortable: true,
            align: 'left',
        },
        {
            id: 'device_id',
            label: 'ID Name',
            width: '20%',
            sortable: true,
            align: 'left',
        },
        {
            id: 'name_notification_setting',
            label: 'Notification Name',
            width: '20%',
            sortable: true,
            align: 'left',
        },

        {
            id: 'description',
            label: 'messDescriptionage',
            width: '10%',
            sortable: true,
            align: 'left',
        },
        {
            id: 'area',
            label: 'Area',
            width: '10%',
            sortable: true,
            align: 'left',
        },
    ]

    const handleGetRealTimeLog = useCallback(async () => {
        console.log('req alrm')
        try {
            const res = await axiosInstance.get(
                `${urlConfig.alarmLog}/${deviceId}`,
            )
            const resData = res.data.message
            const dataWithArea = resData.map((item, index) => ({
                id: index + 1,
                ...item,
                area: deviceData?.description,
                timeuse: formatZone(item.createdAt, localTimezone),
            }))
            setData(dataWithArea?.slice(0, 5))
            handleOpen({ open: false })
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [deviceId, deviceData, handleOpen])

    const handleWebSocketData = useCallback(() => {
        if (typeAlarm.device_id !== deviceId || !typeAlarm.data) return
        const dataWithArea = typeAlarm.data.map((item, index) => ({
            id: index + 1,
            ...item,
            area: deviceData?.description,
            timeuse: formatZone(item.createdAt, localTimezone),
        }))
        setDataType(dataWithArea.slice(0, 5))
    }, [typeAlarm, deviceData, deviceId])

    useEffect(() => {
        if (connectedWB && typeAlarm.device_id === deviceId) {
            handleWebSocketData()
        }
    }, [handleWebSocketData])

    useEffect(() => {
        if (!connectedWB || !dataType[0]) {
            handleGetRealTimeLog()
            const intervalId = setInterval(handleGetRealTimeLog, 2000)
            return () => {
                clearInterval(intervalId)
            }
        }
    }, [connectedWB])

    // useEffect(() => {
    //     const intervalId = setInterval(handleGetRealTimeLog, 1000)
    //     return () => {
    //         clearInterval(intervalId)
    //     }
    // }, [deviceId, deviceData, setData, handleOpen])

    return (
        <Grid container>
            <Typography mb={1} variant='h5'>
                Alarm Real-Time
            </Typography>

            {/* <DataGridTable
                    columns={columns}
                    data={data}
                    isHidePagination={true}
                    isNoRowText={true}
                /> */}

            <Grid item xs={12}>
                <TableComponents
                    noPadding={true}
                    disableSearch={true}
                    data={dataType[0] ? dataType : data}
                    columns={column2}
                />
            </Grid>
        </Grid>
    )
}
