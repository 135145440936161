export const palette = {
    primary: {
        lightest: '#fff',
        light: '#E8E8E8',
        main: '#A81E1A',
        dark: '#8C0400',
        // dark: '#3D3D3D',
        darkest: '#1b5e20',
        contrastText: '#fff',
    },
    secondary: {
        contrastText: '#E5E7FF',
        main: '#0C58AD',
        light: '#000572',
        lightest: '#FAFAFA',
        darkest: '#B1B2B2',
    },
    buttonSave: {
        main: '#0C58AD',
        contrastText: 'white',
    },
    buttonCancel: {
        main: '#FDA655',
        contrastText: 'white',
    },
    button: {
        main: '#0C58AD',
        contrastText: 'white',
    },
    info: {
        lightest: '#ECFDFF',
        light: '#3073BE',
        main: '#06AED4',
        dark: '#164C63',
        darkest: '#868686',
        contrastText: '#000',
    },
    warning: {
        lightest: '#ffee58',
        light: '#ffec3f',
        main: '#FFC700',
        dark: '#DAA520',
        darkest: '#f1d900',
        contrastText: '#FFFFFF',
    },
    success: {
        lightest: '#F0FDF9',
        light: '#FFFFFF',
        main: '#00B533',
        dark: '#73be30',
        darkest: '#10B981',
        contrastText: '#FFFFFF',
    },
    text: {
        primary: '#000000',
        secondary: '#5C7080',
        disabled: '#9999',
    },
    background: {
        default: '#fff',
        paper: '#fff',
    },
    error: {
        lightest: '#FEF3F2',
        light: '#FEE4E2',
        main: '#DA1C23',
        dark: '#8C0400',
        darkest: '#8C0400',
        contrastText: '#FEF3F2',
    },
}
