import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

const TabContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 15px;
    font-size: 14px;
`

const Tab = styled.div`
    cursor: pointer;
    padding: 10px 20px;
    color: ${({ $active }) => ($active ? '#A81E1A' : 'black')};
    background: ${({ $active }) => ($active ? '#F7F6F6' : 'none')};
    transition: color 0.3s;
`

const Slider = styled.div`
    position: absolute;
    bottom: 0;
    height: 4px;
    width: ${({ $width }) => $width}px;
    background-color: #a81e1a;
    transition: left 0.3s, width 0.3s;
    border-radius: 10px;
    left: ${({ $left }) => $left}px;
`

const Tabs = ({ activeTab, setActiveTab }) => {
    const [sliderProps, setSliderProps] = useState({ left: 0, width: 0 })
    const tabsRef = useRef([])

    const tabs = ['DAY', 'MONTH', 'YEAR']

    useEffect(() => {
        const activeTabElement = tabsRef.current[tabs.indexOf(activeTab)]
        if (activeTabElement) {
            setSliderProps({
                left: activeTabElement.offsetLeft,
                width: activeTabElement.clientWidth,
            })
        }
    }, [activeTab])

    return (
        <TabContainer>
            {tabs.map((tab, index) => (
                <Tab
                    key={tab}
                    $active={activeTab === tab}
                    onClick={() => setActiveTab(tab)}
                    ref={(el) => (tabsRef.current[index] = el)}
                >
                    {tab}
                </Tab>
            ))}
            <Slider $left={sliderProps.left} $width={sliderProps.width} />
        </TabContainer>
    )
}

export default Tabs
