import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import DetailContainer from './DeviceDetail/DetailContainer'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { urlConfig } from '../../../../config/apiConfig'
import axiosInstance from '../../../../config/axiosConfig'
import CustomModal from '../../../common/popup/CustomModal'
import PopOver from '../../../common/popup/PopOver'
import DeviceIotForm from './DeviceIotForm'
import ActionButtons from '../../../common/popup/ActionButtons'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModalDelete from '../../../ModalDelete'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import { Container } from '../../../StyleComponent'
import { useAuth } from '../../../../hook/useAuth'
import TableComponents from '../../../TableComponents'
import columnConfig from '../../../../config/columnsConfig'
import moment from 'moment-timezone'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import { AlertContext } from '../../../../context/AlertContext'

const initialInput = {
    enable: true,
    keyTypeId: '',
    gatewayId: '',
    desc: '',
    s_n: '',
}

export default function UserDeviceIOT({ org, typeAlarm, connectedWB, typeDevice }) {
    const [data, setData] = useState('')
    const [filterData, setFilterData] = useState('')
    const [loading, setLoading] = useState(false)
    const [rowClick, setRowClick] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [deviceTypeSelect, setDeviceTypeSelect] = useState('')
    const [openMore, setOpenMore] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [selectRow, setSelectRow] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [deviceType, setDeviceType] = useState(null)
    const [row, setRow] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [input, setInput] = useState(initialInput)
    const [message, setMessage] = useState('')
    // const { organizationId } = useParams()
    const columns = columnConfig('deviceIOT')
    const { handleOpen } = useContext(AlertContext)
    const localTimezone = localStorage.getItem('timezone')
    // console.log('local user device iot:', localTimezone)

    const { auth } = useAuth()

    const labelStyle = 'original'
    const timezones = {
        ...allTimezones,
        'Europe/Berlin': 'Frankfurt',
    }
    const { options } = useTimezoneSelect({
        labelStyle,
        timezones,
    })
    const timezoneOffsets = options.reduce((acc, option) => {
        acc[option.value] = option.offset * 60
        return acc
    }, {})

    const formatZone = (isoString, timezone) => {
        if (isoString) {
            const offset = timezoneOffsets[timezone] || 0
            const date = moment.utc(isoString)
            date.add(offset, 'minutes')
            return date.format('DD/MM/YYYY HH:mm:ss')
        }
        return null
    }

    const organizationId = auth.keyName

    const isAllInputFilled = () => {
        const inputKeys = Object.keys(input)
        for (const key of inputKeys) {
            if (typeof input[key] !== 'boolean' && !input[key]) {
                return false
            }
        }
        return true
    }

    const handleGetAllDeviceIot = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(
                `${urlConfig.deviceIot}/keyName/${organizationId}`,
            )
            const newData = res.data.message?.map((item, index) => {
                return {
                    id: index + 1,
                    ...item,
                    last_update: localTimezone
                        ? formatZone(item.updatedAt, localTimezone)
                        : formatDate(item.updatedAt),
                }
            })
            setData(newData)
            setFilterData(newData)
            setSearchText('')
            setDeviceTypeSelect('ALL')
            setLoading(false)
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            setLoading(false)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
            setData([])
        }
    }

    const handleBack = () => {
        setRowClick(null)
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.delete(
                `${urlConfig.deviceIot}/${selectRow?.device_id}`,
            )
            if (res.status === 204) {
                setIsSuccess(true)
                setAnchorEl(true)
                handleGetAllDeviceIot()
            }
        } catch (error) {
            setAnchorEl(true)
            setIsSuccess(false)
        } finally {
            setLoading(false)
            setOpenMore(false)
            setMessage('Delete device iot')
        }
    }

    const handleSubmitEdit = async () => {
        const payload = {
            keyName: organizationId,
            description: input.desc,
            enable: input.enable,
            key_type_id: input.keyTypeId,
            gateway_id: input.gatewayId,
        }

        setLoading(true)
        try {
            const resp = await axiosInstance.put(
                `${urlConfig.deviceIot}/${selectRow.device_id}`,
                payload,
            )
            if (resp.data.status === 200) {
                setIsSuccess(true)
                handleGetAllDeviceIot()
                setOpenEdit(false)
                setAnchorEl(true)
                setInput(initialInput)
            }
        } catch (error) {
            console.log('Error:', error)
            setIsSuccess(false)
            setAnchorEl(true)
        } finally {
            setLoading(false)
            setMessage('Edit device iot')
        }
    }

    useEffect(() => {
        const handleGetAllDeviceType = async () => {
            try {
                const res = await axiosInstance.get(`${urlConfig.deviceType}`)
                if (res.status === 200) {
                    const options = res.data.message?.map((item) => ({
                        id: item.key_type_id,
                        value: item.name,
                    }))
                    setDeviceType([{ id: 'ALL', value: 'ALL' }, ...options])
                }
                handleOpen({ open: false })
            } catch (error) {
                console.log('Error:', error)
                handleOpen({
                    open: true,
                    message: `${error.response?.data.message}`,
                    isSuccess: false,
                })
                setDeviceType(null)
            }
        }

        handleGetAllDeviceType()
    }, [])

    useEffect(() => {
        handleGetAllDeviceIot()
    }, [])

    const listMenu = [
        // {
        //     title: 'detail',
        //     icon: (
        //         <RemoveRedEyeOutlinedIcon color='secondary'></RemoveRedEyeOutlinedIcon>
        //     ),
        //     action: () => setRowClick(row),
        // },
        // {
        //     title: 'Edit',
        //     icon: <EditOutlinedIcon color='warning' />,
        //     action: () => handleClickMore(row, false),
        // },
        // {
        //     title: 'Delete',
        //     icon: <DeleteOutlineOutlinedIcon color='error' />,
        //     action: () => handleClickMore(row, true),
        // },
    ]
    const uniqueDeviceTypes = data && [
        'ALL',
        ...new Set(data.map((item) => item.device_type)),
    ]

    return (
        <Container header={header}>
            <Grid container gap={4} px={0}>
                {rowClick ? (
                    <Grid container mt={1} gap={3} p={2}>
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            startIcon={
                                <ArrowBackIosRoundedIcon fontSize='12px' />
                            }
                            onClick={handleBack}
                            sx={{ borderRadius: 5, fontSize: 12 }}
                        >
                            Back To Device IoT
                        </Button>{' '}
                        <DetailContainer
                            typeDevice={typeDevice}
                            typeAlarm={typeAlarm}
                            connectedWB={connectedWB}
                            user={true}
                            deviceId={rowClick.device_id}
                            data={rowClick}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <TableComponents
                            refresh={handleGetAllDeviceIot}
                            rowTable={setRowClick}
                            data={filterData}
                            columns={columns}
                            MenuList={listMenu}
                            setAction={setRow}
                            subButton={
                                <FormControl size='small' fullWidth>
                                    <InputLabel>Device Type</InputLabel>
                                    <Select
                                        value={deviceTypeSelect || ''}
                                        onChange={(e) => {
                                            const filteredData = data.filter(
                                                (item) =>
                                                    item.device_type ===
                                                    e.target.value,
                                            )

                                            setFilterData(
                                                filteredData.length > 0
                                                    ? filteredData
                                                    : data,
                                            )
                                            setDeviceTypeSelect(e.target.value)
                                        }}
                                        label='Device Type'
                                        sx={{
                                            borderRadius: '30px',
                                        }}
                                    >
                                        {uniqueDeviceTypes &&
                                            uniqueDeviceTypes?.map(
                                                (item, index) => (
                                                    <MenuItem
                                                        value={item}
                                                        key={index}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ),
                                            )}
                                    </Select>
                                </FormControl>
                            }
                        />
                    </Grid>
                )}
            </Grid>
            <CustomModal
                open={openEdit}
                setOpen={setOpenEdit}
                handleClose={() => setInput(initialInput)}
                size='xs'
                loading={loading}
            >
                <DeviceIotForm state={input} setState={setInput} isEdit />
                <ActionButtons
                    canSave={isAllInputFilled()}
                    onSave={handleSubmitEdit}
                    onCancel={() => setOpenEdit(false)}
                />
            </CustomModal>
            <ModalDelete
                disabled={loading}
                onSubmit={handleSubmit}
                open={openMore}
                setOpen={setOpenMore}
                message={selectRow?.device_id}
            />
            <PopOver
                isSuccess={isSuccess}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                message={message}
            />
        </Container>
    )
}

const formatDate = (isoString) => {
    const date = new Date(isoString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`
}

const header = {
    icon: <DisplaySettingsIcon sx={{ color: 'white' }}></DisplaySettingsIcon>,
    color: 'grey',
    title: 'Device IOT',
}
