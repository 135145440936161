import React from 'react'

export default function NotFound() {
    return (
        <div
            style={{
                textAlign: 'center',
                width: '100%',
                justifyContent: 'center',
                padding: '20px',
                marginTop: '20%',
            }}
        >
            Not Found
        </div>
    )
}
