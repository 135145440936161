import { Box } from '@mui/material'
import AirIcon from '@mui/icons-material/Air'
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined'
import { useAuth } from '../hook/useAuth'
import { useContext, useEffect, useRef, useState } from 'react'
import axiosInstance from '../config/axiosConfig'
import { urlConfig } from '../config/apiConfig'
import { AlertContext } from '../context/AlertContext'

export default function WeatherCard({ keyName }) {
    const { auth } = useAuth()
    const [weather, setWeather] = useState(null)
    const [air, setAir] = useState(null)
    const isFirstRender = useRef(true)
    const { handleOpen } = useContext(AlertContext)

    const getWeatherData = async () => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.weather}/${keyName ? keyName : auth.keyName}`,
            )
            setWeather(res.data.message)
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }

    const getAirPollutionData = async () => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.airPollution}/${keyName ? keyName : auth.keyName}`,
            )
            if (res.status === 200) {
                setAir(res.data.message)
            }
            handleOpen({ open: false })
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
            console.log('Error:', error)
        }
    }
    useEffect(() => {
        const fetchData = () => {
            getWeatherData()
            getAirPollutionData()
        }

        if (isFirstRender.current) {
            isFirstRender.current = false
            fetchData()
        }
        const intervalId = setInterval(fetchData, 30000)
        return () => clearInterval(intervalId)
    }, [])

    return (
        <Box
            sx={{
                bgcolor: 'rgba(255, 255, 255,0.7)',
                p: '10px',
                width: '100%',
                height: '100%',
                borderRadius: '5px',
            }}
        >
            <Box
                sx={{
                    height: '20%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    fontWeight: 'bold',
                    fontSize: '11px',
                    color: '#3073BE',
                }}
            >
                WEATHER
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: '4px',
                    width: '100%',
                    height: '80%',
                    justifyContent: 'center',
                }}
                align='center'
            >
                <Box
                    sx={{
                        boxShadow: ` rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`,
                        bgcolor: 'rgba(255,255,255,0.85)',
                        flexGrow: 0.6 / 3,
                        borderRadius: '5px',
                        padding: '13px 2px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50px',
                            width: '36px',
                            height: '36px',
                            bgcolor: '#3BA7FF',
                            boxShadow:
                                ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                            marginBottom: '5px',
                            border: '3px solid white',
                        }}
                    >
                        <CloudOutlinedIcon
                            sx={{
                                width: '25px',
                                height: '25px',
                                color: 'white',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            overflow: 'none',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#1b7080',
                        }}
                    >
                        {weather ? (weather.main.temp - 273.15).toFixed(2) : 0}{' '}
                        °C
                    </Box>
                    <Box
                        sx={{
                            fontSize: '10px',
                            fontWeight: '200',
                            color: '#1b7080',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <LocationOnOutlinedIcon
                            color='error'
                            sx={{
                                width: '15px',
                                height: '15px',
                                marginRight: '0px',
                            }}
                        />
                        <span
                            style={{
                                overflow: 'hidden',
                                width: '60px',
                                textOverflow: `ellipsis`,
                            }}
                        >
                            {weather?.name ?? '-'}
                        </span>
                    </Box>
                </Box>
                <Box
                    sx={{
                        boxShadow: ` rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px`,
                        bgcolor: 'rgba(255,255,255,0.85)',
                        flexGrow: 2.4 / 3,
                        padding: '13px 2px',
                        borderRadius: '5px',
                        display: 'flex',
                        gap: '3px',
                    }}
                >
                    <Box sx={{ flexGrow: 1 / 3 }}>
                        <Box
                            sx={{
                                width: '35px',
                                height: '35px',
                                border: '2px solid white',
                                borderRadius: '50px',
                                background: '#4a65e8',
                                boxShadow:
                                    ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                marginBottom: '8px',
                            }}
                        >
                            <img
                                src='/images/water.png'
                                alt='icon'
                                style={{ width: '29px', height: '29px' }}
                            />
                        </Box>
                        <Box
                            sx={{
                                overflow: 'none',
                                fontSize: '12px',
                                color: '#347ae3',
                            }}
                        >
                            Humidity
                        </Box>{' '}
                        <Box
                            sx={{
                                overflow: 'none',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: '#347ae3',
                            }}
                        >
                            {weather?.main?.humidity ?? 0} %
                        </Box>
                    </Box>{' '}
                    <Box sx={{ flexGrow: 1 / 3 }}>
                        <Box
                            sx={{
                                width: '35px',
                                height: '35px',
                                border: '2px solid white',
                                borderRadius: '50px',
                                background: '#a8a8a8',
                                boxShadow:
                                    ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                marginBottom: '8px',
                            }}
                        >
                            <AirIcon
                                sx={{
                                    width: '29px',
                                    height: '29px',
                                    color: 'white',
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                overflow: 'none',
                                fontSize: '12px',
                                color: '#5e5e5e',
                            }}
                        >
                            Wind Speed
                        </Box>{' '}
                        <Box
                            sx={{
                                overflow: 'none',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: '#5e5e5e',
                            }}
                        >
                            {weather?.wind.speed ?? 0} m/s
                        </Box>
                    </Box>{' '}
                    <Box sx={{ flexGrow: 1 / 3 }}>
                        <Box
                            sx={{
                                width: '35px',
                                height: '35px',
                                border: '2px solid white',
                                borderRadius: '50px',
                                background: 'green',
                                boxShadow:
                                    ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                marginBottom: '8px',
                            }}
                        >
                            <CoronavirusOutlinedIcon
                                sx={{
                                    width: '30px',
                                    height: '30px',
                                    color: 'white',
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                overflow: 'none',
                                fontSize: '12px',

                                color: 'green',
                            }}
                        >
                            PM 2.5
                        </Box>{' '}
                        <Box
                            sx={{
                                overflow: 'none',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: 'green',
                            }}
                        >
                            {air?.list[0].components.pm2_5 ?? 0} ug/m3
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
