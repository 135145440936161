import axios from 'axios'
import Cookies from 'js-cookie'
import { urlConfig } from './apiConfig'
import { logout } from '../components/ProtectRoute'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://kumwellslms.ubonmicrotech.com',
    timeout: 60000,
})

let isRefreshing = false
let subscribers = []

const encodedAccessToken = Cookies.get('token')
const encodedRefreshToken = Cookies.get('retoken')
const accessToken = encodedAccessToken ? atob(encodedAccessToken) : null
const refreshToken = encodedRefreshToken ? atob(encodedRefreshToken) : null

const onAccessTokenFetched = (newToken) => {
    subscribers = subscribers.filter((callback) => callback(newToken))
}

const addSubscriber = (callback) => {
    subscribers.push(callback)
}

export const reToken = async () => {
    try {
        if (!refreshToken) {
            logout()
            return null
        }
        const response = await axios.post(urlConfig.reToken, {
            refreshToken: refreshToken,
        })
        const newAccessToken = response.data.message.access_token
        const encodedNewAccessToken = btoa(newAccessToken)
        Cookies.set('token', encodedNewAccessToken, { expires: 10 })
        axiosInstance.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${newAccessToken}`
        onAccessTokenFetched(newAccessToken)
        return newAccessToken
    } catch (error) {
        if (
            error.response &&
            error.response.status &&
            error.response.status === 401
        ) {
            logout()
        }
        throw error
    } finally {
        isRefreshing = false
    }
}

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
axiosInstance.interceptors.response.use(
    (response) => {
        // console.log('response', response)
        return response
    },
    async (error) => {
        const {
            config,
            response: { status },
        } = error
        const originalRequest = config
        console.log('error yes  ', error)
        if (status && status === 401) {
            if (!isRefreshing) {
                isRefreshing = true
                try {
                    const newToken = await reToken()
                    if (newToken) {
                        originalRequest.headers[
                            'Authorization'
                        ] = `Bearer ${newToken}`
                        return axiosInstance(originalRequest)
                    }
                } catch (e) {
                    return Promise.reject(e)
                }
            }

            return new Promise((resolve) => {
                addSubscriber((newToken) => {
                    originalRequest.headers[
                        'Authorization'
                    ] = `Bearer ${newToken}`
                    resolve(axiosInstance(originalRequest))
                })
            })
        }

        return Promise.reject(error)
    },
)

export default axiosInstance
