import { useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import ActionButtons from '../popup/ActionButtons'
import { urlConfig } from '../../../config/apiConfig'

export default function UploadImage({
    setImage,
    onSave,
    setOpen,
    isSmall,
    imagePath,
    canSave,
    onCancel,
}) {
    const [previewImage, setPreviewImage] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')

    const handleImageChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            if (validateImageType(file)) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    setPreviewImage(reader.result)
                }
                reader.readAsDataURL(file)
                setImage(file)
                setErrorMessage('')
            } else {
                setPreviewImage(null)
                setErrorMessage('Only png, jpg, jpeg are allowed.')
            }
        }
    }

    const validateImageType = (file) => {
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif']
        return acceptedImageTypes.includes(file.type)
    }

    const handleCancel = () => {
        setOpen(false)
        onCancel?.()
    }

    const handleRemoveImage = () => {
        setPreviewImage(null)
    }

    return (
        <Grid container mt={2} display='flex' justifyContent='center'>
            {imagePath && !previewImage && (
                <img
                    src={`${urlConfig.baseUrlAPI}/api/v1${imagePath}`}
                    alt='PreviousImage'
                    style={{
                        // minWidth: isSmall ? '20%' : '100%',
                        // maxWidth: isSmall ? '20%' : '100%',
                        borderRadius: 10,
                        minHeight: '100px',
                        maxHeight: '100px',
                    }}
                />
            )}
            {previewImage && (
                <img
                    src={previewImage}
                    alt='Preview'
                    style={{
                        // minWidth: isSmall ? '20%' : '100%',
                        // maxWidth: isSmall ? '20%' : '100%',
                        borderRadius: 10,
                        minHeight: '100px',
                        maxHeight: '100px',
                    }}
                />
            )}
            <Box
                sx={{
                    width: '100%',
                    bgcolor: '#F0F0F0',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 4,
                    flexDirection: 'column',
                    gap: 2,
                    mt: previewImage || imagePath ? 2 : 0,
                }}
            >
                <Typography>Upload New Image Here</Typography>
                {previewImage ? (
                    <Button
                        variant='contained'
                        onClick={() => handleRemoveImage()}
                        sx={{ color: 'primary.lightest' }}
                    >
                        Clear Image
                    </Button>
                ) : (
                    <Button
                        variant='contained'
                        component='label'
                        color='info'
                        sx={{ color: 'primary.lightest' }}
                    >
                        Upload Image
                        <input
                            type='file'
                            hidden
                            accept='.png, .jpeg, .jpg'
                            onChange={handleImageChange}
                        />
                    </Button>
                )}
            </Box>
            {errorMessage && (
                <Typography mt={1} color='error'>
                    *{errorMessage}
                </Typography>
            )}
            <ActionButtons
                canSave={(previewImage && canSave) || (imagePath && canSave)}
                onSave={onSave}
                onCancel={handleCancel}
            />
        </Grid>
    )
}
