import React, { useState } from 'react'
import { Box, Button, Grid, Modal, TextField } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

export default function ModalDeleteOrg({
    open,
    setOpen,
    onSubmit,
    message,
    disabled,
    data,
}) {
    const [inputValue, setInputValue] = useState('')

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const isDeleteEnabled = inputValue === data.keyName

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box
                sx={{
                    position: 'absolute',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 6,
                    padding: '25px 20px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12} align='center'>
                        <CancelOutlinedIcon
                            color='error'
                            fontSize='large'
                            sx={{ width: '50px', height: '50px' }}
                        />
                    </Grid>
                    <Grid item xs={12} align='center'>
                        <Box sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                            Are you sure?
                        </Box>
                    </Grid>
                    <Grid item xs={12} align='center' mt={0}>
                        <Box sx={{ fontSize: '14px' }}>
                            To confirm, type the name{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                "{data.keyName}"
                            </span>{' '}
                            to delete
                        </Box>
                    </Grid>
                    <Grid item xs={12} align='center' mt={2}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Confirm by typing the name'
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} align='center' mt={2} mb={0}>
                        <Button
                            disabled={disabled || !isDeleteEnabled}
                            color='error'
                            sx={{ color: 'white', textTransform: 'none' }}
                            variant='contained'
                            onClick={() => onSubmit()}
                        >
                            Delete
                        </Button>
                        <Button
                            color='secondary'
                            onClick={() => setOpen(false)}
                            sx={{
                                color: 'white',
                                textTransform: 'none',
                                background: 'lightgrey',
                                marginLeft: '10px',
                            }}
                            variant='contained'
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
