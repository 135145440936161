import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export default class CustomAdapter extends AdapterDayjs {
    constructor({ locale, formats, instance }) {
        super({ locale, formats, instance })
    }

    formatByString = (date, format) => {
        // const newFormat = format.replace(/\bYYYY\b/g, 'YYYY')
        return this.dayjs(date).locale('th').format(format)
    }
}
