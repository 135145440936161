import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import StickyNote2RoundedIcon from '@mui/icons-material/StickyNote2Rounded'
import columnConfig from '../../config/columnsConfig'
import { Container } from '../../components/StyleComponent'
import { useAuth } from '../../hook/useAuth'
import { urlConfig } from '../../config/apiConfig'
import axiosInstance from '../../config/axiosConfig'
import DynamicTable from './DydnamicTable'
import { useParams } from 'react-router-dom'
import ModalReport from './ModalReport'
import moment from 'moment-timezone'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'
import { AlertContext } from '../../context/AlertContext'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import ModalReportTrigger from './ModalReportTrigger'

export default function UserReport({ org, admin, orgUser }) {
    const { organizationId } = useParams()
    const [data, setData] = useState('')
    const [device, setDevice] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectDevice, setSelectDevice] = useState('')
    const [deviceType, setDeviceType] = useState('')
    const [foot, setFoot] = useState('')
    const [open, setOpen] = useState('')
    const [openTrigger, setOpenTrigger] = useState(false)
    const [loadd, setLoadd] = useState(false)
    const [pageChange, setPageChange] = useState(1)
    const [limit, setLimit] = useState(10)
    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [payDevice, setPayDevice] = useState()
    const [mode, setMode] = useState(0)
    const [modeOK, setModeOK] = useState(0)
    const [useColumn, setUseColumn] = useState('')
    const columns = columnConfig('AlarmReport')
    const columns2 = columnConfig('AlarmReport2')
    const { auth } = useAuth()

    const localTimezone = localStorage.getItem('timezone')

    const labelStyle = 'original'
    const timezones = {
        ...allTimezones,
        'Europe/Berlin': 'Frankfurt',
    }
    const { options } = useTimezoneSelect({
        labelStyle,
        timezones,
    })
    const timezoneOffsets = options.reduce((acc, option) => {
        acc[option.value] = option.offset * 60
        return acc
    }, {})

    const formatZone = (isoString, timezone) => {
        if (isoString) {
            const offset = timezoneOffsets[timezone] || 0
            const date = moment.utc(isoString)
            date.add(offset, 'minutes')
            return date.format('DD/MM/YYYY HH:mm:ss')
        }
        return null
    }

    const historyColumns = [
        {
            id: 'no',
            label: 'No.',
            width: '5%',
            sortable: true,
            align: 'left',
        },
        // {
        //     id: 'createdAt',
        //     label: 'createdAt',
        //     width: '20%',
        //     sortable: true,
        //     align: 'left',
        // },
    ]
    historyColumns.push({
        id: 'timeStampTo',
        label: 'Timestamp',
        width: '10%',
        sortable: true,
        align: 'center',
    })
    if (data && modeOK === 1) {
        const uniqueKeys = new Set()
        data.forEach((item) => {
            if (item.payload) {
                Object.keys(item.payload).forEach((key) => {
                    uniqueKeys.add(key)
                })
            }
        })
        uniqueKeys.forEach((key) => {
            historyColumns.push({
                id: key,
                label: key.replace('_', ' '),
                width: '10%',
                align: 'left',
                type: 'payload',
            })
        })
    }

    const { handleOpen } = useContext(AlertContext)

    const getDevice = useCallback(async () => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.device}/keyName/${
                    admin ? organizationId : auth.keyName
                }`,
            )
            setDevice(res.data.message)
            setPayDevice(res)
            setDeviceType('ALL')
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error fetching :', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [auth.keyName])

    const getReport = async () => {
        setLoadd(true)
        setData('')
        const section = mode === 0 ? 'alarmLog' : 'historylog'
        try {
            const res = await axiosInstance.get(
                `${
                    urlConfig[section]
                }/${selectDevice}?page=${pageChange}&limit=${limit}&date_from=${dayjs(
                    startDate,
                ).format('YYYY-MM-DD')} 00:00:00&date_to=${dayjs(
                    endDate,
                ).format('YYYY-MM-DD')} 23:59:59`,
            )

            setFoot(res.data.meta)
            const startIndex = (pageChange - 1) * limit
            setData(
                res.data.message.map((item, index) => ({
                    ...item,
                    no: startIndex + index + 1,
                    timeStampTo:
                        localTimezone && !admin
                            ? formatZone(item.createdAt, localTimezone)
                            : formatDate(item.createdAt),
                })),
            )
        } catch (error) {
            console.log('Error fetching:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        } finally {
            setLoadd(false)
        }
    }

    const getData = useCallback(async (yea) => {
        const section = mode === 0 ? 'alarmLog' : 'historylog'
        try {
            const res = await axiosInstance.get(
                `${
                    urlConfig[section]
                }/${yea}?page=${pageChange}&limit=${limit}&date_from=${dayjs(
                    startDate,
                ).format('YYYY-MM-DD')} 00:00:00&date_to=${dayjs(
                    endDate,
                ).format('YYYY-MM-DD')} 23:59:59`,
            )
            const startIndex = (pageChange - 1) * limit
            setFoot(res.data.meta)
            setData(
                res.data.message.map((item, index) => ({
                    ...item,
                    no: startIndex + index + 1,
                    timeStampTo:
                        localTimezone && !admin
                            ? formatZone(item.createdAt, localTimezone)
                            : formatDate(item.createdAt),
                })),
            )
            handleOpen({ open: false })
        } catch (error) {
            console.log('Error fetching:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }, [])

    const uniqueDeviceTypes = device && [
        'ALL',
        ...new Set(device.map((item) => item.device_type)),
    ]
    const deviceFiltered =
        device && device.filter((item) => item.device_type === deviceType)

    const deviceName = deviceFiltered.length > 0 ? deviceFiltered : device

    useEffect(() => {
        getDevice()
    }, [getDevice])

    useEffect(() => {
        if (device.length > 0) {
            // setSelectDevice(device[0].device_id)
            // getData(device[0].device_id)
        }
    }, [device])

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        getReport()
    }, [pageChange, limit])

    const onSearch = (event) => {
        event.preventDefault()
        setUseColumn(tyleCoulumn)
        setModeOK(mode)
        getReport()
        setPageChange(1)
    }

    const optionExport = historyColumns.filter(
        (item) => item.type === 'payload',
    )

    const optionTrigger =
        device &&
        modeOK === 0 &&
        device.filter((item) => item.device_id === selectDevice)[0]
            ?.deviceTypeParameter

    const optionTrigger2 = data && modeOK === 0 && data[0]?.payload

    // console.log('dada', optionTrigger2)

    const onSubmit = async (title) => {
        setLoading(true)
        try {
            const startDateFormat = dayjs(startDate).format('YYYY-MM-DD')
            const endDateFormat = dayjs(endDate).format('YYYY-MM-DD')

            const formattedUrl = `${
                urlConfig.export
            }/alarm/${selectDevice}?format=${
                title === 'pdf' ? 'pdf' : 'csv'
            }&date_from=${startDateFormat} 00:00:00&date_to=${endDateFormat} 23:59:59`
            const res = await axiosInstance.get(formattedUrl, {
                responseType: 'arraybuffer',
            })
            const blob = new Blob([res.data], {
                type:
                    title === 'pdf'
                        ? 'application/pdf'
                        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const blobURL = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = blobURL

            link.download = `alarm_log_report.${
                title === 'pdf' ? 'pdf' : 'xlsx'
            }`
            // link.download = `alaram%20%log%20${startDateFormat}%2000_00_00&date_to=${dayjs(
            //     endDateFormat,
            // ).format('YYYY-MM-DD')}%2023:59:59.${
            //     title === 'pdf' ? 'pdf' : 'xlsx'
            // }`
            link.click()
            handleOpen({ open: false })
            setLoading(false)
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
            setLoading(false)
        }
    }
    // console.log('foot', foot)

    const tyleCoulumn =
        device.length > 0 &&
        device?.filter((item) => item.device_id === selectDevice)[0]?.type_alert

    const formControls = (
        <>
            <Grid item xs={4} mb={2}>
                <FormControl size='small' fullWidth>
                    <InputLabel>Report Type</InputLabel>
                    <Select
                        label='Report Type'
                        value={mode}
                        onChange={(e, ev) => setMode(e.target.value)}
                        sx={{ borderRadius: '30px' }}
                    >
                        {Array.from({ length: 2 }).map((item, index) => (
                            <MenuItem value={index} key={index}>
                                {index === 0 ? 'alarm log' : ' history log'}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
                <FormControl size='small' fullWidth>
                    <InputLabel>Device Type</InputLabel>
                    <Select
                        label='Device Type'
                        onChange={(e) => {
                            setDeviceType(e.target.value)
                            setSelectDevice('')
                        }}
                        required
                        sx={{ borderRadius: '30px' }}
                        value={deviceType}
                    >
                        {uniqueDeviceTypes &&
                            uniqueDeviceTypes.map((item, index) => (
                                <MenuItem value={item} key={index}>
                                    {item}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4} md={4}>
                <FormControl size='small' fullWidth>
                    <InputLabel>Device</InputLabel>
                    <Select
                        required
                        label='Device'
                        sx={{ borderRadius: '30px' }}
                        value={selectDevice || ''}
                        onChange={(e) => setSelectDevice(e.target.value) || ''}
                    >
                        {deviceName &&
                            deviceName.map((item, index) => (
                                <MenuItem value={item.device_id} key={index}>
                                    {item.device_id}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} md={4}>
                {/* <TextField
                    fullWidth
                    size='small'
                    type='date'
                    label='Start Date'
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{
                        '& .MuiOutlinedInput-root': { borderRadius: '30px' },
                    }}
                    value={startDate}
                    onChange={handleStartDateChange}
                /> */}
                <DatePicker
                    label='Start Date'
                    InputLabelProps={{ shrink: true }}
                    slotProps={{ textField: { size: 'small' } }}
                    format='DD/MM/YYYY'
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                            borderRadius: 30,
                        },
                    }}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                {/* <TextField
                    fullWidth
                    size='small'
                    type='date'
                    label='End Date'
                    required
                    InputLabelProps={{ shrink: true }}
                    sx={{
                        '& .MuiOutlinedInput-root': { borderRadius: '30px' },
                    }}
                    value={endDate}
                    onChange={handleEndDateChange}
                /> */}
                <DatePicker
                    label='End Date'
                    InputLabelProps={{ shrink: true }}
                    slotProps={{ textField: { size: 'small' } }}
                    format='DD/MM/YYYY'
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                            borderRadius: 30,
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Button
                    // onClick={() => getReport()}
                    fullWidth
                    type='submit'
                    variant='contained'
                    color='buttonSave'
                    sx={{ borderRadius: '30px' }}
                >
                    Search
                </Button>
            </Grid>
        </>
    )

    return (
        <>
            {!org ? (
                <Container header={header}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DynamicTable
                                refresh={onSearch}
                                trigger={useColumn === 'Trigger state'}
                                setOpenTrigger={setOpenTrigger}
                                loadd={loadd}
                                loading={loading}
                                modeOK={modeOK}
                                getReports={onSubmit}
                                setOpen={setOpen}
                                limit={limit}
                                setLimit={setLimit}
                                setPageChange={setPageChange}
                                pageChange={pageChange}
                                foot={foot || false}
                                report={
                                    <form onSubmit={onSearch}>
                                        <Grid item xs={12} mt={2}>
                                            <Grid container spacing={1}>
                                                {formControls}
                                            </Grid>
                                        </Grid>
                                    </form>
                                }
                                data={device.length > 0 ? data : []}
                                columns={
                                    modeOK === 0
                                        ? useColumn === 'Trigger state'
                                            ? columns2
                                            : columns
                                        : historyColumns
                                }
                                error={false}
                                exportDoc={true}
                            />
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DynamicTable
                            refresh={onSearch}
                            trigger={useColumn === 'Trigger state'}
                            setOpenTrigger={setOpenTrigger}
                            loadd={loadd}
                            loading={loading}
                            modeOK={modeOK}
                            getReports={onSubmit}
                            setOpen={setOpen}
                            limit={limit}
                            setLimit={setLimit}
                            setPageChange={setPageChange}
                            pageChange={pageChange}
                            foot={foot || false}
                            report={
                                <form onSubmit={onSearch}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            {formControls}
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={12} mt={0.5}>
                                        <Grid container spacing={1}>
                                            {dateFields}
                                        </Grid>
                                    </Grid> */}
                                </form>
                            }
                            data={device.length > 0 ? data : []}
                            columns={
                                modeOK === 0
                                    ? useColumn === 'Trigger state'
                                        ? columns2
                                        : columns
                                    : historyColumns
                            }
                            error={false}
                            exportDoc={true}
                        />
                    </Grid>
                </Grid>
            )}
            <ModalReport
                startDate={startDate}
                endDate={endDate}
                selectDevice={selectDevice || null}
                open={!!open}
                setOpen={setOpen}
                title={open}
                optionExport={optionExport}
                nameParam={''}
            />
            {openTrigger && (
                <ModalReportTrigger
                    optionTrigger2={optionTrigger2}
                    startDate={startDate}
                    endDate={endDate}
                    selectDevice={selectDevice || null}
                    open={!!openTrigger}
                    setOpen={setOpenTrigger}
                    title={openTrigger}
                    optionExport={optionTrigger}
                    nameParam={''}
                />
            )}
        </>
    )
}

const header = {
    icon: (
        <StickyNote2RoundedIcon
            sx={{ width: '20px', height: '20px', color: 'white' }}
        />
    ),
    color: 'grey',
    title: 'Report',
}

const formatDate = (isoString) => {
    const date = new Date(isoString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`
}
