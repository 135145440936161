import React, { useContext, useEffect, useState } from 'react'
import ModalRequest from '../../components/ModalRequest'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { Input } from '../../components/StyleComponent'
import { useAuth } from '../../hook/useAuth'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import { AlertContext } from '../../context/AlertContext'

export default function UserManage({ open, setOpen, handleAlert }) {
    const { handleSubmit, control, setValue } = useForm()
    const { auth, setAuth } = useAuth()
    const [loading, setLoading] = useState(false)
    const { handleOpen } = useContext(AlertContext)

    const getAuth = async () => {
        try {
            const res = await axiosInstance(urlConfig.myToken)
            setAuth(res.data.message)
            handleOpen({ open: false })
        } catch (error) {
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
        }
    }

    const onSubmit = async (data) => {
        const body = {
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number,
            email: data.email,
        }
        if (!data.phone_number) {
            delete body.phone_number
        }
        try {
            setLoading(true)
            await axiosInstance.put(`${urlConfig.user}/${auth.user_id}`, body)
            handleAlert('Account update successfully.', 'success')
            getAuth()
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        Object.keys(auth).forEach((key) => {
            setValue(key, auth[key])
        })
    }, [auth, setValue])

    return (
        <ModalRequest
            open={open}
            setOpen={setOpen}
            title={'My Account'}
            onSubmit={handleSubmit(onSubmit)}
            disabled={loading}
        >
            <Grid item xs={12} md={6}>
                <Controller
                    name='first_name'
                    control={control}
                    // defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='First Name'
                            value={field.value || ''}
                            onChange={field.onChange}
                            required
                        />
                    )}
                />
            </Grid>{' '}
            <Grid item xs={12} md={6}>
                <Controller
                    name='last_name'
                    control={control}
                    // defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Last Name'
                            value={field.value || ''}
                            onChange={field.onChange}
                            required
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    name='phone_number'
                    control={control}
                    // defaultValue=''
                    render={({ field }) => (
                        <Input
                            label='Phone Number '
                            type='number'
                            value={field.value || ''}
                            onChange={field.onChange}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                    name='email'
                    control={control}
                    // defaultValue=''
                    render={({ field }) => (
                        <Input
                            type='email'
                            label='Email'
                            value={field.value || ''}
                            onChange={field.onChange}
                            required
                        />
                    )}
                />
            </Grid>{' '}
            <Grid item xs={12} md={12}>
                <Controller
                    name='role_name'
                    control={control}
                    render={({ field }) => (
                        <Input
                            label='Role'
                            value={field.value || ''}
                            readOnly={true}
                        />
                    )}
                />
            </Grid>{' '}
        </ModalRequest>
    )
}
