import React, { useState } from 'react'
import ModalRequest from '../../components/ModalRequest'
import { Controller, useForm } from 'react-hook-form'
import { Grid, Checkbox, FormControlLabel } from '@mui/material'
import { Input } from '../../components/StyleComponent'
import { useAuth } from '../../hook/useAuth'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'

export default function PasswordChange({ open, setOpen, handleAlert }) {
    const { handleSubmit, control, setError, clearErrors } = useForm()
    const { auth } = useAuth()
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const onSubmit = async (data) => {
        if (data.newPassword !== data.confirmPassword) {
            setError('confirmPassword', {
                type: 'manual',
                message: 'Passwords do not match',
            })
            return
        } else {
            clearErrors('confirmPassword')
        }

        const body = {
            currentPassword: data.currentPassword,
            newPassword: data.newPassword,
        }

        try {
            setLoading(true)
            await axiosInstance.put(
                `${urlConfig.user}/change-password/${auth.user_id}`,
                body,
            )
            handleAlert('Password updated successfully.', 'success')
            setOpen(false)
        } catch {
            handleAlert('Failed to update the Password', 'error')
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalRequest
            open={open}
            setOpen={setOpen}
            title={'Change Password'}
            onSubmit={handleSubmit(onSubmit)}
            disabled={loading}
        >
            <Grid item xs={12} md={12}>
                <Controller
                    name='currentPassword'
                    control={control}
                    render={({ field }) => (
                        <Input
                            label='Current Password'
                            value={field.value || ''}
                            onChange={field.onChange}
                            required
                            type={showPassword ? 'text' : 'password'}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Controller
                    name='newPassword'
                    control={control}
                    rules={{
                        required: 'New Password is required',
                        minLength: {
                            value: 8,
                            message:
                                'Password must be at least 8 characters long',
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <Input
                            label='New Password'
                            value={field.value || ''}
                            onChange={field.onChange}
                            required
                            type={showPassword ? 'text' : 'password'}
                            error={!!error}
                            helperText={error ? error.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Controller
                    name='confirmPassword'
                    control={control}
                    rules={{
                        required: 'Confirm Password is required',
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <Input
                            label='Confirm Password'
                            value={field.value || ''}
                            onChange={field.onChange}
                            required
                            type={showPassword ? 'text' : 'password'}
                            error={!!error}
                            helperText={error ? error.message : ''}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showPassword}
                            onChange={(e) => setShowPassword(e.target.checked)}
                        />
                    }
                    label={
                        <span style={{ fontSize: '13px' }}>Show Passwords</span>
                    }
                />
            </Grid>
        </ModalRequest>
    )
}
