import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import LoadingBackdrop from '../../../common/LoadingBackdrop'
import DeviceIoTHeader from './DeviceIotHeader'
import { useContext, useEffect, useState } from 'react'
import DetailContainer from './DeviceDetail/DetailContainer'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import { urlConfig } from '../../../../config/apiConfig'
import axiosInstance from '../../../../config/axiosConfig'
import CustomModal from '../../../common/popup/CustomModal'
import DeviceIotForm from './DeviceIotForm'
import ActionButtons from '../../../common/popup/ActionButtons'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModalDelete from '../../../ModalDelete'
import { useParams } from 'react-router-dom'
import TableComponents from '../../../TableComponents'
import columnConfig from '../../../../config/columnsConfig'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { AlertPopup } from '../../../StyleComponent'
import dayjs from 'dayjs'
import { AlertContext } from '../../../../context/AlertContext'

const initialInput = {
    enable: true,
    keyTypeId: '',
    gatewayId: '',
    desc: '',
    s_n: '',
}

export default function DeviceIoTContainer({
    create,
    update,
    remove,
    handleAlert,
    typeDevice,
    typeAlarm,
    connectedWB,
}) {
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState([])
    const [loading, setLoading] = useState(false)
    const [rowClick, setRowClick] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [deviceTypeSelect, setDeviceTypeSelect] = useState('')
    const [openMore, setOpenMore] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [selectRow, setSelectRow] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [row, setRow] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [input, setInput] = useState(initialInput)
    const [message, setMessage] = useState('')
    const [openAdd, setOpenAdd] = useState(false)
    const { organizationId } = useParams()
    const columns = columnConfig('deviceIOTadmin')
    const { handleOpen } = useContext(AlertContext)

    const isAllInputFilled = () => {
        const inputKeys = Object.keys(input)
        for (const key of inputKeys) {
            if (typeof input[key] !== 'boolean' && !input[key]) {
                return false
            }
        }
        return true
    }

    const handleGetAllDeviceIot = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(
                `${urlConfig.deviceIot}/keyName/${organizationId}`,
            )
            const newData = res.data.message?.map((item, index) => ({
                id: index + 1,
                ...item,
                last_update: dayjs(item.updatedAt).format(
                    'DD/MM/YYYY, HH:mm:ss',
                ),
            }))
            setData(newData)
            setFilterData(newData)
            setSearchText('')
            setDeviceTypeSelect('ALL')
            setLoading(false)
            handleOpen({ open: false })
        } catch (error) {
            setFilterData([])
            console.log('Error:', error)
            handleOpen({
                open: true,
                message: `${error.response?.data.message}`,
                isSuccess: false,
            })
            setLoading(false)
        }
    }

    const handleBack = () => {
        setRowClick(null)
    }

    const handleSearch = () => {
        const filteredData = data.filter(
            (item) => item.device_type === deviceTypeSelect,
        )

        setFilterData(filteredData)
    }

    const handleClickMore = (row, isDelete) => {
        if (isDelete) {
            setOpenMore(true)
        } else {
            setInput((prev) => ({
                ...prev,
                desc: row.description,
                enable: row.enable,
                gatewayId: row.gateway_id,
                keyTypeId: row.key_type_id,
                s_n: row.s_n,
            }))
            setOpenEdit(true)
        }
        setSelectRow(row)
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.delete(
                `${urlConfig.deviceIot}/${selectRow?.device_id}`,
            )
            if (res.status === 204) {
                setIsSuccess(true)
                setAnchorEl(true)
                handleGetAllDeviceIot()
            }
        } catch (error) {
            setAnchorEl(true)
            setIsSuccess(false)
        } finally {
            setLoading(false)
            setOpenMore(false)
            setMessage('Delete device iot')
        }
    }

    const handleSubmitEdit = async () => {
        const payload = {
            keyName: organizationId,
            description: input.desc,
            enable: input.enable,
            key_type_id: input.keyTypeId,
            gateway_id: input.gatewayId,
            s_n: input.s_n,
        }

        setLoading(true)
        try {
            const resp = await axiosInstance.put(
                `${urlConfig.deviceIot}/${selectRow.device_id}`,
                payload,
            )
            if (resp.data.status === 200) {
                setIsSuccess(true)
                handleGetAllDeviceIot()
                setOpenEdit(false)
                setAnchorEl(true)
                setInput(initialInput)
            }
        } catch (error) {
            console.log('Error:', error)
            setIsSuccess(false)
            setAnchorEl(true)
        } finally {
            setLoading(false)
            setMessage('Edit device iot')
        }
    }

    useEffect(() => {
        handleGetAllDeviceIot()
    }, [])

    useEffect(() => {
        console.log('data:', data)
    }, [data])

    const listMenu = [
        {
            title: 'Setting',
            icon: (
                <SettingsOutlinedIcon color='secondary'></SettingsOutlinedIcon>
            ),
            action: () => setRowClick(row),
        },
    ]

    if (update) {
        listMenu.push({
            title: 'Edit',
            icon: <EditOutlinedIcon color='warning' />,
            action: () => handleClickMore(row, false),
        })
    }

    if (remove) {
        listMenu.push({
            title: 'Delete',
            icon: <DeleteOutlineOutlinedIcon color='error' />,
            action: () => handleClickMore(row, true),
        })
    }

    const uniqueDeviceTypes = data && [
        'ALL',
        ...new Set(data.map((item) => item.device_type)),
    ]

    return (
        <>
            <LoadingBackdrop loading={loading} />
            <Grid container gap={4}>
                {rowClick ? (
                    <Grid container mt={1} gap={3}>
                        <Button
                            variant='contained'
                            size='small'
                            color='primary'
                            startIcon={
                                <ArrowBackIosRoundedIcon fontSize='12px' />
                            }
                            onClick={handleBack}
                            sx={{ borderRadius: 5, fontSize: 12 }}
                        >
                            Back To Device IoT
                        </Button>
                        <DetailContainer
                            typeDevice={typeDevice}
                            typeAlarm={typeAlarm}
                            connectedWB={connectedWB}
                            create={create}
                            update={update}
                            remove={remove}
                            deviceId={rowClick.device_id}
                            data={rowClick}
                            handleAlert={handleAlert}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <DeviceIoTHeader
                            searchText={searchText}
                            setSearchText={setSearchText}
                            deviceTypeSelect={deviceTypeSelect}
                            setDeviceTypeSelect={setDeviceTypeSelect}
                            onFetchData={handleGetAllDeviceIot}
                            onSearch={handleSearch}
                            input={input}
                            setInput={setInput}
                            openAdd={openAdd}
                            setOpenAdd={setOpenAdd}
                        />
                        <TableComponents
                            refresh={handleGetAllDeviceIot}
                            data={filterData}
                            columns={columns}
                            setAction={setRow}
                            MenuList={listMenu}
                            disabledButton={create}
                            button={{
                                title: 'ADD DEVICE',
                                action: () => setOpenAdd(true),
                            }}
                            subButton={
                                <FormControl size='small' fullWidth>
                                    <InputLabel>Device Type</InputLabel>
                                    <Select
                                        value={deviceTypeSelect || ''}
                                        onChange={(e) => {
                                            const filteredData = data.filter(
                                                (item) =>
                                                    item.device_type ===
                                                    e.target.value,
                                            )
                                            setFilterData(
                                                filteredData.length > 0
                                                    ? filteredData
                                                    : data,
                                            )
                                            setDeviceTypeSelect(e.target.value)
                                            setDeviceTypeSelect(e.target.value)
                                            // handleSearch(e.target.value)
                                        }}
                                        label='Device Type'
                                        sx={{
                                            borderRadius: '30px',
                                        }}
                                    >
                                        {uniqueDeviceTypes &&
                                            uniqueDeviceTypes?.map(
                                                (item, index) => (
                                                    <MenuItem
                                                        value={item}
                                                        key={index}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ),
                                            )}
                                    </Select>
                                </FormControl>
                            }
                        />
                    </Grid>
                )}
            </Grid>
            <CustomModal
                open={openEdit}
                setOpen={setOpenEdit}
                handleClose={() => setInput(initialInput)}
                size='xs'
                loading={loading}
            >
                <DeviceIotForm state={input} setState={setInput} isEdit />
                <ActionButtons
                    canSave={isAllInputFilled()}
                    onSave={handleSubmitEdit}
                    onCancel={() => setOpenEdit(false)}
                />
            </CustomModal>
            <ModalDelete
                disabled={loading}
                onSubmit={handleSubmit}
                open={openMore}
                setOpen={setOpenMore}
                message={selectRow?.device_id}
            />
            <AlertPopup
                open={anchorEl}
                setOpen={setAnchorEl}
                message={{
                    status: isSuccess ? 'success' : 'error',
                    name: message,
                }}
            />
        </>
    )
}
