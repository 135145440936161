import { FormControlLabel, Switch } from '@mui/material'

export default function IOSSwitchInput({ setState, name, status }) {
    const isChecked = status || false

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={isChecked}
                    onChange={(e) => {
                        setState(name, e.target.checked)
                    }}
                    color='success'
                />
            }
        />
    )
}
