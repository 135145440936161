import { Box, Typography } from '@mui/material'
import { Container } from '../StyleComponent'
import { useContext, useEffect, useState } from 'react'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import MqttSummary from './MqttSummary'
import LoadingBackdrop from '../common/LoadingBackdrop'
import WifiTetheringRoundedIcon from '@mui/icons-material/WifiTetheringRounded'
import columnConfig from '../../config/columnsConfig'
import TableComponents from '../TableComponents'
import dayjs from 'dayjs'
import { AlertContext } from '../../context/AlertContext'

export default function MqttContainer() {
    const [loading, setLoading] = useState(false)
    const [mqttStatus, setMqttStatus] = useState('')
    const [gateway, setGateway] = useState([])
    const [summary, setSummary] = useState({
        totalClients: 0,
        activeClients: 0,
        connectedClients: 0,
    })

    const columns = columnConfig('mqtt')
    const { handleOpen } = useContext(AlertContext)

    useEffect(() => {
        const handleGetAllGateway = async () => {
            setLoading(true)
            try {
                const res = await axiosInstance.get(`${urlConfig.gateway}`)
                if (res.data.status === 200) {
                    const newData = res.data.message?.map((item, index) => ({
                        id: index + 1,
                        ...item,
                        updatedAt: item.updatedAt
                            ? dayjs(item.updatedAt).format(
                                  'DD/MM/YYYY, HH:mm:ss',
                              )
                            : '',
                    }))
                    setMqttStatus(res.data.mqttStatus)
                    setGateway(newData)
                }
                setLoading(false)
                handleOpen({ open: false })
            } catch (error) {
                console.log('Error:', error.response?.data.message)
                setLoading(false)
                setGateway([])
                handleOpen({
                    open: true,
                    message: `${error.response?.data.message}`,
                    isSuccess: false,
                })
            }
        }
        handleGetAllGateway()
    }, [])

    useEffect(() => {
        if (gateway) {
            const totalClients = gateway.length
            const activeClients = gateway.filter((gw) => gw.enable).length
            const connectedClients = gateway.filter(
                (gw) => gw.status === 'Online',
            ).length

            setSummary({
                totalClients: totalClients,
                activeClients: activeClients,
                connectedClients: connectedClients,
            })
        }
    }, [gateway])

    return (
        <Container
            statusValue={mqttStatus === true ? 'Online' : 'Offline'}
            header={header}
            summaryChildren={<MqttSummary summary={summary} />}
        >
            <Box sx={{}}>
                <LoadingBackdrop loading={loading} />
                <Typography variant='h5' sx={{ padding: '20px 20px 0 20px' }}>
                    Clients
                </Typography>
                <TableComponents
                    columns={columns}
                    data={gateway}
                    disableSearch
                />
            </Box>
        </Container>
    )
}
const header = {
    icon: <WifiTetheringRoundedIcon sx={{ color: 'white' }} />,
    color: 'grey',
    title: 'MQTT Broker',
}
