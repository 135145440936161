import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './hook/useAuth'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import theme from './theme/index.jsx'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <AuthProvider>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </AuthProvider>,
)

reportWebVitals()
