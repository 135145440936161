import { useTheme } from '@emotion/react'
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    TextField,
    Modal,
    Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { urlConfig } from '../../config/apiConfig.jsx'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

export default function Login() {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const theme = useTheme()
    const { register, handleSubmit } = useForm()
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        setError(false)
        setLoading(true)
        try {
            const response = await axios.post(
                urlConfig.loginUser,
                {
                    usernameOrEmail: data.username,
                    password: data.password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            if (response.status === 200) {
                if (!response.data.message.message) {
                    const accessToken = btoa(response.data.message.access_token)
                    const refreshToken = btoa(
                        response.data.message.refresh_token,
                    )
                    await Cookies.set('token', accessToken, { expires: 10 })
                    await Cookies.set('retoken', refreshToken, { expires: 10 })
                    window.location.href = '/'
                    // navigate('/', { replace: true })
                } else {
                    setError({
                        message: response.data.message.message,
                        logo: 'warning',
                    })
                }
            }
        } catch (error) {
            setError({
                message: error.response?.data.message,
                logo: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                }}
            >
                <Grid item xs={0} sm={7} display={{ xs: 'none', sm: 'block' }}>
                    <img
                        src='./images/bg.jpg'
                        width='100%'
                        height='100%'
                        alt='logo'
                        style={{
                            objectFit: 'cover',
                            OBackgroundSize: 'cover',
                            backgroundSize: 'cover',
                            position: 'fixed',
                            zIndex: '-1',
                        }}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '5rem',
                            fontWeight: 'bolder',
                            color: theme.palette.error.main,
                        }}
                        align='center'
                        justifyItems='center'
                    >
                        {/* Kumwell */}
                        <img src='./images/logo_kumwell.png' width={300}></img>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            width: '100%',
                            height: '100%',
                            bgcolor: theme.palette.primary.main,
                        }}
                    >
                        <Box
                            sx={{
                                width: '70%',
                            }}
                        >
                            <Grid container spacing={5}>
                                <Grid item xs={12} mb={2.5}>
                                    <Box
                                        sx={{
                                            fontSize: '33px',
                                            color: 'white',
                                        }}
                                    >
                                        Sign in to
                                    </Box>
                                    <Box
                                        sx={{
                                            marginTop: '5px',
                                            fontSize: '24px',
                                            color: 'white',
                                            fontWeight: '100',
                                        }}
                                    >
                                        Smart Lightning Management System
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        {...register('username')}
                                        required
                                        placeholder='Username'
                                        size='medium'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                boxShadow:
                                                    'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                                background: 'white',
                                                borderRadius: '10px',
                                            },
                                            '& input::placeholder': {
                                                fontSize: '20px',
                                                top: '17px',
                                                position: 'absolute',
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <svg
                                                        viewBox='0 0 24 24'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        className='svg-icon'
                                                        width='24'
                                                        height='24'
                                                        style={{
                                                            fill: '#9F9F9F',
                                                        }}
                                                    >
                                                        <path d='M1 3.5c0-.56.44-1 1-1h3.65v2H2.99v15h7v2h-8c-.56 0-1-.45-1-1v-17Zm14 1h-3v-2h4c.55 0 1 .44 1 1v5h-2v-4Z' />
                                                        <path d='M12 11c0-.56.44-1 1-1h9c.55 0 1 .44 1 1v11c0 .55-.45 1-1 1h-9c-.56 0-1-.45-1-1V11Zm2 1v9h7v-9h-7Z' />
                                                        <path d='M5 2c0-.56.44-1 1-1h6c.55 0 1 .44 1 1v3c0 .55-.45 1-1 1H6c-.56 0-1-.45-1-1V2Zm2 1v1h4V3H7Z' />
                                                        <path d='M18 15.5h-2.5v-2H18v2Z' />
                                                        <path d='M19.5 18.5h-4v-2h4v2Z' />
                                                    </svg>
                                                </InputAdornment>
                                            ),
                                            // disableUnderline: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        {...register('password')}
                                        required
                                        placeholder='Password'
                                        type='password'
                                        size='medium'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                boxShadow:
                                                    'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                                background: 'white',
                                                borderRadius: '10px',
                                            },
                                            '& input::placeholder': {
                                                fontSize: '20px',
                                                top: '17px',
                                                position: 'absolute',
                                            },
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <svg
                                                        viewBox='0 0 24 24'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        className='svg-icon'
                                                        width='24'
                                                        height='24'
                                                        style={{
                                                            fill: '#9F9F9F',
                                                        }}
                                                    >
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M9 3c-.56 0-1 .44-1 1v3.58H6V3.99c0-1.66 1.34-3 3-3h6c1.65 0 3 1.34 3 3v3.58h-2V3.98c0-.56-.45-1-1-1H9Z'
                                                        />
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M12 12.5c-.28 0-.5.22-.5.5 0 .27.22.5.5.5 .27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5Zm-2.5.5c0-1.39 1.11-2.5 2.5-2.5 1.38 0 2.5 1.11 2.5 2.5 0 1.38-1.12 2.5-2.5 2.5 -1.39 0-2.5-1.12-2.5-2.5Z'
                                                        />
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M11 17.979v-3.48h2v3.47h-2Z'
                                                        />
                                                        <path
                                                            fillRule='evenodd'
                                                            d='M12 7c-3.87 0-7 3.13-7 7 0 3.86 3.13 7 7 7 3.86 0 7-3.14 7-7 0-3.87-3.14-7-7-7Zm-9 7c0-4.98 4.02-9 9-9 4.97 0 9 4.02 9 9 0 4.97-4.03 9-9 9 -4.98 0-9-4.03-9-9Z'
                                                        />
                                                    </svg>
                                                </InputAdornment>
                                            ),
                                            // disableUnderline: true,
                                        }}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} mt={3.5} align='center'>
                                    <Button
                                        type='submit'
                                        size='large'
                                        variant='contained'
                                        color='success'
                                        disabled={loading}
                                        sx={{
                                            width: '210px',
                                            bgcolor: theme.palette.success.dark,
                                            color: theme.palette.success.light,
                                            fontWeight: 'normal',
                                            textTransform: 'none',
                                            borderRadius: 1.5,
                                            fontSize: '20px',
                                        }}
                                    >
                                        Sign in
                                        <svg
                                            viewBox='0 0 24 24'
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='24'
                                            height='24'
                                            style={{
                                                fill: 'white',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            <path d='M15 13H3v-2h12v2Z' />
                                            <path d='M12.2 7.79l3.5 3.49c.39.39.39 1.02 0 1.41l-3.5 3.5 -1.42-1.42 2.79-2.8 -2.8-2.8 1.41-1.42Z' />
                                            <path d='M8 4c0-.56.44-1 1-1h12c.55 0 1 .44 1 1v16c0 .55-.45 1-1 1H9c-.56 0-1-.45-1-1v-3h2v2h10V5H10v2H8V4Z' />
                                        </svg>
                                    </Button>
                                </Grid>
                            </Grid>

                            {error && (
                                <Modal
                                    open={!!error}
                                    onClose={() => setError(false)}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 350, // Set the width here
                                            bgcolor: 'background.paper',
                                            boxShadow: 24,
                                            p: '35px 25px 20px 25px',
                                            borderRadius: 1,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant='h6'
                                            gutterBottom
                                            sx={{
                                                display: 'flex',
                                                textAlign: 'center',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {error.logo === 'warning' ? (
                                                <svg
                                                    fill='#ff8e3d'
                                                    viewBox='0 0 24 24'
                                                    width='30px'
                                                    height='30px'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    style={{
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    <path d='M12 3c-4.98 0-9 4.02-9 9 0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9 0-4.98-4.03-9-9-9ZM1 12C1 5.92 5.92 1 12 1c6.07 0 11 4.92 11 11 0 6.07-4.93 11-11 11C5.92 23 1 18.07 1 12Z' />
                                                    <path d='M11.22 6.88c-.56 0-1 .44-1 1v1.33h-2V7.87c0-1.66 1.34-3 2.99-3h1.55c1.65 0 3 1.34 3 3v1.61c0 .79-.32 1.55-.88 2.12l-1.61 1.6c-.19.18-.3.44-.3.7v1.94h-2v-1.95c0-.8.31-1.56.87-2.13l1.6-1.61c.18-.19.29-.45.29-.71V7.82c0-.56-.45-1-1-1h-1.56Z' />
                                                    <path d='M11 19.22v-2.23h2v2.22h-2Z' />
                                                </svg>
                                            ) : (
                                                <svg
                                                    fill='red'
                                                    viewBox='0 0 24 24'
                                                    width='30px'
                                                    height='30px'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    style={{
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    <path d='M12 3c-4.98 0-9 4.02-9 9 0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9 0-4.98-4.03-9-9-9ZM1 12C1 5.92 5.92 1 12 1c6.07 0 11 4.92 11 11 0 6.07-4.93 11-11 11C5.92 23 1 18.07 1 12Z' />
                                                    <path d='M15.53 16.94L7.04 8.45l1.41-1.42 8.48 8.48 -1.42 1.41Z' />
                                                    <path d='M16.94 8.46l-8.49 8.48 -1.42-1.42 8.48-8.49 1.41 1.41Z' />
                                                </svg>
                                            )}
                                            Login Failed
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            gutterBottom
                                        >
                                            {error.message}
                                        </Typography>
                                        <Button
                                            fullWidth
                                            onClick={() => setError(false)}
                                            variant='contained'
                                            color='error'
                                            sx={{
                                                color: 'white',
                                                marginTop: '20px',
                                            }}
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                </Modal>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
