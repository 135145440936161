import { useCallback, useEffect, useState } from 'react'
import columnConfig from '../../config/columnsConfig'
import { Grid } from '@mui/material'
import TableComponents from '../../components/TableComponents'
import axiosInstance from '../../config/axiosConfig'
import { urlConfig } from '../../config/apiConfig'
import AddUser from './AddUser'
import { AlertPopup, handdleOpenAlert } from '../../components/StyleComponent'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditUser from './EditUser'
// import ModalRequest from '../../components/ModalRequest'
import ModalDelete from '../../components/ModalDelete'
export default function User({ keyName, create, update, remove }) {
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [data, setData] = useState('')
    const [Loading, setLoading] = useState(false)
    const [row, setRow] = useState('')
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('')
    const columns = columnConfig('User')

    const handleAlert = (message, status) => {
        handdleOpenAlert(setOpenAlert, setMessage, message, status)
    }

    const ListMenu = []

    if (update) {
        ListMenu.push({
            title: 'Edit',
            icon: <EditOutlinedIcon color='warning' />,
            action: () => setOpen('edit'),
        })
    }

    if (remove) {
        ListMenu.push({
            title: 'Delete',
            icon: <DeleteOutlineOutlinedIcon color='error' />,
            action: () => setOpen('delete'),
        })
    }

    const getUserByKey = useCallback(async () => {
        setData('')
        try {
            setError(false)
            const res = await axiosInstance.get(
                `${urlConfig.user}/keyName/${keyName}`,
            )

            setData(
                res.data.message?.map((item, index) => ({
                    ...item,
                    fullName: `${item.first_name} ${item.last_name}`,
                    index: index + 1,
                    date: formatDate(item.last_assess),
                })),
            )
        } catch {
            setError(true)
        }
    }, [keyName])
    const deleteUser = async () => {
        setLoading(true)
        try {
            await axiosInstance.delete(`${urlConfig.user}/${row.user_id}`)
            handleAlert('User delete successfully.', 'success')
            getUserByKey()
            setOpen(false)
        } catch (err) {
            handleAlert(
                `${err.response.data.message || 'No Internet'}`,
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getUserByKey()
    }, [getUserByKey])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableComponents
                    refresh={getUserByKey}
                    disabledButton={create}
                    MenuList={ListMenu}
                    setAction={setRow}
                    data={data}
                    columns={columns}
                    error={error}
                    button={{
                        title: 'ADD User',
                        action: () => setOpen('add'),
                    }}
                />
            </Grid>
            <AddUser
                handleAlert={handleAlert}
                getUserByKey={getUserByKey}
                open={open === 'add'}
                setOpen={setOpen}
                keyName={keyName}
            />

            <EditUser
                handleAlert={handleAlert}
                getUserByKey={getUserByKey}
                open={open === 'edit'}
                setOpen={setOpen}
                keyName={keyName}
                row={row}
            />

            <ModalDelete
                disabled={Loading}
                onSubmit={deleteUser}
                open={open === 'delete'}
                setOpen={setOpen}
                message={`${row.fullName}`}
            />
            <AlertPopup
                open={openAlert}
                setOpen={setOpenAlert}
                message={message}
            />
        </Grid>
    )
}
const formatDate = (isoString) => {
    const date = new Date(isoString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`
}
