import { Typography } from '@mui/material'
import AddDeviceType from './AddDeviceType'
import UploadImage from '../../common/upload-image/UploadImage'

export default function DeviceTypeForm({
    input,
    setInput,
    setImageUpload,
    handleSave,
    isEdit,
    imagePath,
    setOpen,
    canSave,
    onCancel,
    setErr,
}) {
    console.log(canSave)
    return (
        <>
            <AddDeviceType
                input={input}
                setInput={setInput}
                isEdit={isEdit}
                setErr={setErr}
            />
            <Typography variant='h6' mt={5}>
                Upload Device Type Image
            </Typography>
            <UploadImage
                setImage={setImageUpload}
                onSave={handleSave}
                setOpen={setOpen}
                isSmall
                imagePath={imagePath}
                canSave={canSave}
                onCancel={onCancel}
            />
        </>
    )
}
