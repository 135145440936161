import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import UserList from './UserList'
import { Container } from '../../components/StyleComponent'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import Test from './Test'
import { Navigate } from 'react-router-dom'

export default function UserManagement({ data, create, update, remove }) {
    const [tab, setTab] = useState(0)

    const tablabel = ['User List']
    const renderTabComponent = () => {
        switch (tab) {
            case 0:
                return (
                    <UserList create={create} update={update} remove={remove} />
                )
            case 1:
                return <Test />
            default:
                return null
        }
    }

    return (
        <Container header={header}>
            {/* <Tabs value={tab} onChange={(e, nv) => setTab(nv)}>
                    {tablabel.map((item, index) => (
                        <Tab
                            key={index}
                            value={index}
                            label={
                                <span style={{ fontSize: '12px' }}>{item}</span>
                            }
                            sx={{ color: '#706564' }}
                            // textTransform: 'none',
                        />
                    ))}
                </Tabs> */}

            {renderTabComponent()}
        </Container>
    )
}
const header = {
    icon: <AccountCircleOutlinedIcon sx={{ color: 'white' }} />,
    color: 'grey',
    title: 'User Management',
}
